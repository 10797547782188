import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { RootState } from "../../../store";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../constants/url";
import { getMovieDetailApi, getNowShowingApi } from "../../../services/films";
import "./movieDetails.scss";
import { IMovieData, ISessionDetails } from "../../../models/cinema";
import moment from "moment";
import HtmlRender from "../../../components/UI/HtmlRender";
import dompurify from "dompurify";
import SingleShowTime from "../../../components/Session/US/SingleShowTime";
import ShowTimeFilter from "../../../components/UI/Filters/ShowTimeFilter";
import {
  dateToFromNowDaily,
  findCinemaObject,
  formatFlags,
  seatFillStatus,
} from "../../../utils/formatter";
import { IDropDown } from "../../../models/common";
import {
  setCinemaAction,
  setReinitializeSlider,
} from "../../../store/application/action";
import { setQuickFilter } from "../../../store/films/action";
import { setTicketLessFb } from "../../../store/foodAndBeverage/action";
import {
  getTicketBookingDetails,
  toggleTicketModal,
} from "../../../store/ticketBooking/action";
import { toast } from "react-toastify";
import { enableShowTime } from "../../../utils/helper";

interface IMovieDetailProps {}

const MovieDetail: FC<IMovieDetailProps> = () => {
  const { userDetails, isLoggedIn, countryId, quickFilters, brandId, cinemas, currentCinema } =
    useSelector((state: RootState) => ({
      userDetails: state.authReducer.userDetails,
      isLoggedIn: state.authReducer.isLoggedIn,
      countryId: state.applicationReducer.countryId,
      quickFilters: state.filmsReducer.quickFilters,
      brandId: state.applicationReducer.brandId,
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
    }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cinemaId: cinemaAlias, movieGroupId } = useParams();
  const [searchParams] = useSearchParams();
  const [movie, setMovie] = useState<IMovieData | null>();
  const [type, setType] = useState<IDropDown[]>([]);
  const [session, setSession] = useState<IDropDown[]>([]);
  const [checkLocationChange, setCheckLocationChange] = useState("");

  const getMovieData = async (payload: any, inital: boolean) => {
    const {
      status,
      response: { data },
      error,
    } = (await getNowShowingApi(payload)) as any;

    if (status && data && data.statusCode === 200 && data.data.length > 0) {
      setMovie(data.data[0]);
      if (checkLocationChange !== quickFilters.location) {
        setCheckLocationChange(quickFilters.location);
        let format: string[] = [];
        let sessionDate: string[] = [];
        data.data.forEach((v: any) => {
          v.showdates.forEach((show: any) => {
            sessionDate.push(show.date);
            show.showtypes.forEach((t: any) => {
              format.push(t.type);
            });
          });
        });
        setType(
          [...new Set(format)].map((v) => {
            return { label: v, value: v };
          })
        );
        setSession(
          [...new Set(sessionDate)].map((v) => ({
            label: dateToFromNowDaily(v),
            value: v,
          }))
        );
      }
    } else {
      setSession([]);
      setType([]);
      setMovie(null);
    }
  };

  useEffect(() => {
    if (cinemaAlias) {
      const cinema = findCinemaObject(cinemas, cinemaAlias);
      if (cinema) {
        dispatch(setCinemaAction(cinema));
      }
    }
  }, [cinemaAlias]);

  useEffect(() => {
    const payload: any = {
      brandId,
      countryId: countryId,
      cinemaId: currentCinema?.slug,
      status: API_REQUEST_TYPE.NOW_SHOWING,
      flag: "",
      selectedMovie: movieGroupId,
    };

    if (quickFilters.format) {
      payload.selectedAttribute = quickFilters.format;
    }
    if (quickFilters.session) {
      payload.selectedSessionId = quickFilters.session;
    }
    if (quickFilters.date) {
      payload.selectedDate = quickFilters.date;
    }
    if (movieGroupId) {
      getMovieData(payload, false);
    }
    const cinema = findCinemaObject(cinemas, quickFilters.location);
    if (cinema) {
      dispatch(setCinemaAction(cinema));
    }
  }, [quickFilters]);

  useEffect(() => {
    if (countryId && currentCinema && currentCinema?.alias === cinemaAlias && movieGroupId) {
      const payload: any = {
        countryId: countryId,
        cinemaId: currentCinema?.slug,
        status: API_REQUEST_TYPE.NOW_SHOWING,
        flag: "",
        selectedMovie: movieGroupId,
      };

      if (quickFilters.format) {
        payload.selectedAttribute = quickFilters.format;
      }
      getMovieData(payload, true);
    }
  }, [currentCinema, movieGroupId, countryId]);

  const getComingSoonMovieData = async (id: string) => {
    const { data } = await getMovieDetailApi({
      movieGroupId: id,
      countryId,
      cinemaId: currentCinema?.slug!,
      sort: true,
    });

    if (data.length > 0) {
      const [mov] = data;
      const payload = {
        ...mov,
        name: mov.movieName,
        poster_image: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${mov.mobileImageId}${IMAGE_URL_CONFIG.LARGE_IMAGE_EXT}`,
        length: mov.movieDuration,
        gentre: mov.ratingExplanation,
        synopsis: mov.outline,
        youtube_id: mov.trailerUrl ? mov.trailerUrl.split("=").pop() : "",
      };
      setMovie({ ...payload });
    }
  };

  useEffect(() => {
    if (countryId && searchParams && !movieGroupId) {
      const id = searchParams.get("id");

      if (id) {
        getComingSoonMovieData(id);
      }
    }
  }, [countryId, searchParams]);

  const safeURL = useMemo(() => {
    if (movie && movie.youtube_id) {
      const spilturl = new RegExp(/(?:\?v=)([^&]+)*/);
      const videoId = spilturl.exec(movie.youtube_id);
      return dompurify.sanitize(
        "https://www.youtube.com/embed/" +
          (videoId !== null && Array.isArray(videoId)
            ? videoId[1]
            : movie.youtube_id.split("/").pop())
      );
    } else {
      return null;
    }
  }, [movie]);

  const openTicketModal = (data: ISessionDetails, movie: IMovieData) => {
    if (enableShowTime(movie)) {
      dispatch(setTicketLessFb(false));
      dispatch(
        getTicketBookingDetails({
          cinemaId: currentCinema.slug,
          sessionId: data.id,
          reservedSeating: data.reservedSeating,
          screenType: data.type,
          countryId: countryId,
          covidFlag: currentCinema.covidFlag,
        })
      );
      dispatch(toggleTicketModal(true));
    } else {
      toast.error("Sale not started");
    }
  };

  return (
    <>
      <section className="movie-detail-banner-section">
        <div className="banner-content">
          <img src={movie?.poster_image} alt="Reading Cinemas" />
        </div>
        <div className="buy-ticket-box">
          <div className="buy-ticket-box-top">
            <h2 className="heading">{movie?.name}</h2>
            <div className="btn-section">
              <a href="#showTime" className="btn-new btn-danger-new">
                BUY TICKETS
              </a>
            </div>
          </div>
          <div className="buy-ticket-box-cont">
            <div className="movie-details-action-list">
              <div className="movie-details-action-list-img">
                <img src="/images/img-icon.svg" />
              </div>
              <div className="movie-details-action-list-cont">
                <h3 className="active-cls">92%</h3>
                <p>Rotten Tomatoes Score</p>
              </div>
            </div>
            <div className="movie-details-action-list">
              <div className="movie-details-action-list-img">
                <img src="/images/wishlist-icon.svg" />
              </div>
              <div className="movie-details-action-list-cont">
                <h3>
                  <a href="javascript:void(0)" className="active-hover">
                    Add to Watch List
                  </a>
                </h3>
              </div>
            </div>
            <div className="movie-details-action-list">
              <div className="movie-details-action-list-img">
                <img src="/images/share-icon.svg" />
              </div>
              <div className="movie-details-action-list-cont">
                <h3>
                  <a href="javascript:void(0)" className="active-hover">
                    Share
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="film-pg-section">
        <div className="film-pg-content">
          <div className="film-pg-top-link">
            <Link to={"/"}>ALL MOVIES</Link>
          </div>
          <div className="film-pg-content-inner">
            <div className="film-pg-rating-table">
              <table>
                <thead>
                  <tr>
                    <th>RATING</th>
                    <th>RUNTIME</th>
                    <th>GENRE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>{movie?.ratingName}</div>
                    </td>
                    <td>
                      <div>
                        {movie?.length
                          ? moment.duration(movie?.length, "minutes").humanize()
                          : ""}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <HtmlRender content={movie?.gentre} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="film-pg-cast-direct">
              <div className="film-pg-cast-direct-left">
                <h2>
                  <span>CAST</span>
                </h2>
                <HtmlRender content={movie?.cast} />
              </div>
              <div className="film-pg-cast-direct-right">
                <h2>
                  <span>DIRECTED BY</span>
                </h2>
                <p>{movie?.director}</p>
              </div>
            </div>
            <div className="film-pg-content-desc">
              {/* <h3>
                <span>YOUR FAVORITE ITALIAN PLUMBERS ARE BACK !</span>
              </h3> */}
              <HtmlRender content={movie?.synopsis} />
              {safeURL ? (
                <div className="film-pg-video-parent">
                  <iframe
                    className="embed-responsive-item"
                    src={safeURL}
                    title={movie?.name}
                  ></iframe>
                </div>
              ) : null}
            </div>

            {movieGroupId ? (
              <>
                <div className="showtime-content film-pg-showtimes-det">
                  <h3>SHOWTIMES & TICKETS</h3>
                  <div className="showtime-content-table-flex" id="showTime">
                    <ShowTimeFilter session={session} type={type} />
                  </div>
                </div>

                <div className="movie-details-right-new">
                  {movie?.showdates ? (
                    movie.showdates.map((show: any) => {
                      return (
                        <div className={!quickFilters.date ? `divider` : ""}>
                          {!quickFilters.date ? (
                            <div className="btn-section d-flex">
                              <a
                                href="javascript:void(0)"
                                className="btn-new btn-border-danger-big-new"
                              >
                                {dateToFromNowDaily(show.date)}
                              </a>
                            </div>
                          ) : null}
                          {show.showtypes.map((session: any) => (
                            <div className="movie-show-time-parent-section">
                              <div className="movie-show-time-parent">
                                <h4> {session.type}</h4>
                                <div className="movie-show-time-flex">
                                  {session.showtimes.map((time: any) => (
                                    <div
                                      className={`movie-show-time ${
                                        !enableShowTime(movie)
                                          ? "show-disable"
                                          : ""
                                      }`}
                                      onClick={() => openTicketModal(time, movie)}
                                    >
                                      {moment
                                        .parseZone(time.date_time)
                                        .format("hh:mm A")}
                                      {time.statusCode === "1" ? (
                                        <span className="sold_out_txt">
                                          SOLD OUT
                                        </span>
                                      ) : null}
                                      {time.statusCode === "0" ? (
                                        <div className="seat_wrap">
                                          <div
                                            className={`fill_status ${
                                              seatFillStatus(
                                                time.available_seats,
                                                time.totalNumberOfSeats
                                              ) === 0
                                                ? "no_fill"
                                                : seatFillStatus(
                                                    time.available_seats,
                                                    time.totalNumberOfSeats
                                                  ) === 1
                                                ? "slow_fill"
                                                : seatFillStatus(
                                                    time.available_seats,
                                                    time.totalNumberOfSeats
                                                  ) === 2
                                                ? "fast_fill"
                                                : ""
                                            }`}
                                          ></div>
                                          <div className={`recliner`}></div>

                                          {time.statusCode === "0" &&
                                            formatFlags(time).map(
                                              (flagObj: any) => (
                                                <div className="flag_wrap">
                                                  <span className="flag_text">
                                                    {flagObj.short}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      ) : null}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-100 d-flex justify-content-center">
                      <div className="no-session">No Sessions Available</div>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default MovieDetail;
