import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSlidesWatcher,
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { RootState } from "../../../../store";
import { COUNTRY } from "../../../../constants/app";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import { screenCategoryApi } from "../../../../services/sideMenus";
import HtmlRender from "../../../../components/UI/HtmlRender";
import './cinemaHire.scss';
import { downloadFile } from "../../../../utils/helper";
import HelmetTitle from "../../../../components/Helmet";

interface ICinemaHireProps {}

const CinemaHire: FC<ICinemaHireProps> = () => {
  const { countryId, slides } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    slides: state.applicationReducer.slides,
  }));

  const { type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const getData = async () => {
    const response = await screenCategoryApi({
      countryId,
      screenType: "cinema-hire",
    });

    setData(
      response && response.length > 0
        ? response[0].content.map((v: any) => {
            v.file = v.buttons.find((v: any) => v.type === "file");
            v.images = v.buttons
              .filter((v: any) => v.type === "image")
              .map((v: any) => ({
                imageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.Id}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
                mobileImageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.Id}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
                showBannerInfo: 0,
              }));
            return v;
          })
        : []
    );
  };


  useEffect(() => {
    if (countryId && countryId === COUNTRY.ANG) {
      getData();
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.CINEMA_HIRE,
        })
      );
    }
  }, [countryId]);

  useEffect(() => {
    if (countryId && countryId !== COUNTRY.ANG) {
      dispatch(setContentModal({ type: ROUTES.CINEMA_HIRE }));
      dispatch(toggleContentModal(true));
    }
  }, [type, countryId]);

  const book = ()=>{
    dispatch(setContentModal({ type: ROUTES.CINEMA_HIRE }));
    dispatch(toggleContentModal(true));
  }

  

  return countryId === COUNTRY.ANG ? (
    <div className="cinema_hire_wrapper ">
      <HelmetTitle title="Cinema Hire" description="Cinema Hire"/>
      <div className="slider-filter">
        <Slider
          className="banner-slider"
          data={slides}
          isDotsNeeded={true}
        />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="thumb_wrap p-0">
            {data.map((list: any) => (
              <div className="list_thumb">
                <React.Fragment key={list.title}>
                  <div className="thumb_content_wrap">
                    <div className="thumb_content">
                      <div className="thumb_title">{list.title}</div>
                      <div className="description">
                      {list.description ? (
                        <HtmlRender content={list.description} />
                      ) : null}
                      {list.description1 ? (
                        <HtmlRender content={list.description1} />
                      ) : null}
                      {list.description2 ? (
                        <HtmlRender content={list.description2} />
                      ) : null}
                      {list.description3 ? (
                        <HtmlRender content={list.description3} />
                      ) : null}
                      </div>
                    </div>
                    {list.showButton === 1 ? (
                      <div className="cust_desc_thumb_btn_wrap text-center">
                        {list.buttonType === "file" && list.file ? (
                          <div className="thumb_btn_wrap">
                            <a
                              href="javascript:void(0)"
                              className="white_btn"
                              onClick={() =>
                                downloadFile(
                                  `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${list.file.Id}.${list.file.extension}`
                                )
                              }
                            >
                              {list.buttonName}
                            </a>
                          </div>
                        ) : list.buttonType === "link" && list.buttonLinkUrl ? (
                          <div className="thumb_btn_wrap">
                            <a
                              href={list.buttonLinkUrl}
                              className="white_btn"
                              target="blank"
                            >
                              {list.buttonName}
                            </a>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="thumb_image">
                    <Slider data={list.images} />
                  </div>
                </React.Fragment>
              </div>
            ))}
          </div>
          <div className="cinema_hire_footer_section">
            <a className="white_btn px-3" onClick={book} >
            click here to submit a booking request
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CinemaHire;
