import React, { FC, useState } from "react";
import { URLS } from "../../../constants/url";
import LoyaltySignUp from "./Loyalty";
import MilitarySignUp from "./Military";

interface IMemberSignupProps {}

const MemberSignup: FC<IMemberSignupProps> = () => {
  const [type, setType] = useState("");

  return (
    <div className="col-12 auth-wrapper">
      {!type ? (
        <div>
          <div className="sign-up">
            <p>Create an account as a...</p>
            <button onClick={() => setType("loyalty")}>LOYALTY MEMBER</button>
            <button onClick={() => setType("military")}>MILITARY MEMBER</button>
          </div>
        </div>
      ) : (
        <div>{type === "loyalty" ? <LoyaltySignUp onCancel={()=>setType('')} /> : <MilitarySignUp onCancel={()=>setType('')} />}</div>
      )}
    </div>
  );
};

export default MemberSignup;
