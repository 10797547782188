import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { BRANDS, COUNTRY } from "../../../constants/app";
import { IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import "./membership.scss";
import TextInput from "../../../components/UI/TextInput";
import Modal from "../../../components/UI/Modal";
import GiftShopFooter from "../GiftShop/Footer";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../utils/validatorSchema";
import { checkGiftCardBalanceApi } from "../../../services/giftshop";
import {
  checkMembershipBalanceApi,
  getMemberItemsApi,
  getMembershipPackageApi,
} from "../../../services/auth";
import {
  memberValidationWatcher,
  showLogin,
  updateMembbershipPurchase,
} from "../../../store/auth/action";
import {
  setModal,
  toggleTicketModal,
} from "../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import { setFooterType } from "../../../store/application/action";
import { pageContentApi } from "../../../services/sideMenus";
import HtmlRender from "../../../components/UI/HtmlRender";
import { ILoyaltyPackage } from "../../../models/auth";
import { toast } from "react-toastify";
import MembershipRenewal from "../Modals/Membership/MembershipRenewal";
import moment from "moment";
import { generateUsersessionId } from "../../../utils/tickets";
import UpgradeGoldBtn from "../../../components/Auth/Membership/UpgradeGoldBtn";
import HelmetTitle from "../../../components/Helmet";

interface IMembershipProps {}

const Membership: FC<IMembershipProps> = () => {
  const {
    isLoggedIn,
    countryId,
    settings,
    brandId,
    currentCinema,
    userDetails,
    memberDetail,
  } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    isLoggedIn: state.authReducer.isLoggedIn,
    settings: state.applicationReducer.settings,
    countryId: state.applicationReducer.countryId as COUNTRY,
    currentCinema: state.applicationReducer.currentCinema,
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
  }));

  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [headerContent, setHeaderContent] = useState<any>(null);
  const [membership, setMembership] = useState<any>([]);
  const [cardError, setCardError] = useState("");
  const [cardBalance, setCardBalance] = useState({
    balance: "",
    expiryDate: "",
  });
  const [packages, setPackages] = useState<ILoyaltyPackage[]>([]);
  const [showUpgrade, setShowUgrade] = useState<any>(null);
  const [showRenewal, setShowRenewal] = useState<any>(null);

  const onClose = () => {
    setOpen(false);
  };

  const getPageContent = async () => {
    const {
      data: { data, status },
    } = await pageContentApi({
      countryId,
      cinemaId: currentCinema?.slug,
      pageName: `angelika-rewards`,
      flag: "customPage",
    });
    if (status && data && data.length > 0) {
      setHeaderContent(data[0]);
      setMembership(data[0].content ? data[0].content : []);
      dispatch(setFooterType(5));
    } else {
      dispatch(setFooterType(0));
    }
  };

  const getPackageContent = async () => {
    const {
      data: {
        data: { loyaltyMembershipPackages },
        status,
      },
    } = await getMembershipPackageApi({
      countryId,
    // cinemaId: currentCinema?.slug, 
      cinemaId: '0000000001'
    });

    if (loyaltyMembershipPackages && loyaltyMembershipPackages.length > 0) {
      setPackages(loyaltyMembershipPackages);
    }
  };

  const getMemberItems = async () => {
    const {
      data: {
        data: {
          data: { ConcessionList },
        },
      },
    } = await getMemberItemsApi({
      countryId,
      MemberRefId: userDetails?.vista_memberid!,
      cardNumber: userDetails?.cardNumber!,
      isMemberPackage: true,
    });
    if (ConcessionList && ConcessionList.length > 0) {
      setShowUgrade(
        ConcessionList.find(
          (v: any) =>
            Number(v.RecognitionID) ===
              settings?.membership?.upgrade?.recognitionId &&
            Number(v.VistaID) === settings?.membership?.upgrade.itemId
        )
      );
      setShowRenewal(
        ConcessionList.find(
          (v: any) =>
            Number(v.RecognitionID) ===
              settings?.membership?.renewal?.recognitionId &&
            Number(v.VistaID) === settings?.membership?.renewal.itemId
        )
      );
    }
  };

  useEffect(() => {
    if (countryId && currentCinema) {
      getPageContent();
      getPackageContent();
    }
  }, [countryId, currentCinema]);

  useEffect(() => {
    if (countryId && currentCinema && isLoggedIn) {
      getMemberItems();
    }
  }, [countryId, currentCinema, isLoggedIn && memberDetail]);

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("membershipCard"),
      initialValues: {
        cardNumber: "",
      },
      onSubmit: async (values) => {
        setCardError("");
        const { cardNumber } = values;
        const {
          data: { data },
        } = (await checkMembershipBalanceApi({
          cardNumber: cardNumber,
          countryId: countryId,
          guestCardUpdate: true,
        })) as any;

        if (data && data.result && data.result.LoyaltyMember) {
          const {
            result: {
              LoyaltyMember: { ExpiryDate, BalanceList },
            },
          } = data;
          let balance = 0;
          if (BalanceList.length > 0) {
            balance = BalanceList.reduce((total: number, v: any) => {
              total = total + Number(v.PointsRemaining);
              return total;
            }, 0);
          }
          setCardBalance({
            balance: `${balance}`,
            expiryDate: moment(ExpiryDate).format("Do MMM, YYYY"),
          });
        } else {
          setCardError("Invalid Card Number");
        }
      },
    });

  const purchase = (data: any) => {
    const isFree =
      data.Button_Link_URL.toLowerCase() === "signup" ? true : false;

    if (isFree) {
      dispatch(
        setModal({
          ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
            MODAL_TYPE.MEMBERSHIP_AUTH
          ],
          type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
          data: 'account'
        })
      );
      dispatch(toggleTicketModal(true));
    } else {
      if (!isLoggedIn) {
        const loyaltyPackage = packages.find((v: ILoyaltyPackage) => {
          return (
            v.activationData &&
            Number(v.activationData.id) === Number(data.Button_Link_URL)
          );
        });
        dispatch(
          updateMembbershipPurchase({
            membershipType: "new",
            clubId: loyaltyPackage ? loyaltyPackage.clubId : 0,
            loyaltyPackage: loyaltyPackage
              ? loyaltyPackage.activationData
              : null,
          })
        );
        dispatch(
          setModal({
            ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
              MODAL_TYPE.MEMBERSHIP_AUTH
            ],
            type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
          })
        );
        dispatch(toggleTicketModal(true));
      }
    }
  };

  const onRenewal = () => {
    setOpen(false);
    const userSessionId = generateUsersessionId(currentCinema?.slug);
    dispatch(
      updateMembbershipPurchase({
        membershipType: "renewal",
        userSessionId: userSessionId,
        loyaltyPackage: {
          description: "Angelika Gold Member 1 Year renewal",
          extendedDescription: "Angelika Gold Rewards",
          id: settings?.membership?.renewal?.itemId,
          recognitionId: settings?.membership?.renewal?.recognitionId,
          priceInCents: 2000,
        },
      })
    );
    const payload = {
      MemberRefId: userDetails?.vista_memberid,
      UserSessionId: userSessionId,
      cardNumber: userDetails?.result?.cardNumber,
      countryId: countryId,
    };
    dispatch(
      memberValidationWatcher(
        payload,
        (res: any) => {
          dispatch(
            setModal({
              ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
                MODAL_TYPE.MEMBERSHIP_PAYMENT
              ],
              type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
            })
          );
        },
        (err: any) => {}
      )
    );

    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
          MODAL_TYPE.MEMBERSHIP_AUTH
        ],
        type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
      })
    );
    dispatch(toggleTicketModal(true));
  };

  const Header = () => {
    return (
      <div className="reward-header">
        <h3>{headerContent?.headerTitle}</h3>
        <HtmlRender content={headerContent?.description} />

        <div className="reward-images">
          <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}tickets.svg`} />
          <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}drink.svg`} />
          <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}choctop.svg`} />
          <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}popcorn.svg`} />
        </div>
        {/* <p>
          Plus you can enjoy additional benefits included in our Reading Cinema
          eNewsletter such as:
          <ul>
            <li>Exclusive member-only Competitions</li>
            <li>Advance ticket alerts and more!</li>
          </ul>
        </p> */}
      </div>
    );
  };

  return (
    <div className="reward-wrapper">
      <HelmetTitle title="Rewards" description="Rewards"/>
      <div className="reward-container">
        <Header />
        {membership.map((v: any, index: number) =>
          v.Tile_Style === "regular" ? (
            <div className="reward-membership">
              <img className="membership-details-image"
                src={`${
                  URLS.CLOUDFRONT_URL +
                  "/" +
                  IMAGE_URL_CONFIG.IMAGE_PATH +
                  "/" +
                  v.Tile_Image +
                  IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT
                }`}
              />
              <div className="membership-details">
                <h3>{v.Tile_Title}</h3>
                <div className="membership-body">
                  <HtmlRender content={v.Tile_Description} />
                </div>
                {v.Show_Buttons === 1 && v.buttons.length > 0
                  ? v.buttons.map((b: any) =>
                      b.Button_Link_URL.toLowerCase() !== "signup" ? (
                        !isLoggedIn ? (
                          <button
                            className={`membership-button ${
                              isLoggedIn ? "px-4" : ""
                            }`}
                            onClick={() => purchase(b)}
                          >
                            {b.Button_Text}
                          </button>
                        ) : showUpgrade ? (
                          <UpgradeGoldBtn
                            fromPage="membership"
                            upgradeData={showUpgrade}
                          />
                        ) : null
                      ) : b.Button_Link_URL.toLowerCase() == "signup" &&
                        !memberDetail?.LoyaltyMember?.MemberLevelName?.includes(
                          "Gold"
                        ) ? (
                        !isLoggedIn ? (
                          <button
                            className="membership-button"
                            onClick={() => purchase(b)}
                          >
                            {b.Button_Text}
                          </button>
                        ) : (
                          <span>CURRENT MEMBERSHIP</span>
                        )
                      ) : null
                    )
                  : null}
              </div>
            </div>
          ) : v.Tile_Style === "image" ? (
            <div className="reward-membership">
              <img
                className="tile-image"
                src={`${
                  URLS.CLOUDFRONT_URL +
                  "/" +
                  IMAGE_URL_CONFIG.IMAGE_PATH +
                  "/" +
                  v.Tile_Image +
                  IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT
                }`}
              />
            </div>
          ) : null
        )}
        {/* <div className="reward-membership military-members">
            <img src="/images/Military_Membership.svg"  />
            <div className="membership-details">
              <h3>Military Members</h3>
              <p className="membership-body">At Reading Cinemas, we are grateful for the sacrifice our nation’s 
              military men and women, and their families have made in service to our country. We are proud to 
              now offer military discounts.* </p>
              <button className="membership-button" onClick={() => setOpenMilitaryModal(true)}>VERIFY YOUR MILITARY ID</button>
            </div>
        </div> */}
        <div className="reward-membership check_renew">
          <div className="membership-details">
            <h3>Check or Renew your Rewards Membership</h3>
            {!isLoggedIn ? (
              <>
                <p className="membership-body">
                  Enter your membership number below to check your membership
                  points and expiry.
                </p>
                <div className="search_common">
                  <input
                    type="text"
                    maxLength={20}
                    className="form-control"
                    placeholder="Enter card number"
                    onChange={({ target: { value } }) =>
                      setFieldValue("cardNumber", value)
                    }
                  />
                  <button type="button" onClick={() => handleSubmit()}>
                    <img src={URLS.RIGHT_ARROW} alt="" />
                  </button>
                </div>
                {touched && errors && errors.cardNumber ? (
                  <div className="err-message text-center mt-2">
                    {errors.cardNumber}
                  </div>
                ) : !cardError && cardBalance.balance !== "" ? (
                  <div className="my-3">
                    <h6>Points available: {cardBalance.balance}</h6>
                    <h6>Expires on: {cardBalance.expiryDate}</h6>
                  </div>
                ) : cardError ? (
                  <div className="error_message text-center mt-2">
                    {cardError}
                  </div>
                ) : null}
              </>
            ) : (
              <button
                className="membership-button my-4"
                onClick={() => setOpen(true)}
              >
                CHECK/RENEW MEMBERSHIP
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal visible={open} showCloseBtn={false}>
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <button type="button" className="close" onClick={onClose}>
                <img
                  className="close_icon"
                  src={URLS.CLOSE_ICON}
                  alt="close_icon"
                  title="close_icon"
                />
              </button>
              <div className="modal-header">
                <div className="title title_append reel_head">
                  Check Membership Status
                </div>
              </div>
              <div className="modal-body">
                <MembershipRenewal onClose={onClose} onRenewal={onRenewal} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Membership;
