import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { COUNTRY } from "../../../../constants/app";
import Barcode from "react-barcode";
import ProgressBar from "@ramonak/react-progress-bar";
import PointScale from "../PointScale";
import {
  memberValidationWatcher,
  showPhysicalCardModal,
  showReelClubModal,
} from "../../../../store/auth/action";
import Redeem from "../Redeem";
import PhysicalCard from "../PhysicalCard";

interface ILoyaltyProps {}

const Loyalty: FC<ILoyaltyProps> = () => {
  const { countryId, userDetails, memberDetail } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails) {
      const payload = {
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userDetails?.result?.cardNumber,
        cardNumber: userDetails?.result?.cardNumber,
        countryId: countryId,
      };
      dispatch(memberValidationWatcher(payload));
    }
  }, [userDetails]);

  const txnPoints = useMemo(() => {
    if (
      memberDetail &&
      memberDetail?.LoyaltyMember &&
      memberDetail.LoyaltyMember.BalanceList &&
      memberDetail.LoyaltyMember.BalanceList.length > 0
    ) {
      return memberDetail?.LoyaltyMember?.BalanceList.reduce(
        (total: number, v: any) => {
          total = total + Number(v.PointsRemaining);
          return total;
        },
        0
      )
    } else {
      return 0;
    }
  }, [memberDetail]);

  const openReelClubModal = () => {
    dispatch(showReelClubModal(true));
  };

  const onClickAddCard = () => {
    dispatch(showPhysicalCardModal(true));
  };

  return (
    <div className="col-md-12 reel_wrap">
      <div className="section_divided">
        <div className="redeem-card card_top">
          <div className="name">
            {countryId === COUNTRY.STA ? "State Member" : countryId === COUNTRY.ANG ?(memberDetail?.LoyaltyMember?.MemberLevelName)?.replace('Gold','Rewards Gold')?.replace('Club','Rewards Club'):"REEL Club"}
            <span className="float-right text-right member-number">
              <div>MEMBER NUMBER</div>
              <div className="member-no">
                {memberDetail?.LoyaltyMember?.CardNumber}
              </div>
            </span>
          </div>
          {/* <div
            className="col-md-12 text-right text_with_right_arrow px-2"
            onClick={onClickAddCard}
          >
            Add a Physical Card <span className="chevron_right"></span>
          </div> */}
          <div className="club_bar_code" onClick={() => {}}>
            <Barcode
              width={4}
              height={65}
              displayValue={false}
              value={memberDetail?.LoyaltyMember?.CardNumber!}
            />
          </div>
        </div>
        <div className="card_bottom px-3">
          <div className="col-md-12">
            <div className="points_wrap m-0">
              <div className="points">{txnPoints} Points</div>
              {/* {countryId && countryId !== COUNTRY.STA ? (
                <div
                  className="redeem_help help_icon right_icon"
                  onClick={openReelClubModal}
                >
                  HOW TO REDEEM
                </div>
              ) : null} */}
            </div>
            {/* <div className="mb-2 rewards_scale">
              <ProgressBar
                completed={(txnPoints/500)*100}
                className="wrapper mb-2"
                bgColor="#e20000"
                isLabelVisible={false}
                height="12px"
              />
              {countryId !== COUNTRY.STA ? (
                <PointScale points={txnPoints} type="scale1" />
              ) : (
                <PointScale points={txnPoints} type="scale2" />
              )}
            </div> */}
          </div>
          {/* <div className="name">
            <span className="float-right text-right member-number"></span>
          </div> */}
        </div>
      </div>
      <Redeem />
      <PhysicalCard />
    </div>
  );
};

export default Loyalty;
