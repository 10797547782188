import React, { FC, useEffect, useMemo, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./newsLetter.scss";
import { getNowShowingApi } from "../../../../services/films";
import { newsLetterApi } from "../../../../services/auth";
import { useRef } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { validateEmail } from "../../../../utils/helper";
import { setContentModal, toggleContentModal } from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";

interface INewsLetterProps {
  fromPage?:string
}

const NewsLetter: FC<INewsLetterProps> = ({fromPage}) => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));

  const emailInputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const dispatch = useDispatch();

  const signUp = () => {
    if (emailInputRef?.current?.value && emailInputRef?.current?.value.trim()) {
      if(!validateEmail(emailInputRef.current.value)){
        toast.error("Please enter a valid email address", {toastId:'newsletter_invalid_email'});
        return;
      }
      newsLetterApi({
        countryId: countryId,
        email: emailInputRef.current.value,
      }).then((res) => {
        if (res.data.data) {
          if(emailInputRef && emailInputRef.current){
            emailInputRef.current.value = ''
          }
          toast.success("Registered Successfully");
        } else {
          toast.error("Something went wrong!");
        }
      });
    } else {
      toast.error("Please enter your email address");
    }
  };

  const openTerms = ()=>{
    dispatch(
      setContentModal({ type: ROUTES.PRIVACY})
    );
    dispatch(toggleContentModal(true));
  }

  const openPrivacy = ()=>{
    dispatch(
      setContentModal({ type: ROUTES.TERMS })
    );
    dispatch(toggleContentModal(true));
  }

  const openUsRight = ()=>{
    dispatch(
      setContentModal({ type: ROUTES.PRIVACY_POLICY_US_STATE })
    );
    dispatch(toggleContentModal(true));
  }

  return (
    <section className={`news-letter-signup-section ${fromPage}`}>
      <div className="container-content">
        <h2 className="heading">GET READING CINEMAS DELIVERED TO YOUR INBOX</h2>
        <div className="signup-search-content">
          <h4>
            Sign up for weekly movie info, food & drink offers, ticket deals and
            invites to special screenings & events from Reading Cinemas.
          </h4>
          <div className="signup-input-search">
            <div style={{position:'relative'}}>
              <input
                type="text"
                ref={emailInputRef}
                placeholder="Please enter your email address"
              />
              <img
                src="/images/arrow_next_black.svg"
              />
            </div>
          </div>
          <p>
            Yes! I would like to receive movie info, special offers, and other
            updates from Reading Cinemas. By creating an account, you agree to
            our{" "}
            <a href='javascript:void(0)' className="privacy-policy" onClick={openTerms}>
              Terms&nbsp;of&nbsp;Use
            </a>{" "}
            and acknowledge that you have read our{" "}
            <a href='javascript:void(0)' className="privacy-policy" onClick={openPrivacy}>
              Privacy&nbsp;Policy
            </a>{" "}
            and{" "}
            <a href='javascript:void(0)' className="privacy-policy" onClick={openUsRight}>
              US&nbsp;State&nbsp;Privacy&nbsp;Rights&nbsp;Notice
            </a>
            .
          </p>
          <div className="btn-section">
            <a onClick={() => signUp()} className="btn-new btn-danger-new">
              SIGN UP
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsLetter;
