import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { getCafeAndBarApi } from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import { getSlidesWatcher } from "../../../../store/application/action";
import "./cafeandbar.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { downloadFile } from "../../../../utils/helper";
import { ICafeBar } from "../../../../models/sidemenu";
import { useLocation } from "react-router";
import { ROUTES } from "../../../../constants/path";

interface IFaqProps {}

const CafeAndBar: FC<IFaqProps> = () => {
  const { countryId, slides, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    slides: state.applicationReducer.slides,
    currentCinema: state.applicationReducer.currentCinema
  }));

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName:API_REQUEST_TYPE.CAFEBAR,
        })
      );

      const getData = async () => {
        const response = await getCafeAndBarApi({
          countryId,
          isCafeBar: "1",
        });

        setData(response.map((v:ICafeBar)=>{
          if (v.screenType == 'angelika-bar') {
            v['pdfUrl'] = currentCinema?.angBarPdf;
          } else {
            v['pdfUrl'] = currentCinema?.stateCafePdf;
          }    
          return v;
        }));
        // dispatch(setFooterType(response.data.deals.length));
      };
      getData();
    }
  }, [countryId]);

  return (
    <div className="cafe-bar-wrappper">
      <div className="slider-filter">
        <Slider className="banner-slider" data={slides} isDotsNeeded={false} />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="thumb_wrap p-0 px-0">
            {data.map((list: any) => (
              <div className="list_thumb">
                <div className="thumb_content_wrap">
                  <div className="thumb_content">
                    <div className="thumb_title">{list.title}</div>
                    <div className="description">
                      <HtmlRender content={list.description1} />
                    </div>
                    <div className="cust_desc_thumb_btn_wrap">
                      <div className="description">
                        <HtmlRender content={list.description2} />
                      </div>
                      <div className="thumb_btn_wrap">
                        <a href="javascript:void(0)"
                          className="white_btn"
                          onClick={() => downloadFile(list.pdfUrl)}
                        >
                          {list.screenType == "angelika-bar"
                            ? "Drinks"
                            : "Current"}{" "}
                          MENU
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="thumb_image">
                  <img src={list.imageUrl} alt="" />
                  <div className="img_info"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CafeAndBar;
