import { FC, useEffect, useState } from "react";
import { pageContentApi } from "../../../services/sideMenus";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import Slider from "../../../components/UI/Slider";
import HtmlRender from "../../../components/UI/HtmlRender";
import './accessibility.scss';
interface IAccessibilityProps {}
const Accessibility: FC<IAccessibilityProps> = () => {
    const { countryId, currentCinema } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId,
        currentCinema: state.applicationReducer.currentCinema,
      }));
      const [accessibility, setAccessibility] = useState<any>(null);
      const [accessibilityContent, setAccessibilityContent] = useState([]);
      const [slides, setSlides] = useState<any>([]);
    const getAccessibility = async () => {
        const {
          data: { data, status },
        } = await pageContentApi({
          countryId,
          pageName: `accessibilty`,
        });
        if (status && data ) {
            setAccessibility(data);
            setAccessibilityContent(data.accessibility);
            setSlides([
                {
                  bannerlink: "",
                  buttonLink: "",
                  imageId: data.image,
                  mobileImageUrl: "",
                  mobileLink: "",
                  ratingIcon: "",
                  ratingName: "",
                  showBannerInfo: 0,
                  imageUrl:
                    URLS.CLOUDFRONT_URL +
                    "/" +
                    IMAGE_URL_CONFIG.IMAGE_PATH +
                    "/" +
                    data.image +
                    IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT,
                },
              ])
        }
     };
    
      useEffect(() => {
        if (countryId) {
          getAccessibility();
        }
      }, [countryId]);
    return(
        <div className="accessibility-wrapper">
            <div className="slider-filter">
                <Slider className="banner-slider-us" data={[...slides]} />
            </div>
            <div className="accessibility-container">
                <div className="accessibility-header">
                <h3>{accessibility?.title}</h3>
                <HtmlRender content={accessibility?.description} />
                </div>
            </div>
            <div className="accessibility-section">
                {accessibilityContent.map((v: any, i: number) => (
                    <div
                        className={`pre-show`}
                        style={{
                        backgroundImage: `${i%2 == 0?'linear-gradient(90deg,rgba(0, 0, 0, 0.4) 10%,rgba(0, 0, 0, 1) 99.05%)': ''}, url(${
                            URLS.CLOUDFRONT_URL +
                            "/" +
                            IMAGE_URL_CONFIG.IMAGE_PATH +
                            "/" +
                            v.image +
                            IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT
                        })`,
                        }}
                    >
                        <HtmlRender content={v.Title} {...{ className: "title" }} />
                        <HtmlRender
                        content={v.Description}
                        {...{ className: "mt-3 mb-0" }}
                        />
                    </div>
                ))}
      </div>
        </div>
    )
}
export default Accessibility;