import React, { FC, useEffect, useMemo, useState } from "react";
import Select, { components, ValueContainerProps } from "react-select";
import "./selectDropdown.scss";

const SelectDropdown: FC<any> = ({
  field,
  touched,
  error,
  onChange,
  setFieldValue,
  options,
  value,
  ...props
}) => {
  const [selected, setSelected] = useState<any>(
    props.isMulti
      ? options.filter((v: any) => value && value.includes(v.value))
      : options.find((v: any) => v.value === value)
  );

  const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
    let [values, input] = children as any;

    if (Array.isArray(values)) {
      const plural = values.length === 1 ? "" : "s";
      values = `${values.length} item${plural} selected`;
    }

    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };

  useEffect(() => {
    if (selected) {
      if (onChange) {
        onChange(
          props.isMulti ? selected.map((v: any) => v.value) : selected.value
        );
      }
      if (setFieldValue && field) {
        setFieldValue(
          field,
          props.isMulti ? selected.map((v: any) => v.value) : selected.value
        );
      }
    }
  }, [selected]);

  const values = useMemo(() => {
    if (value) {
      return props.isMulti
        ? options.filter((v: any) => value && value.includes(v.value))
        : options.find((v: any) => v.value === value);
    } else {
      return {};
    }
  }, [value, options]);

  return (
    <>
      <Select
        className={`custom-drop-down ${touched && error ? "error-border" : ""}`}
        classNamePrefix="custom-select"
        value={values}
        options={options}
        onChange={(e: any) => setSelected(e)}
        closeMenuOnSelect={!props.isMulti}
        hideSelectedOptions={false}
        components={{
          ValueContainer,
        }}
        {...props}
      />
      {touched && error ? (
        <div className="error_message f-12">{error}</div>
      ) : null}
    </>
  );
};

export default SelectDropdown;
