import { ICancelTicket, ITicketVoucher } from '../../models/tickets';
import { ACTIONS, ISetModalActionType } from './type';


export function getTicketBookingDetails(payload: any) {
    return { type: ACTIONS.GET_TICKET_BOOKING_DETAILS, payload: payload }
}

export function ticketBookingDetailsSuccess(payload: any) {
    return { type: ACTIONS.GET_TICKET_BOOKING_DETAILS_SUCCESS, payload: payload }
}

export function ticketBookingDetailsError(payload: any) {
    return { type: ACTIONS.GET_TICKET_BOOKING_DETAILS_ERROR, payload: payload }
}

export function toggleTicketModal(payload: boolean) {
    return { type: ACTIONS.TOGGLE_MODAL, payload: payload }
}

export function setModal(payload: ISetModalActionType) {
    return { type: ACTIONS.SET_MODAL, payload: payload }
}

export function setTicketVoucher(payload: ITicketVoucher[]) {
    return { type: ACTIONS.SET_TICKET_VOUCHER, payload: payload }
}

export function cancelTicketAction(payload: ICancelTicket) {
    return { type: ACTIONS.CANCEL_TICKET, payload: payload }
}

export function cancelTicketSuccess(payload: any) {
    return { type: ACTIONS.CANCEL_TICKET_SUCCESS, payload: payload }
}

export const addTicketSuccess = (payload: any) => {
    return { type: ACTIONS.ADD_TICKET_SUCCESS, payload: payload };
}

export const updateSession = (payload: string) => {
    return { type: ACTIONS.UPDATE_SESSION_DETAILS, payload };
};

export const updateBookingId = (payload: string) => {
    return { type: ACTIONS.UPDATE_BOOKING_ID, payload };
};

export function setSeatSwapDetails(payload: boolean) {
    return { type: ACTIONS.SEAT_SWAP_DETAILS, payload: payload }
}

export const futureFbAction = (payload: boolean) => {
    return { type: ACTIONS.FUTURE_FB, payload };
};

export const preOrderConcession = (payload: any) => {
    return { type: ACTIONS.PREORDER_CONCESSION, payload };
};

export const setCanBookFood = (payload: number) => {
    return { type: ACTIONS.CAN_BOOK_FOOD, payload }
}