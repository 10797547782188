import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE, URLS } from "../../../../constants/url";
import { getReelClubApi } from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import {
  getSlidesWatcher,
  openSliderAction,
} from "../../../../store/application/action";
import "./reelclub.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { showLogin } from "../../../../store/auth/action";
import { COUNTRY } from "../../../../constants/app";

interface IFaqProps {}

const ReelClub: FC<IFaqProps> = () => {
  const { countryId, slides, isLoggedIn } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    slides: state.applicationReducer.slides,
    isLoggedIn: state.authReducer.isLoggedIn,
  }));

  const dispatch = useDispatch();
  const [content, setContent] = useState();

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.ACCOUNT,
        })
      );

      const getData = async () => {
        const response = await getReelClubApi({
          countryId: countryId,
          pageName: API_REQUEST_TYPE.REELCLUB,
        });
        setContent(response.data.content);
      };
      getData();
    }
  }, [countryId]);

  const join = () => {
    if (countryId === COUNTRY.STA) {
      window.open(URLS.CLUB_STA, "_blank");
      return false;
    } else {
      if (isLoggedIn) {
        dispatch(showLogin(false));
        dispatch(openSliderAction({ open: true, type: "account" }));
      } else {
        dispatch(showLogin(true));
      }
    }
  };

  return (
    <>
      <section>
        <Slider data={slides} />
        {countryId !== COUNTRY.STA?<div className="split_section_header">
          <div className="label">Reel Club</div>
        </div>: null}
      </section>
      <section className="real_content_wrap min_screen_height">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <HtmlRender {...{ className: "description" }} content={content} />
            </div>
            <div className="col-md-12 text-center my-5">
              <button
                type="button"
                className="btn black_btn_border"
                onClick={join}
              >
                {countryId === COUNTRY.STA
                  ? "Click Here to join"
                  : "Join Reel Club here"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReelClub;
