import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Deal from "../../../../components/Menu/SideMenu/Deal";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import {
  getAdvertisingApi,
  getDealsApi,
  getHistroyApi,
} from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import {
  getSlidesWatcher,
  setFooterType,
} from "../../../../store/application/action";
import "./advertising.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";

interface IFaqProps {}

const Advertising: FC<IFaqProps> = () => {
  const { countryId, slides } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    slides: state.applicationReducer.slides,
  }));

  const dispatch = useDispatch();
  const [content, setContent] = useState();

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.ADVERTISING,
        })
      );

      const getDeals = async () => {
        const response = await getAdvertisingApi({
          countryId,
          pageName: API_REQUEST_TYPE.ADVERTISING,
        });

        setContent(response.data.content);
        // dispatch(setFooterType(response.data.deals.length));
      };
      getDeals();
    }
  }, [countryId]);

  return (
    <>
      <section className="container-fluid">
        <Slider data={slides} />
        <div className="split_section_header">
          <div className="label">Advertising</div>
        </div>
      </section>
      <section className="real_content_wrap min_screen_height">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <HtmlRender {...{ className: "description" }} content={content} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Advertising;
