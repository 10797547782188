import React, { FC, useEffect, useMemo, useState } from "react";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMovieData } from "../../../../models/cinema";
import "./allShowTmes.scss";
import { ROUTES } from "../../../../constants/path";
import moment from "moment";
import { enableShowTime } from "../../../../utils/helper";
import { LOCATION_MAPPER } from "../../../../constants/app";

interface IAllShowTimesProps {
  session: IMovieData;
}

const AllShowTimes: FC<IAllShowTimesProps> = ({ session }) => {

  const {countryId} = useSelector((state: RootState)=>({
    countryId: state.applicationReducer.countryId
  }))
  const navigate = useNavigate();

  const onClickBuy = (data: IMovieData) => {
    let movieGroupId = "";
    if (
      data.showdates &&
      data.showdates[0] &&
      data.showdates[0].showtypes &&
      data.showdates[0].showtypes[0] &&
      data.showdates[0].showtypes[0].showtimes
    ) {
      movieGroupId =
        data.showdates[0].showtypes[0].showtimes[0].ScheduledFilmId;
    }
    navigate(`/${LOCATION_MAPPER[countryId][data.theater]}/movies/details/${movieGroupId}`);
  };


  return (
    <div className="img-content-parent col-xl-3 col-lg-4 col-md-6 col-12">
      <div className="img-container" onClick={() => onClickBuy(session)}>
        <img src={session.film_image_large_size} alt="Movie Image" />
      </div>
      <div className="thumbnail-content">
        <h3>{session.name}</h3>
        <div className="action">
          <div className="left">
            <img src="/images/img-icon.svg" alt="Reading Cinemas" />
            92%
          </div>
          <div className="right">
            {enableShowTime(session) && session.showdates && session.showdates.length > 0 ? (
              <a
                href="javascript:void(0)"
                className="btn-border-danger-new"
                onClick={() => onClickBuy(session)}
              >
                BUY TICKETS
              </a>
            ) : 
              (!enableShowTime(session) && session.showdates && session.showdates.length > 0)? <a href="javascript:void(0)" className="btn-border-danger-new border-0 btn-border-disable">
              SALE NOT STARTED
            </a>:
              <a href="javascript:void(0)" className="btn-border-danger-new border-0">
                SOLD OUT
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllShowTimes;
