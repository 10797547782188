import React, { FC, useEffect, useState } from "react";
import "./eatAndDrink.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import { eatAndDrinkApi } from "../../../../services/films";
import { IEatAndDrink } from "../../../../models/foodbeverage";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { decode } from "html-entities";

interface IEatAndDrinkProps {}

const EatAndDrink: FC<IEatAndDrinkProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eatDrink, setEatDrink] = useState<IEatAndDrink[]>([]);

  const getData = async () => {
    const { data } = await eatAndDrinkApi({
      cinemaId: currentCinema.slug,
      countryId: countryId,
    });
    if (Array.isArray(data) && data.length > 0) {
      const [value] = data;
      const { Tiles } = value;
      setEatDrink(Tiles);
    }
  };

  useEffect(() => {
    getData();
  }, [countryId, currentCinema]);

  const orderNow = (v: IEatAndDrink) => {
      navigate(`/${currentCinema?.alias}/${ROUTES.EAT_AND_DRINK}${v.LinkedToTab?`?tab=${v.LinkedToTab}`:''}`);
  };
  const download = (v: IEatAndDrink) => {
      window.open(`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${v.DownloadFile}.pdf`, "_blank")?.focus();
  };

  return (
    <section className="eat-drinks-section">
      {eatDrink.length > 0 ? (
        <>
          <div className="header">
            <div>&nbsp;</div>
            <h2 className="heading">EAT & DRINK</h2>
          </div>
          <div className="eat-drinks-content">
            <div className="img-section row justify-content-center">
              {eatDrink.map((v) => (
                <div className="col-md-6">
                  <div className="img-content-parent">
                    <div className="image-container">
                      <img
                        src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.MainImage}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT}`}
                      />
                    </div>
                    <div className="img-content">
                      <div
                        className={"action"}
                      >
                        <div className="left">
                          <h4 className="header">
                            <span>{decode(v.Title)}</span>
                            </h4>
                        </div>
                        <div className={
                          v.DownloadMenu === "1" &&
                          v.DownloadFile &&
                          v.OrderNow === "1"
                            ? "right"
                            : "right single"
                        }>
                          {v.DownloadMenu === "1" && v.DownloadFile ? (
                            <a
                              href="javascript:void(0)"
                              className="btn btn-border-danger-new mx-2"
                              onClick={()=>download(v)}
                              target="_blank"
                            >
                              DOWNLOAD MENU
                            </a>
                          ) : null}
                          {v.OrderNow === "1" ? (
                            <a
                              href="javascript:void(0)"
                              className="btn btn-border-danger-new"
                              onClick={() => orderNow(v)}
                            >
                              ORDER NOW
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default EatAndDrink;
