import React, { FC, useEffect, useMemo, useState } from "react";
import "./signatureProgram.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { getSignatureProgramsApi } from "../../../../services/films";
import { ISignatureProgramming } from "../../../../models/cinema";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/path";

interface ISignatureProgramProps {}
const SignatureProgram: FC<ISignatureProgramProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const limitLength = 4;

  const [signatureData, setSignatureData] = useState<ISignatureProgramming[]>(
    []
  );
  const navigate = useNavigate();
  const [signature, setSignature] = useState<ISignatureProgramming[]>([]);
  const [limit, setLimit] = useState<number>(limitLength);

  const getData = async () => {
    const {
      data: { data, statusCode },
    } = await getSignatureProgramsApi({
      cinemaId: currentCinema.slug,
      status: API_REQUEST_TYPE.GET_SIGNATURE_PROGRAMMING,
      countryId: countryId,
      flag: "",
    });
    if (statusCode === 200 && Array.isArray(data)) {
      setSignatureData(data);
      setSignature(data.slice(0, limit));
    }
  };

  const onShowMore = () => {
    if (signatureData.length != signature.length) {
      setSignature(signatureData.slice(0, limit + limitLength));
      setLimit(limit + limitLength);
    }
  };

  const onShowLess = () => {
    setSignature(signatureData.slice(0, limitLength));
    setLimit(limitLength);
  };

  const showMore = useMemo(() => {
    return signatureData.length > limitLength && signatureData.length != signature.length;
  }, [signatureData, signature]);

  const showLess = useMemo(() => {
    return signatureData.length > limitLength && signatureData.length == signature.length;
  }, [signatureData, signature]);

  useEffect(() => {
    getData();
  }, [countryId, currentCinema]);

  return (
    <section className="signatures-series-section">
      {signature.length > 0?<div className="container-content img-section-parent-two-list img-section-bottom-description">
        <div className="signatures-series-content">
          <h2 className="heading">OUR SIGNATURE PROGRAMMING</h2>
          <div className="img-section row">
            {signature.map((v) => (
              <div className="col-md-6 my-2">
                <div className="card h-100" onClick={()=>navigate(`/${currentCinema?.alias}/${ROUTES.SIGNATURE_PROGRAM.replace(':uniqueKey',encodeURIComponent(v.uniqueKey))}`)}>
                  <img
                    className="card-img-top"
                    src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.mainImage}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT}`}
                  />
                  <div className="card-body">
                    <p className="card-text">
                      <HtmlRender content={v.Description} />
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {showMore ? (
            <div className="show-more">
              <p onClick={onShowMore}>SHOW MORE</p>
            </div>
          ) : null}
           {showLess ? (
            <div className="show-more">
              <p onClick={onShowLess}>SHOW LESS</p>
            </div>
          ) : null}
        </div>
      </div>:null}
    </section>
  );
};

export default SignatureProgram;
