import { Formik, useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import {
  GIFT_CARD_TYPE,
  GiftType,
  ICard,
  stateANGCard,
  stateANGSCard,
  stateAUSCard,
  stateNZCard,
  stateUSCard,
} from "../../../models/giftcard";
import { getCardDesignApi } from "../../../services/giftshop";
import { RootState } from "../../../store";
import {
  addGiftCart,
  getCardCategory,
  getCardDesignWatcher,
  setGiftFormVisible,
  setGiftModalVisible,
  setGiftShopTab,
} from "../../../store/giftshop/action";
import {
  ICardCategory,
  ICardData,
  ICardDesign,
  ICardFormated,
  ICartData,
  IImages,
  IPhysicalCard,
} from "../../../store/giftshop/type";
import { createValidationSchema } from "../../../utils/validatorSchema";
import "./giftcardform.scss";
import moment from "moment";
import TextInput from "../../UI/TextInput";
import { BRANDS, COUNTRY } from "../../../constants/app";
import SelectDropdown from "../../UI/DropDown/SelectDropDown";
import Slick from "react-slick";
import "./slider.scss";
import "react-calendar/dist/Calendar.css";
import { ROUTES } from "../../../constants/path";
import { Link, useMatch, useNavigate } from "react-router-dom";
import Calendar from "../../UI/Calendar";
import HtmlRender from "../../UI/HtmlRender";

interface iGiftCardFormProps {
  card?: ICard | null;
  isEdit?: boolean;
  item?: ICartData;
  index?: any;
  removeGiftCard?: any;
}
const defaultMaxGiftAmount = 1000;
const GiftCardForm: React.FC<iGiftCardFormProps> = ({
  card,
  isEdit,
  item,
  index,
  removeGiftCard,
}) => {
  const {
    currentCinema,
    currentTab,
    brandId,
    countryId,
    cardDesigns,
    cardCategory,
    cards,
    cart,
  } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId as BRANDS,
    currentCinema: state.applicationReducer.currentCinema,
    cardCategory: state.giftCardReducer.cardCategory,
    currentTab: state.giftCardReducer.currentTab,
    countryId: state.applicationReducer.countryId,
    cardDesigns: state.giftCardReducer.cardDesigns,
    cards: state.giftCardReducer.cards,
    cart: state.giftCardReducer.cart,
  }));
  const navigate = useNavigate();


  useEffect(() => {
    if (countryId) {
      dispatch(
        getCardCategory({
          countryId,
        })
      );
    }
  }, [countryId]);

  const LINK = {
    [BRANDS.AU]: {
      GIFTSHOP: {
        LABEL: "GIFT SHOP",
        TO: `/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`,
        PATHS: [useMatch(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`)],
      },
      GIFT_CART: `/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`,
    },
    [BRANDS.US]: {
      GIFTSHOP: {
        LABEL: "GIFT SHOP",
        TO: `/${currentCinema?.alias}/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`,
        PATHS: [
          useMatch(
            `/${currentCinema?.alias}/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`
          ),
        ],
      },
      GIFT_CART: `/${currentCinema?.alias}/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`,
    },
  };

  const settings = {
    className: "",
    centerPadding: "0px",
    dots: false,
    lazyload: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: false,
    autoplayspeed: 500,
    cssEase: "linear",
    fade: true,
    slidesToScroll: 1,
    initialSlide: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    arrows: true,
    beforeChange: (current: any, next: any) => {
      changeCard(next);
    },
  };

  const [physical, setPhysical] = useState<boolean>(false);
  const [cardDesignData, setCardDesignData] = useState([]);
  const [currentCard, setCurrentCard] = useState<ICard | null>(null);
  const [tabId, setTabId] = useState<string>("");
  const [sliders, setSliders] = useState<ICardFormated[]>([]);
  const [page, setPage] = useState(0);
  const [pickDate, setPickDate] = useState(moment().toISOString());
  const [state, setState] = useState<any>([]);
  const [isShow, setisShow] = useState<boolean>(false);
  const [terms, setTerms] = useState<any>([]);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const dispatch = useDispatch();
  const pagerViewRef = useRef<any>();

  useEffect(() => {
    if (isEdit) {
      const pageId = item?.giftcardType!;
      switch (pageId) {
        case GiftType.ecard:
          dispatch(setGiftShopTab("e-gift"));
          break;
        case GiftType.physical:
          dispatch(setGiftShopTab("physical"));
          break;
        case GiftType.exp:
          dispatch(setGiftShopTab("experience"));
          break;
      }
    }
  }, [item]);

  useEffect(() => {
    if (card) {
      setCurrentCard(card);
    }
  }, [card]);
  useEffect(() => {
    if (currentTab) {
      setTabId(currentTab);
    }
  }, [currentTab]);

  const getCardDesign = async () => {
    await dispatch(getCardDesignWatcher({ countryId: countryId }));
  };

  useEffect(() => {
    if (
      currentCard &&
      cardDesigns &&
      cardDesigns.length > 0 &&
      tabId === GIFT_CARD_TYPE.E_GIFT_CARD
    ) {
      const cardsSlides: ICardFormated[] = [];
      cardDesigns.forEach((obj: ICardDesign) => {
        obj.images.forEach((img: IImages) => {
          cardsSlides.push({
            cardId: currentCard.id,
            cardName: obj.value,
            description: currentCard.description,
            // image: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${img.id}${IMAGE_URL_CONFIG.LARGE_IMAGE_EXT}`,
            image: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${img.name}`,
            imgId: img.id,
            qty: 1,
            tax: currentCard.tax,
            price: currentCard?.price ? Number(currentCard.price) : 0,
            type: "",
            isVariablePriceItem: currentCard?.IsVariablePriceItem
              ? currentCard?.IsVariablePriceItem
              : false,
            maximumVariablePriceInCents:
              currentCard?.MaximumVariablePriceInCents
                ? Number(currentCard.MaximumVariablePriceInCents)
                : 0,
            minimumVariablePriceInCents:
              currentCard?.MinimumVariablePriceInCents
                ? Number(currentCard.MinimumVariablePriceInCents)
                : 0,
          });
        });
      });
      setSliders(cardsSlides);
      const design = cardDesigns.map((v: ICardData) => ({
        label: v.value,
        value: v.value,
      }));
      setCardDesignData(design);
    }
  }, [currentCard, cardDesigns]);

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    validationSchema:
      tabId === GIFT_CARD_TYPE.E_GIFT_CARD
        ? createValidationSchema("eGiftCard")
        : tabId === GIFT_CARD_TYPE.PHYSICAL_CARD
        ? createValidationSchema("physicalGiftCard")
        : tabId === GIFT_CARD_TYPE.EXPERIENCES_CARD
        ? createValidationSchema("experiences")
        : null,
    initialValues: {
      deliveryDate: isEdit && item?.deliveryDate ? item.deliveryDate : pickDate,
      recipientName: isEdit && item?.recipientName ? item.recipientName : "",
      confirmRecipientEmail:
        isEdit && item?.confirmRecipientEmail ? item.confirmRecipientEmail : "",
      recipientemail: isEdit && item?.recipientemail ? item.recipientemail : "",
      senderName: isEdit && item?.senderName ? item.senderName : "",
      phone: isEdit && item?.phone ? item.phone : "",
      address1: isEdit && item?.address1 ? item.address1 : "",
      address2: isEdit && item?.address2 ? item?.address2 : "",
      city: isEdit && item?.city ? item?.city : "",
      state: isEdit && item?.state ? item?.state : "",
      postCode: isEdit && item?.postCode ? item?.postCode : "",
      giftMessage: isEdit && item?.giftMessage ? item.giftMessage : "",
      quantity: isEdit && item?.quantity ? item.quantity : 1,
      giftamount: isEdit && item?.giftamount ? item.giftamount : 0,
      id: isEdit && item?.id ? item.id : "",
      image: isEdit && item?.image ? item.image : "",
      imgId: isEdit && item?.imgId ? item.imgId : "",
      description: isEdit && item?.description ? item.description : "",
      price: isEdit && item?.price ? item.price : 0,
      tax: isEdit && item?.tax ? item.tax : 0,
      giftcardType: isEdit && item?.giftcardType ? item.giftcardType : "",
      itemTotal: isEdit && item?.itemTotal ? item.itemTotal : 0,
      isVariablePriceItem:
        isEdit && item?.isVariablePriceItem ? item.isVariablePriceItem : false,
      minimumVariablePriceInCents:
        isEdit && item?.minimumVariablePriceInCents
          ? item.minimumVariablePriceInCents
          : 0,
      maximumVariablePriceInCents:
        isEdit && item?.maximumVariablePriceInCents
          ? item.maximumVariablePriceInCents
          : 0,
      carddesign: isEdit && item?.carddesign ? item.carddesign : "",
      eGiftcardDesign:
        isEdit && item?.eGiftcardDesign ? item.eGiftcardDesign : "",
      type: isEdit && item?.type ? item.type : "",
    },
    onSubmit: async (values) => {
      let cartArray = [...cart];
      if (isEdit) {
        cartArray[index] = values;
        dispatch(addGiftCart(cartArray));
        await dispatch(setGiftModalVisible(false));
      } else {
        cartArray.push(values);
        await dispatch(addGiftCart(cartArray));
      }
      if (brandId === BRANDS.US) {
        navigate(
          `/${currentCinema?.alias}/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`
        );
      } else {
        navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`);
      }
    },
  });

  // formik
  useEffect(() => {
    if (isEdit && item && tabId && cards.length > 0) {
      const cardData = cards.find(
        (v: ICard | IPhysicalCard) => v.id === item.id
      );
      if (cardData) {
        setCurrentCard(cardData);
      }
    }
  }, [isEdit, item, tabId, cards]);

  useEffect(() => {
    if (currentCard) {
      if (tabId === GIFT_CARD_TYPE.E_GIFT_CARD) {
        getCardDesign();
      } else {
        const cardsSlides: ICardFormated[] = [];
        cards.forEach((obj: IPhysicalCard) => {
          cardsSlides.push({
            cardId: obj.id,
            cardName: obj.name,
            description: obj.name,
            image: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${obj.image}`,
            imgId: "",
            qty: 1,
            price: obj.price,
            tax: 0,
            isVariablePriceItem: obj?.type === 2 ? true : false,
            type: obj?.type,
            maximumVariablePriceInCents: defaultMaxGiftAmount,
            minimumVariablePriceInCents: Number(obj.price),
          });
        });
        const design = cards.map((v: IPhysicalCard) => ({
          label: v.name,
          value: v.name,
        }));
        setCardDesignData(design);
        setSliders(cardsSlides);
        if (!isEdit) {
          let tempIndex = cardsSlides.findIndex(
            (v: ICardFormated) => v.cardId === currentCard.id
          )!;
          const tempCardDesign =
            tempIndex > -1 ? cardsSlides[tempIndex] : cardsSlides[0];

          let tempDesign = cards.find(
            (v: IPhysicalCard) => v.name === currentCard.name
          )!;

          if (!tempDesign) {
            tempDesign = design[0];
          }

          setPage(tempIndex);
          pagerViewRef?.current?.slickGoTo(index);
          setFieldValue("carddesign", tempDesign.name);
          setFieldValue("quantity", tempCardDesign.qty);
          setFieldValue("price", tempCardDesign.price);
          setFieldValue("tax", tempCardDesign.tax);
          setFieldValue("description", tempCardDesign.description);
          setFieldValue("image", tempCardDesign.image);
          setFieldValue("id", tempCardDesign.cardId);
          setFieldValue("imgId", tempCardDesign.imgId);
          setFieldValue("type", tempCardDesign.type);
          setFieldValue(
            "giftcardType",
            tabId === GIFT_CARD_TYPE.PHYSICAL_CARD
              ? GiftType.physical
              : GiftType.exp
          );
          setFieldValue(
            "isVariablePriceItem",
            tempCardDesign.isVariablePriceItem
          );
          setFieldValue(
            "minimumVariablePriceInCents",
            tempCardDesign.minimumVariablePriceInCents
          );
          setFieldValue(
            "maximumVariablePriceInCents",
            tempCardDesign.maximumVariablePriceInCents
          );
          if (tempCardDesign.isVariablePriceItem) {
            setFieldValue(
              "giftamount",
              tempCardDesign.minimumVariablePriceInCents
            );
          }
        } else {
          const index = cardsSlides.findIndex(
            (v: ICardFormated) => v.cardId === item?.id
          );

          if (index > -1) {
            setPage(index);
            pagerViewRef?.current?.slickGoTo(index);
          }
        }
      }
    }
  }, [currentCard]);

  useEffect(() => {
    if (cardCategory) {
      let terms;
      if (tabId == GIFT_CARD_TYPE.E_GIFT_CARD) {
        terms = cardCategory.filter(
          (item: ICardCategory) => item.category_name == "E-Gift Card"
        );
      } else if (tabId == GIFT_CARD_TYPE.PHYSICAL_CARD) {
        terms = cardCategory.filter(
          (item: ICardCategory) => item.category_name == "Physical Gift Card"
        );
      } else {
        terms = cardCategory.filter(
          (item: ICardCategory) => item.category_name == "Experiences"
        );
      }
      setTerms(terms.map((v: ICardCategory) => v.category_tc));
    }
  }, [cardCategory]);

  useEffect(() => {
    if (currentTab) {
      currentTab !== GIFT_CARD_TYPE.E_GIFT_CARD
        ? setPhysical(true)
        : setPhysical(false);
    }
  }, [currentTab]);

  useEffect(() => {
    if (
      currentCard &&
      cardDesigns &&
      cardDesigns.length > 0 &&
      tabId === GIFT_CARD_TYPE.E_GIFT_CARD
    ) {
      const cardsSlides: ICardFormated[] = [];
      cardDesigns.forEach((obj: ICardDesign) => {
        obj.images.forEach((img: IImages) => {
          cardsSlides.push({
            cardId: currentCard.id,
            cardName: obj.value,
            description: currentCard.description,
            //image: IMAGE_URL_CONFIG.IMAGEPATH + img.name,
            image: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${img.name}`,
            imgId: img.id,
            qty: 1,
            tax: currentCard.tax,
            price: currentCard?.price ? Number(currentCard.price) : 0,
            type: "",
            isVariablePriceItem: currentCard?.IsVariablePriceItem
              ? currentCard?.IsVariablePriceItem
              : false,
            maximumVariablePriceInCents:
              currentCard?.MaximumVariablePriceInCents
                ? Number(currentCard.MaximumVariablePriceInCents)
                : 0,
            minimumVariablePriceInCents:
              currentCard?.MinimumVariablePriceInCents
                ? Number(currentCard.MinimumVariablePriceInCents)
                : 0,
          });
        });
      });
      setSliders(cardsSlides);
      const design = cardDesigns.map((v: ICardData) => ({
        label: v.value,
        value: v.value,
      }));
      setCardDesignData(design);
      if (!isEdit && cardsSlides.length > 0) {
        setFieldValue("carddesign", design[0].value);
        setFieldValue("quantity", cardsSlides[0].qty);
        setFieldValue("price", cardsSlides[0].price);
        setFieldValue("tax", cardsSlides[0].tax);
        setFieldValue("giftcardType", GiftType.ecard);
        setFieldValue("description", cardsSlides[0].description);
        setFieldValue("image", cardsSlides[0].image);
        setFieldValue("id", cardsSlides[0].cardId);
        setFieldValue("imgId", cardsSlides[0].imgId);
        setFieldValue("type", cardsSlides[0].type);
        setFieldValue(
          "isVariablePriceItem",
          cardsSlides[0].isVariablePriceItem
        );
        setFieldValue(
          "minimumVariablePriceInCents",
          cardsSlides[0].minimumVariablePriceInCents
        );
        setFieldValue(
          "maximumVariablePriceInCents",
          cardsSlides[0].maximumVariablePriceInCents
        );
        if (cardsSlides[0].isVariablePriceItem) {
          setFieldValue(
            "giftamount",
            cardsSlides[0].minimumVariablePriceInCents
          );
        }
        pagerViewRef?.current?.slickGoTo(0);
      } else {
        const index = cardsSlides.findIndex(
          (v: ICardFormated) => v.imgId === item?.imgId
        );
        if (index > -1) {
          setPage(index);
          pagerViewRef?.current?.slickGoTo(index);
        }
      }
    }
  }, [currentCard, cardDesigns]);

  const close = () => {};
  const onClickPlusQuantity = () => {
    if (values.quantity < 10) {
      setFieldValue("quantity", values.quantity + 1);
    }
  };

  const onClickMinusQuantity = () => {
    if (values.quantity > 1) {
      setFieldValue("quantity", values.quantity - 1);
    }
  };

  const goHomePage = () => {
    dispatch(giftFormVisible(false));
  };

  const showTerms = () => {
    setisShow(!isShow);
  };
  useEffect(() => {
    switch (countryId) {
      case COUNTRY.AUS:
        setState(stateAUSCard);
        break;
      case COUNTRY.STA:
        setState(stateANGSCard);
        break;
      case COUNTRY.ANG:
        setState(stateANGCard);
        break;
      case COUNTRY.NZ:
        setState(stateNZCard);
        break;
      case COUNTRY.US:
        setState(stateUSCard);
        break;
    }
  }, [countryId]);

  useEffect(() => {
    if (Number(values.giftamount) > 0 && values.isVariablePriceItem) {
      setFieldValue(
        "itemTotal",
        Number(values.quantity) * Number(values.giftamount)
      );
    } else {
      setFieldValue(
        "itemTotal",
        Number(values.quantity) * Number(values.price)
      );
    }
  }, [values.giftamount, values.quantity, values.price]);

  const updateFormFields = (payload: any) => {
    setFieldValue("isVariablePriceItem", payload.isVariablePriceItem);
    setFieldValue(
      "minimumVariablePriceInCents",
      payload.minimumVariablePriceInCents
    );
    setFieldValue(
      "maximumVariablePriceInCents",
      payload.maximumVariablePriceInCents
    );
    if (payload.isVariablePriceItem) {
      setFieldValue("giftamount", payload.minimumVariablePriceInCents);
    } else {
      setFieldValue("giftamount", payload.price);
    }
    setFieldValue("quantity", 1);
    setFieldValue("price", payload.price);
  };

  const onChangeCardDesign = (value: string) => {
    const index = sliders.findIndex((v: ICardFormated) => v.cardName === value);
    if (index > -1) {
      setPage(index);
      pagerViewRef?.current?.slickGoTo(index);
      setFieldValue("carddesign", sliders[index].cardName);
      setFieldValue("image", sliders[index].image);
      setFieldValue("description", sliders[index].description);
      setFieldValue("imgId", sliders[index].imgId);
      setFieldValue("type", sliders[index].type);
      setFieldValue("cardId", sliders[index].cardId);
      setFieldValue("id", sliders[index].cardId);
      setFieldValue("tax", sliders[index].tax);

      if (
        tabId !== GIFT_CARD_TYPE.E_GIFT_CARD &&
        (!isEdit || (isEdit && !isFirstTime))
      ) {
        updateFormFields({
          isVariablePriceItem: sliders[index].isVariablePriceItem,
          minimumVariablePriceInCents:
            sliders[index].minimumVariablePriceInCents,
          maximumVariablePriceInCents:
            sliders[index].maximumVariablePriceInCents,
          price: sliders[index].price,
          cardName: sliders[index].cardName,
          image: sliders[index].image,
          cardId: sliders[index].cardId,
          imgId: sliders[index].imgId,
        });
      }
      setIsFirstTime(false);
    }
  };

  const changeCard = (index: number) => {
    const card: ICardFormated = sliders[index];
    if (card) {
      setFieldValue("carddesign", card.cardName);
      setFieldValue("image", card.image);
      setFieldValue("description", card.description);
      setFieldValue("imgId", card.imgId);
      setFieldValue("type", card.type);
      setFieldValue("cardId", card.cardId);
      setFieldValue("id", card.cardId);
      setFieldValue("tax", card.tax);
    }
    if (
      tabId !== GIFT_CARD_TYPE.E_GIFT_CARD &&
      (!isEdit || (isEdit && !isFirstTime))
    ) {
      updateFormFields({
        isVariablePriceItem: card?.isVariablePriceItem!,
        minimumVariablePriceInCents: card?.minimumVariablePriceInCents!,
        maximumVariablePriceInCents: card?.maximumVariablePriceInCents!,
        price: card?.price!,
      });
    }
    setIsFirstTime(false);
  };
  const checkAmountLimit = () => {
    if (values.isVariablePriceItem) {
      if (Number(values.giftamount) > values.maximumVariablePriceInCents) {
        setFieldValue("giftamount", values.maximumVariablePriceInCents);
      } else if (
        Number(values.giftamount) < values.minimumVariablePriceInCents
      ) {
        setFieldValue("giftamount", values.minimumVariablePriceInCents);
      }
    } else {
      if (Number(values.giftamount) > defaultMaxGiftAmount) {
        setFieldValue("giftamount", defaultMaxGiftAmount);
      } else if (Number(values.giftamount) < values.price) {
        setFieldValue("giftamount", values.price);
      }
    }
  };

  const onClickAmount = (type: string) => {
    const amount = Number(values.giftamount) + (type === "plus" ? 1 : -1);
    if (values.isVariablePriceItem) {
      if (
        amount > values.maximumVariablePriceInCents ||
        amount < values.minimumVariablePriceInCents
      ) {
        checkAmountLimit();
      } else {
        setFieldValue("giftamount", amount);
      }
    } else {
      if (amount > defaultMaxGiftAmount || amount < values.price) {
        checkAmountLimit();
      } else {
        setFieldValue("giftamount", amount);
      }
    }
  };

  return (
    <div className="gift-card-form">
      <div className="card-form-main">
        <div className="row card-form">
          <div className="col-12">
            <h6 className="card-title">
              <span>{values.description}</span>
              <button
                type="button"
                className="close btn-close pull-right close-btn-poponly"
                aria-label="Close"
                onClick={() => {
                  close();
                }}
              >
                <span aria-hidden="true" className="visually-hidden">
                  <img src="closeIcon" alt="" />
                </span>
              </button>
            </h6>
          </div>
          <div className="row card-form-section">
            <div className="col-12 col-md-6">
              <div className="col-12">
                <div className="custom-slick-slider">
                  <Slick {...settings} className="carousel" ref={pagerViewRef}>
                    {sliders &&
                      sliders?.map((slide: any, index: number) => (
                        <div
                          key={`slide_${index}`}
                          className={`banner-image ${slide.textPosition}`}
                        >
                          <img
                            src={slide.image}
                            alt={slide.cardName}
                            title={slide.cardName}
                          />
                        </div>
                      ))}
                  </Slick>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="col-12">
                <label className="form-label">
                  {physical
                    ? "Physical Gift Card Amount"
                    : "E-Gift Card Design"}
                </label>
                <div className=" form-group cust-eGiftcardDesign">
                  <SelectDropdown
                    field={"carddesign"}
                    options={cardDesignData}
                    setFieldValue={setFieldValue}
                    touched={touched.carddesign!}
                    error={errors.carddesign}
                    value={values.carddesign}
                    onChange={(value: string) => {
                      onChangeCardDesign(value);
                    }}
                  />
                </div>
              </div>
              {values.isVariablePriceItem ? (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label className="form-label">
                        Gift Amount (nearest dollar)*
                      </label>
                      <div className="form-group quantity_wrap">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="btn_minus"
                              onClick={() => {
                                onClickAmount("minus");
                              }}
                            >
                              &nbsp;
                            </span>
                          </div>
                          <TextInput
                            value={`${values.giftamount}`}
                            field={"quantity"}
                            handleChange={handleChange}
                            touched={touched.quantity!}
                            error={errors.quantity}
                            type={"number"}
                          />
                          <div className="input-group-append">
                            <span
                              className="btn_plus"
                              onClick={() => {
                                onClickAmount("plus");
                              }}
                            >
                              &nbsp;
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-12">
                <div className="row">
                  <div className="col-7 col-md-7">
                    <label className="form-label">Quantity</label>
                    <div className="quantity_wrap">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button
                            className="btn_minus"
                            onClick={() => {
                              onClickMinusQuantity();
                            }}
                          >
                            &nbsp;
                          </button>
                        </div>
                        <TextInput
                          value={values.quantity}
                          field={"quantity"}
                          handleChange={handleChange}
                          touched={touched.quantity!}
                          error={errors.quantity}
                          type={"number"}
                          disabled
                        />
                        <div className="input-group-append">
                          <button
                            className="btn_plus"
                            onClick={() => {
                              onClickPlusQuantity();
                            }}
                          >
                            &nbsp;
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 col-md-5">
                    <div className="form-group-quant">
                      <label className="form-label">ITEM TOTAL</label>
                      <h2>
                        <strong>
                          ${values.itemTotal ? values.itemTotal : 0}
                        </strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="col-12 pl-0">
                {!physical ? (
                  <div className="col-12 col-md-6">
                    <label className="form-label">Delivery Date*</label>
                    <Calendar
                      minDate={new Date()}
                      format={"DD MMM YYYY"}
                      field={"deliveryDate"}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      touched={touched.deliveryDate!}
                      error={errors.deliveryDate}
                      value={values.deliveryDate}
                    />
                  </div>
                ) : null}
              </div>
              <div className="col-12">
                <label className="form-label">Recipient's Name*</label>
                <div className="form-group">
                  <TextInput
                    value={values.recipientName}
                    field={"recipientName"}
                    handleChange={handleChange}
                    touched={touched.recipientName!}
                    error={errors.recipientName}
                    type={"text"}
                    maxLength={20}
                  />
                </div>
              </div>
              {physical ? (
                <div className="col-12">
                  <label className="form-label">
                    Delivery Address Line 1* (Not a PO Box)
                  </label>
                  <div className=" form-group">
                    <TextInput
                      value={values.address1}
                      field={"address1"}
                      handleChange={handleChange}
                      touched={touched.address1!}
                      error={errors.address1}
                      type={"text"}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {physical ? (
                <div className="col-12">
                  <label className="form-label">Delivery Address Line 2</label>
                  <div className=" form-group">
                    <TextInput
                      value={values.address2}
                      field={"address2"}
                      handleChange={handleChange}
                      touched={touched.address2!}
                      error={errors.address2}
                      type={"text"}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {physical ? (
                <div className="col-12">
                  <label className="form-label">City*</label>
                  <div className=" form-group">
                    <TextInput
                      value={values.city}
                      field={"city"}
                      handleChange={handleChange}
                      touched={touched.city!}
                      error={errors.city}
                      type={"text"}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {physical ? (
                <div className="col-12">
                  <div className="form-group-flex-main">
                    <div className="form-group-flex">
                      <label className="form-label">State*</label>
                      <div className=" form-group">
                        <SelectDropdown
                          field={"state"}
                          options={state}
                          setFieldValue={setFieldValue}
                          touched={touched.state!}
                          error={errors.state}
                          value={values.state}
                        />
                      </div>
                    </div>
                    <div className="form-group-flex">
                      <label className="form-label">Postcode*</label>
                      <div className=" form-group">
                        <TextInput
                          value={values.postCode}
                          field={"postCode"}
                          handleChange={handleChange}
                          touched={touched.postCode!}
                          error={errors.postCode}
                          type={"number"}
                          maxLength={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {!physical ? (
                <div className="col-12">
                  <label className="form-label">Recipient's Email*</label>
                  <div className=" form-group">
                    <TextInput
                      value={values.recipientemail}
                      field={"recipientemail"}
                      handleChange={handleChange}
                      touched={touched.recipientemail!}
                      error={errors.recipientemail}
                      type={"email"}
                      maxLength={40}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {!physical ? (
                <div className="col-12">
                  <label className="form-label">
                    Confirm Recipient’s Email*
                  </label>
                  <div className=" form-group">
                    <TextInput
                      value={values.confirmRecipientEmail}
                      field={"confirmRecipientEmail"}
                      handleChange={handleChange}
                      touched={touched.confirmRecipientEmail!}
                      error={errors.confirmRecipientEmail}
                      type={"email"}
                      maxLength={40}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-12 col-md-6">
              <div className="col-12">
                <label className="form-label">Sender’s (Your) Name*</label>
                <div className="form-group">
                  <TextInput
                    value={values.senderName}
                    field={"senderName"}
                    handleChange={handleChange}
                    touched={touched.senderName!}
                    error={errors.senderName}
                    type={"text"}
                    maxLength={20}
                  />
                </div>
              </div>
              <div className="col-12">
                <label className="form-label">Sender's (Your) Phone Number*</label>
                <div className="form-group">
                  <TextInput
                    value={values.phone}
                    field={"phone"}
                    handleChange={handleChange}
                    touched={touched.phone!}
                    error={errors.phone}
                    type={"text"}
                    maxLength={13}
                    minLength={8}
                  />
                </div>
              </div>
              <div className="col-12">
                <label className="form-label">Gift Message: </label>
                <div className="form-group">
                  <textarea
                    title="Enter your Gift Message"
                    maxLength={200}
                    value={values.giftMessage}
                    onChange={handleChange}
                    name="giftMessage"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="row btn-section">
            <div className="col-md-12 movie_footer_btn_el ml-auto">
              {!isEdit ? (
                <div className="movie_button_wrap d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn green_btn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Add to cart
                  </button>
                  {/* </Link> */}
                  <Link to={LINK[brandId].GIFTSHOP.TO}>
                    <button
                      type="button"
                      className="btn black_btn ml-3"
                      onClick={() => {
                        dispatch(setGiftFormVisible(false));
                      }}
                    >
                      Gift Shop Home
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="movie_button_wrap d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn light_gray_btn"
                    onClick={() => {
                      removeGiftCard && removeGiftCard();
                    }}
                  >
                    Remove Gift
                  </button>
                  <button
                    type="submit"
                    className="btn black_btn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="cust-terms-condition">
        <div className="cust-terms-condition-inner">
          <h2>
            <a
              href="javascript:void(0)"
              style={{ display: isShow ? "none" : "block" }}
              onClick={() => {
                showTerms();
              }}
            >
              View terms & conditions <img src={URLS.ARROW_BOTTOM_WHITE} alt="" />
            </a>
          </h2>
          <h2>
            <a
              href="javascript:void(0)"
              style={{ display: isShow ? "block" : "none" }}
              onClick={() => {
                showTerms();
              }}
            >
              Terms & conditions <img src={URLS.CLOSE_WHITE_ICON} alt="" />
            </a>
          </h2>
          <div
            className="termscond-cont"
            style={{ display: isShow ? "block" : "none" }}
          >
            <HtmlRender content={terms}/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GiftCardForm;

function giftFormVisible(arg0: boolean): any {
  throw new Error("Function not implemented.");
}
