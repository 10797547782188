import { FC } from "react";
import "./downloadApp.scss";
import { divide } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IDownloadAppProps {}

const DownloadOurApp: FC<IDownloadAppProps> = () => {
  const resolution = window.innerWidth;
  const isMobile = resolution >= 320 && resolution <= 480;

  const { settings } = useSelector((state: RootState)=>({
    settings: state.applicationReducer.settings
  }))

  return (
    <section className="download-app-section">
      <div className="container-content">
        <h2 className="heading">DOWNLOAD OUR APP</h2>
        <div className="row image-content">
          <div className="col-12 col-md-4">
            <p className="title">The fastest and easiest way to visit.</p>
            <div className="store_icons">
              <a href={settings.playStoreLink} target="_blank"><img src="/images/GOOGLE_PLAY_ICON.svg" /></a>
             <a href={settings.appleStoreLink} target="_blank"><img src="/images/APPLE_STORE_ICON.svg" /></a>
            </div>
          </div>
          <div className="col-12 col-md-8">
            <div className="download-app-content">
              <img className="w-100" src="/images/APP SECTION DESKTOP.png" />
            </div>
          </div>
        </div>
        <div className="accessibility d-none d-md-block">
          <img src="/images/Accessibility Icon.svg" />
        </div>
      </div>
    </section>
  );
};

export default DownloadOurApp;
