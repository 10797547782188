import { FC, useEffect, useState } from "react";
import { IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import { ROUTES } from "../../../constants/path";
import "./careers.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { pageContentApi } from "../../../services/sideMenus";
import {
  IAdvertise,
  IAdvetisement,
  ICareer,
  ICareers,
} from "../../../models/sidemenu";
import HtmlRender from "../../../components/UI/HtmlRender";
import { useNavigate } from "react-router-dom";
import {
  setContentModal,
  toggleContentModal,
} from "../../../store/application/action";
import Slider from "../../../components/UI/Slider";

interface ICareersProps {}

const Careers: FC<ICareersProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [careers, setCarrers] = useState<ICareers | null>(null);
  const [slides, setSlides] = useState<any>([]);

  const getCareers = async () => {
    const {
      data: { data, status },
    } = await pageContentApi({
      countryId,
      pageName: `${currentCinema?.slug}#careers`,
    });
    if (status && data) {
      setCarrers(data);
      setSlides([
        {
          bannerlink: "",
          buttonLink: "",
          imageId: data.image,
          mobileImageUrl: "",
          mobileLink: "",
          ratingIcon: "",
          ratingName: "",
          showBannerInfo: 0,
          imageUrl:
            URLS.CLOUDFRONT_URL +
            "/" +
            IMAGE_URL_CONFIG.IMAGE_PATH +
            "/" +
            data.image +
            IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT,
        },
      ]);
    }
  };

  useEffect(() => {
    if (countryId) {
      getCareers();
    }
  }, [countryId]);

  const employemnt = () => {
    dispatch(
      setContentModal({ type: ROUTES.CAREER})
    );
    dispatch(toggleContentModal(true));
  };

  return (
    <div className="careers-wrapper">
      <div className="slider-filter">
        <Slider className="banner-slider-us" data={[...slides]} />
      </div>
      {careers ? (
        <div className="careers-container">
          <div className="careers-header">
            <h3>{careers.title}</h3>
            <hr />
            <HtmlRender
              {...{ className: "mt-4" }}
              content={careers.description}
            />
          </div>

          <div className="careers-section">
            {careers.carrers.map((v: ICareer) => (
              <div className="jobs">
                <HtmlRender content={v.Title} {...{ className: "title" }} />
                <HtmlRender
                  content={v.Description}
                  {...{ className: "mt-3 mb-0" }}
                />
              </div>
            ))}
            <div className="contact">
              <button onClick={employemnt}>
                APPLY NOW
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Careers;
