import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ICards } from "../../../../models/payment";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { toast } from "react-toastify";
import {
  CAPTCHA_SITE_KEY,
  COUNTRY,
  MONTH_LIST,
} from "../../../../constants/app";
import "./creditCard.scss";
import { getSavedCardWatcher } from "../../../../store/auth/action";
import { setReinitializeScroll } from "../../../../store/application/action";
import ReCAPTCHA from "react-google-recaptcha";
import TextInput from "../../../UI/TextInput";
import SelectDropdown from "../../../UI/DropDown/SelectDropDown";
import { futureYear } from "../../../../utils/helper";

export interface ICardRef {
  submit: () => any;
}

interface ICardProps {
  fromPage?: string;
}
const CreditCard = forwardRef(
  ({ fromPage }: ICardProps, ref: React.Ref<ICardRef>) => {
    const { savedCards, isLoggedIn, userDetails, countryId, currentCinema } =
      useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId,
        savedCards: state.authReducer.savedCards,
        isLoggedIn: state.authReducer.isLoggedIn,
        userDetails: state.authReducer.userDetails,
        currentCinema: state.applicationReducer.currentCinema,
      }));

    const dispatch = useDispatch();
    const [isCreditCardVisible, setIsCreditCardVisible] = useState(false);
    const [years, setYears] = useState(false);
    const [months, setMonths] = useState(false);
    const [selectedCard, setSelectedCard] = useState<ICards | null>(null);
    const [prevmonths, setprevMonths] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [isTermsChecked, setIsTermsChecked] = useState(false);

    useEffect(() => {
      if (userDetails && isLoggedIn) {
        const saveCardData = {
          countryId: countryId,
          emailId: userDetails?.email,
          memberRefId: userDetails?.result?.memberRefId,
        };
        dispatch(getSavedCardWatcher(saveCardData));
      }
    }, [userDetails && isLoggedIn]);

    useEffect(() => {
      if (isLoggedIn && savedCards && savedCards.length > 0) {
        const card = savedCards.find((v: ICards) => v.defaultCard === 1);
        if (card) {
          setSelectedCard(card);
        } else {
          setSelectedCard(savedCards[0]);
        }
      }
    }, [savedCards, isLoggedIn]);

    const {
      handleChange,
      handleSubmit,
      handleBlur,
      values,
      errors,
      touched,
      resetForm,
      setFieldValue,
      isValid,
      validateForm,
    } = useFormik({
      enableReinitialize: true,
      initialValues: {
        cardNumber: "",
        cardHolderName: "",
        cvc: "",
        month: "",
        year: "",
        email: userDetails?.email!,
        saveCard: false,
        captcha: false,
      },
      validationSchema: createValidationSchema(
        fromPage && fromPage === "giftshop" ? "giftshopCard" : "card"
      ),
      onSubmit: (values) => {},
    });

    useImperativeHandle(ref, () => ({
      submit: () => {
        setErrorMessage("");
        if (isCreditCardVisible) {
          if (!selectedCard) {
            setErrorMessage(
              "Please choose your card or pay with new credit card to proceed"
            );
            toast.error("Please select your credit card");
            return null;
          }
          return { card: selectedCard, action: "savedCard" };
        } else {
          handleSubmit();
          if (!isValid || !values.cardNumber) {
            return null;
          }
          return {
            card: {
              expiryDate:
                values.month +
                "/" +
                values.year.toString().substring(2, values.year.length),
              expirymonth: values.month,
              expiryyear: values.year,
              cardNumber: values.cardNumber,
              cardHolderName: values.cardHolderName,
              cvv: values.cvc,
              email: values.email,
            },
            action: "newCard",
            saveCard: values.saveCard,
          };
        }
      },
    }));

    const creditCardfrom = () => {
      const state = !isCreditCardVisible;
      validateForm();
      if (!state) {
        if (savedCards && savedCards.length > 0) {
          const card = savedCards.find((v: ICards) => v.defaultCard === 1);
          if (card) {
            setSelectedCard(card);
          } else {
            setSelectedCard(savedCards[0]);
          }
        }
      }
      setErrorMessage("");
      setIsCreditCardVisible(state);
    };

    const cardSelected = (card: ICards) => {
      setSelectedCard(card);
    };

    useEffect(() => {
      if (countryId !== COUNTRY.NZ && isLoggedIn && savedCards.length > 0) {
        setIsCreditCardVisible(true);
      } else {
        setIsCreditCardVisible(false);
      }
      setReinitializeScroll(true);
    }, [savedCards]);

    return (
      <div>
        {isCreditCardVisible ? (
          <div className="saved_card_list_wrap">
            <div className="saved_card_wrap">
              <div className="title">Your saved credit cards</div>
              <div className="header">
                <p>Card Number</p>
                <p>Name On Card</p>
                <p>Expires on</p>
              </div>
              <div className="body">
                <div className="card_details_wrap mCustomScrollbar">
                  {savedCards.map((card: ICards) => (
                    <div className="card_details_list">
                      <div className="custom_checkbox">
                        <input
                          type="radio"
                          name="saveCardList"
                          checked={selectedCard?.token === card.token}
                          onClick={() => cardSelected(card)}
                        />
                        <div className="state p-primary">
                          <label className="form-label">
                            <span>ending in {card.cardNumber.slice(-3)}</span>
                            <span>{card.cardName}</span>
                            <span>
                              {card.expiryDate.slice(0, 2)}/
                              {card.expiryDate.slice(-2)}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {errorMessage ? (
                <div className="error_message mb-3 text-center">
                  <span>{errorMessage}</span>
                </div>
              ) : null}
            </div>
            <div className="custom_checkbox">
              <input type="checkbox" onClick={creditCardfrom} />
              <div className="state p-primary">
                <label className="form-label">
                  Pay with Another Credit card
                </label>
              </div>
            </div>
          </div>
        ) : (
          <div className="row add_new_card_wrap">
            {isLoggedIn && savedCards.length > 0 ? (
              <div className="col-12">
                <div className="custom_checkbox save_card_list_checkbox">
                  <input type="checkbox" onClick={creditCardfrom} />
                  <div className="state p-primary">
                    <label className="form-label">Use Saved Credit card</label>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="login-form col-md-12">
              <div className="row mt-4 ">
                <div className={`col-12  ${fromPage === "membership"?'col-md-6':'col-md-12'}`}>
                  <label className="form-label">CARD HOLDER NAME</label>
                  <div className="form-group">
                    <TextInput
                      value={values.cardHolderName}
                      field={"cardHolderName"}
                      handleChange={handleChange}
                      touched={touched.cardHolderName!}
                      error={errors.cardHolderName}
                    />
                  </div>
                </div>
                <div className={`col-12 ${fromPage === "membership"?'col-md-6':'col-md-12'}`}>
                  <label className="form-label">CARD NUMBER</label>
                  <div className="form-group">
                    <TextInput
                      value={values.cardNumber}
                      field={"cardNumber"}
                      handleChange={handleChange}
                      touched={touched.cardNumber!}
                      error={errors.cardNumber}
                      maxLength={16}
                    />
                  </div>
                </div>
                <div className="col-4 col-md-4">
                  <label className="form-label">EXPIRY</label>
                  <div className=" form-group">
                    <SelectDropdown
                      field={"month"}
                      options={MONTH_LIST}
                      setFieldValue={setFieldValue}
                      touched={touched.month!}
                      error={errors.month}
                      value={values.month}
                    />
                  </div>
                </div>
                <div className="col-4 col-md-4">
                  <label className="form-label empty"></label>
                  <SelectDropdown
                    field={"year"}
                    options={futureYear()}
                    setFieldValue={setFieldValue}
                    touched={touched.year!}
                    error={errors.year}
                    value={values.year}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <label className="form-label">CVC</label>
                  <div className="form-group">
                    <TextInput
                      type="password"
                      minLength={3}
                      maxLength={4}
                      value={values.cvc}
                      field={"cvc"}
                      handleChange={handleChange}
                      touched={touched.cvc!}
                      error={errors.cvc}
                    />
                  </div>
                </div>
                {fromPage === "giftshop" ? (
                  <div className="col-12 col-md-12">
                    <label className="form-label">Email Address</label>
                    <div className="form-group">
                      <TextInput
                        value={values.email}
                        field={"email"}
                        handleChange={handleChange}
                        touched={touched.email!}
                        error={errors.email}
                      />
                    </div>
                  </div>
                ) : null}
                {isLoggedIn && countryId !== COUNTRY.NZ ? (
                  <div className="col-12">
                    <div className="custom_checkbox">
                      <input type="checkbox" />
                      <div className="state p-primary">
                        <label className="form-label">Save Credit card</label>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default CreditCard;
