import React,{ FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../../../../../components/UI/DropDown/FoodItem";
import { URLS } from "../../../../../../../constants/url";
import { RootState } from "../../../../../../../store";
import "./cuztomizeItems.scss";
import { toast } from "react-toastify";
import { FoodBeverageItems } from "../../../../../../../store/foodAndBeverage/type";
import { addFoodItemsCart, updateFoodItemsCart } from "../../../../../../../store/foodAndBeverage/action";

export interface CustomiseProps {
  item?: any;
  index?: number;
  onEditQty?: () => void;
  //onSave?: (item: object) => void;
  onSave?: any;
  onBack?: any;
  onRemove?: any;
  onEdit?: (item: object, index: number) => void;
}

const CustomizeItems: FC<CustomiseProps> = ({
  item,
  index,
  onSave,
  onBack,
  onRemove,
  onEdit,
}) => {

  const { countryId, hasGL, seat, orderCartData } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      hasGL: state.ticketBookingReducer.hasGL,
      seat: state.ticketBookingReducer.ticketDetails.selectedSeats,
      orderCartData: state.foodBeverageReducer.orderCartData,
    })
  );

  // const deliveryInfo = {
  //   RowId: "",
  //   SeatNumber: "A1",
  //   AreaNumber: 0,
  //   ColumnIndex: 0,
  //   RowIndex: 0,
  //   Comment: "First Half",
  //   DeliveryOption: 1,
  //   SessionId: ""
  // };

  const dispatch = useDispatch();  
  const modIndex = item.options.findIndex((value:any) => value._comment === "ModifierGroups");
  const smartIndex = item.options.findIndex((value:any) => value._comment === "smartModifierGroups");
  const [quantity, setQuantity] = useState(item.quantity);
  const [total, setTotal] = useState(item.price*item.quantity);
  const [deliveryInfo, setDeliveryInfo] = useState<any>();

  useEffect(()=> {
    if (hasGL && seat && seat.length > 0) {

      if (item.state) {
        setDeliveryInfo({
          RowId: item.DeliveryInfo.RowIndex,
          SeatNumber:  item.DeliveryInfo.SeatNumber,
          AreaNumber:  item.DeliveryInfo.AreaNumber,
          ColumnIndex:  item.DeliveryInfo.ColumnIndex,
          RowIndex:  item.DeliveryInfo.RowIndex,
          Comment: item.DeliveryInfo.Comment,
          DeliveryOption: item.DeliveryInfo.DeliveryOption
        })
      }
      else {
        setDeliveryInfo({
          RowId: seat[0].RowIndex,
          SeatNumber:  seat[0].SeatId,
          AreaNumber:  seat[0].AreaNumber,
          ColumnIndex:  seat[0].ColumnIndex,
          RowIndex:  seat[0].RowIndex,
          Comment: "First Half",
          DeliveryOption: 1
        })
      }
      
    }
  }, [seat, hasGL])

  if (smartIndex != -1 && !item.state) {
    item.options[smartIndex].selected = item.options[smartIndex].choices[0].modifiers;
  }

  const addToOrder = () => {
    if (item.options.length > 1) {
      if ((item.options[modIndex].choices.length == Object.values(item.options[modIndex].selected).length) && 
        (item.options[smartIndex].selected.length > 0)) {
          handleAddToOrder(item);
      }
      else {
        toast.error("Please choose the items");
      }
    }
    else {
      if (smartIndex != -1 && (item.options[smartIndex].selected.length > 0)) {
        handleAddToOrder(item);
      }
      else if (modIndex != -1 && (item.options[modIndex].choices.length == Object.values(item.options[modIndex].selected).length)) {
        handleAddToOrder(item);
      }
      else {
        toast.error("Please choose the items");
      }
    }    
  }

  const handleAddToOrder = (items: FoodBeverageItems) => {
    const data = JSON.parse(JSON.stringify(items)); 
    const item = {
      ...data, 
      DeliveryInfo: deliveryInfo,
      delivery: hasGL?"seat":"self",
      totalPrice: (Number(data.price) * data.quantity).toFixed(2)
    }
    if (item.state) {
      const newArray = JSON.parse(JSON.stringify(orderCartData));      
      newArray[item.index] = item;
      dispatch(
        updateFoodItemsCart([...newArray])
      );
    } 
    else {
      dispatch(
        addFoodItemsCart({
          ...item
        })
      );
    }   
    onBack();
  };

  const setSelecetedFoodItem = (option: any, parentIndex: number) => {        
    if (item.options[modIndex].selected && item.options[modIndex].selected[parentIndex] != 'undefined') {
      item.options[modIndex].selected[parentIndex] = option;
    }
    else {
      item.options[modIndex].selected.splice(parentIndex, 0, option);
    }

  }

  const setSelecetedIngredients = (event: any, choice: any, parentIndex: number) => {            
    if(event.target.checked) {
      item.options[smartIndex].selected.push(choice);                  
      item.options[smartIndex].unselected = item.options[smartIndex].unselected.filter((item: any) => item.id != choice.id);        
    }
    else {
      item.options[smartIndex].unselected.push(choice);
      item.options[smartIndex].selected = item.options[smartIndex].selected.filter((item: any) => item.id != choice.id);        
    }
  }

  const checkIngredient = (selectedItem: any) => {        
    return item.options[smartIndex].selected.some(function(el: any){ return el.id === selectedItem.id});
  }

  const onImgError = (event: any) => {
    event.target.src = URLS.DEFAULT_FOOD_IMAGE;
  };

  const setDeliverTime = (choice: any) => {            
    setDeliveryInfo({...deliveryInfo,Comment:choice.description})
  }

  const setSelecetedSeat = (seat: any) => {       
    setDeliveryInfo({
      ...deliveryInfo, 
      RowId:seat.RowIndex,
      SeatNumber:seat.SeatId,
      AreaNumber:  seat.AreaNumber,
      ColumnIndex:  seat.ColumnIndex,
      RowIndex:  seat.RowIndex
    })
  }

  const onClickMinus = () => {        
    if (item.quantity > 1) {
      item.quantity = item.quantity-1;
      setQuantity(item.quantity);
      setTotal(item.quantity*item.price); 
    }    
  }

  const onClickPlus = () => {        
    item.quantity = item.quantity+1;
    setQuantity(item.quantity);
    setTotal(item.quantity*item.price);
  }

  return (
    <>
    <div className="mobile_modal_pad food_customisation_wrap">
      <div className="row">
        <div className="col-md-6 pl-md-0">
          <div className="food_item_thumb_wrap d-flex flex-column justify-content-around">
            <div className="food_item_thumb">
              <img src={item.image} alt="Food Image" onError={onImgError} />
              <p><strong>{item.extendedDescription}</strong></p>
            </div>
            {
              hasGL == 1 ?            
              <div className="food_customisation_wrap w-100" style={{paddingLeft: "25px", paddingRight: "25px"}}>
                <div className="food_selection_wrap">
                  <label className="form-label">Food & Beverage Service</label>
                  <Dropdown 
                    placeHolder={deliveryInfo?.SeatNumber} 
                    options={seat.map((s:any)=>({...s, value: s.SeatId, description: s.SeatId}))}
                    onChange={setSelecetedSeat}
                    parentIndex="0"
                    labelText="Deliver to Seat "
                    dropdownText="Seat "
                    />
                </div>
                <div className="food_selection_wrap">
                  <label className="form-label">Deliver At</label>
                  <Dropdown 
                    placeHolder={deliveryInfo?.Comment}
                    options={[
                      {
                        "value": "First Half",
                        "description": "First Half"
                      },
                      {
                        "choice": "Second Half",
                        "description": "Second Half"
                      },
                    ]} 
                    onChange={setDeliverTime}
                    parentIndex="1"
                    labelImage={true}
                    />
                </div>
              </div>   
              : null 
            }
          </div>
        </div>
        <div className="col-12 col-md-6 pl-md-0">
          {
            item.options.map((options: any, opt: any) => (
              <div>
                {
                  (options._comment != 'smartModifierGroups') ? 
                  <div className="food_selection_wrap">              
                  {
                    options.choices.map((choices: any, i: any) => (
                      <Dropdown 
                        placeHolder={
                          options.selected[i] ? 
                          options.selected[i].description :
                          choices.description
                        } 
                        options={choices.modifiers} 
                        onChange={setSelecetedFoodItem}
                        parentIndex={i}
                        />
                    ))
                  }                    
                  </div>
                : null
                }
                {
                  (options._comment == 'smartModifierGroups') ?                 
                  <div className="modifier_groups_wrap">
                    {
                    options.choices.map((choices: any, i: any) => (
                      <>
                      <h6>Ingredients</h6>
                      <ul className="custom_checkbox_wrap">
                        {
                        choices.modifiers.map((choice: any, j: any) => (
                          <li>
                            <div className="custom_checkbox rhs_checkbox">
                              <input type="checkbox" value="1" defaultChecked={checkIngredient(choice)}
                                onChange={(event) => setSelecetedIngredients(event, choice, j)}/>
                              <div className="state p-primary">
                                <label className="form-label">
                                  <div>{choice.description} {choice.price > 0 ? <span className="addon_amt">+
                                      ${choice.price}</span> : null}</div>
                                </label>
                              </div>
                            </div>
                          </li>
                         ))
                        }
                      </ul>
                      </>
                     ))
                    }
                  </div>
                  : null
                }
              </div>
            ))
          }          
          <div className="amt_qantity_wrap">
            <div className="quantity_wrap">
              <label>QUANTITY</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span 
                    className={`btn_minus ${item && item.quantity > 1 && 'active'}`}
                    onClick={onClickMinus}
                    >
                    &nbsp;</span>
                </div>
                <input type="number" className="form-control" aria-label="qantity" 
                  disabled 
                  value={quantity}
                  />
                <div className="input-group-append">
                  <span 
                    className={`btn_plus ${item && item.quantity >= 10 && 'active'}`}
                    onClick={onClickPlus}
                    >
                    &nbsp;</span>
                </div>
              </div>
            </div>
            <div className="total_amt_wrap">
              <label>ITEM TOTAL</label>
              <p className="total_amt">${total.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-12 movie_footer_btn_el">
      {
        !item.state ? (      
          <div className="movie_button_wrap">
            <button type="button" className="btn gray_btn" onClick={onBack}>
              BACK
            </button>
            <button type="button" className="btn black_btn"
              onClick={addToOrder}>
              ADD TO ORDER
            </button>
          </div>
        ) : (
          <div className="movie_button_wrap">
            <button type="button" className="btn gray_btn" onClick={() => onRemove(item.index)}>
              REMOVE ORDER
            </button>
            <button type="button" className="btn black_btn"
              onClick={addToOrder}>
              SAVE ORDER
            </button>
          </div>
        )
      }
    </div>
  </>
  );
};

export default CustomizeItems;
