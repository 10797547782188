import React,{ FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Barcode from "react-barcode";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { upcomingTicketApi } from "../../../../services/auth";
import moment from "moment";
import { IUpcomingTicket } from "../../../../models/auth";

interface IUpcomingShowProps {}

const UpcoingShow: FC<IUpcomingShowProps> = () => {
  const { countryId, userDetails } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    userDetails: state.authReducer.userDetails,
  }));

  const [upcomingShow, setUpcomingShow] = useState<IUpcomingTicket | null>(
    null
  );

  const getUpcomingTickets = async () => {
    const payload = {
      countryId: countryId,
      emailId: userDetails?.email!,
      requestType: API_REQUEST_TYPE.UPCOMING_TRANSACTION,
    };
    const { status, response } = (await upcomingTicketApi(payload)) as any;
    if (status) {
      const {
        data: { data },
      } = response;
      setUpcomingShow(data);
    }
  };

  useEffect(() => {
    if (userDetails) {
      getUpcomingTickets();
    }
  }, [userDetails]);

  return upcomingShow ? (
    <div className="col-md-12">
      <div className="movie-detail-card section_divided">
        <div className="movie-card">
          <div className="media">
            <div className="media-left">
              <img
                src={upcomingShow.movieImage}
                className="media-object"
                alt={upcomingShow.movieName}
              />
            </div>
            <div className="media-body">
              <div className="movie_title">{upcomingShow.movieName}</div>
              <div className="movie_sub_title">
                {upcomingShow.cinemaName + " Cinema"}
              </div>
              <div className="show_details_wrap">
                <div className="show_details">
                  <div className="time">{upcomingShow.sesionTime}</div>
                  <div className="day">
                    {moment(upcomingShow.sesionDate).format("dddd D MMMM")}
                  </div>
                </div>
              </div>
              {upcomingShow.seats ? <div className="seat">SEATS</div> : null}
              <span className="seat_no">{upcomingShow.seats}</span>
            </div>
          </div>
          <div className={`ticket_info_details`}>
            <div className="amount_details">
              <div className="total">TOTAL</div>
              <div className="total-amount">
                $
                {(
                  +upcomingShow.ticketAmount +
                  +upcomingShow.BookingFee +
                  (upcomingShow.concessionAmount
                    ? +upcomingShow.concessionAmount
                    : 0.0)
                ).toFixed(2)}
              </div>
              <div className="oth-details">
                {upcomingShow.numberOfSeats}X SEATS: $
                {Number(upcomingShow.ticketAmount).toFixed(2)}
              </div>
              {upcomingShow.concessionAmount ? (
                <div className="oth-details">
                  FOOD PRICE: $
                  {upcomingShow.concessionAmount
                    ? upcomingShow.concessionAmount
                    : "0.00"}
                </div>
              ) : null}
              <div className="oth-details">
                BOOKING FEE: ${upcomingShow.BookingFee}
              </div>
            </div>
            <div className="ticket_bar_code">
              <Barcode
                width={1}
                height={50}
                displayValue={true}
                value={upcomingShow.bookingId ? upcomingShow.bookingId : ""}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default UpcoingShow;
