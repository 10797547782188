import React, { FC, useState } from "react";
import { IActivity } from "../../../../models/sidemenu";
import HtmlRender from "../../../UI/HtmlRender";
import "./activity.scss";
import { downloadFile } from "../../../../utils/helper";
import { URLS } from "../../../../constants/url";
interface IActivityProps {
  data: IActivity;
}

const Activitie: FC<IActivityProps> = ({ data }) => {
  const [imageSource, setImageSource] = useState(data.funstuff_image);
  const [error, setError] = useState(false);
  const handleImageError = () => {
    setImageSource(URLS.ERROR_IMAGE);
    setError(true);
  };
  return (
    <div className="activity-list-content">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="media">
              <div className="list_poster">
                {error ? (
                  <img
                    className="align-self-start"
                    src={imageSource}
                    alt={data.title}
                    title={data.title}
                  />
                ) : (
                  <img
                    className="align-self-start"
                    src={imageSource}
                    alt={data.title}
                    title={data.title}
                    onError={handleImageError}
                  />
                )}
              </div>
              <div className="media-body ">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="deal-head">{data.title}</h3>
                    <HtmlRender
                      {...{ className: "description" }}
                      content={data.description}
                    />
                    <div className="col-md-8 col-12 text-center">
                      <a
                        href={data.funstuff_file}
                        onClick={() => downloadFile(data.funstuff_file)}
                        className="btn black_btn download-btt"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activitie;
