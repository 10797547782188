import { ISeatLayout, ITicketDetail, ITicketType, ITicketVoucher, MODAL_TYPE, TICKET_FLOW_TYPES } from "../../models/tickets";
import { IFilms } from "../films/type";

export interface ITicketBookingState {
    error?: null | any;
    inProgress?: boolean;
    modal: {
        header: string;
        prevModal: MODAL_TYPE;
        modal: MODAL_TYPE;
        nextModal: MODAL_TYPE;
        type: TICKET_FLOW_TYPES;
        errorMsg?: string;
        data: any;
    };
    openModal?: boolean;
    isFutureFb?: boolean;
    preOrderFb?: any;
    films: IFilms | null;
    ticketTypeCount: number;
    ticketType: ITicketType[];
    seatLayout: ISeatLayout[][];
    sharedUserSession: ISharedUserSession[],
    timer: {
        minutes: number;
        seconds: number;
    };
    hasGL: number;
    ticketVoucher: ITicketVoucher[],
    ticketDetails: ITicketDetail | null;
    isMailSent: boolean | null;
    showCovid: boolean;
    seatSwapDetails: null;
    canBookFood: number;
}

export interface ISetModalActionType {
    header?: string;
    modal?: string;
    nextModal?: string;
    prevModal?: string;
    errorMsg?: string;
    type?: string;
    data?: any;
}

export interface ISharedUserSession {
    seats: string,
    userName: string,
    userSessionId: string
}


export const ACTIONS = {
    GET_TICKET_BOOKING_DETAILS: "GET_TICKET_BOOKING_DETAILS",
    GET_TICKET_BOOKING_DETAILS_SUCCESS: "GET_TICKET_BOOKING_DETAILS_SUCCESS",
    GET_TICKET_BOOKING_DETAILS_ERROR: "GET_TICKET_BOOKING_DETAILS_ERROR",
    TOGGLE_MODAL: "TOGGLE_MODAL",
    SET_MODAL: "SET_MODAL",
    SET_TICKET_VOUCHER: "SET_TICKET_VOUCHER",
    CANCEL_TICKET: "CANCEL_TICKET",
    CANCEL_TICKET_SUCCESS: "CANCEL_TICKET_SUCCESS",
    ADD_TICKET_SUCCESS: "ADD_TICKET_SUCCESS",
    UPDATE_SESSION_DETAILS: "UPDATE_SESSION_DETAILS",
    UPDATE_BOOKING_ID: "UPDATE_BOOKING_ID",
    SEAT_SWAP_DETAILS: "SEAT_SWAP_DETAILS",
    FUTURE_FB: "FUTURE_FB",
    PREORDER_CONCESSION: "PREORDER_CONCESSION",
    CAN_BOOK_FOOD: "CAN_BOOK_FOOD"
}