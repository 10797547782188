import { useFormik } from "formik";
import React, { FC, useMemo, useState } from "react";
import { createValidationSchema } from "../../../utils/validatorSchema";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import { TICKET_FLOW } from "../../../constants/ticket";
import { setModal } from "../../../store/ticketBooking/action";
import TextInput from "../../UI/TextInput";
import { signUpAsGuest } from "../../../store/auth/action";
import { concessionPriceCalculation } from "../../../utils/tickets";
import { error } from "console";
import { useLocation } from "react-router";

interface IGuestProps {}

const Guest: FC<IGuestProps> = () => {
  const {
    isLoggedIn,
    userDetails,
    countryId,
    ticketDetails,
    flims,
    modalType,
    nextModal,
    prevModal,
    orderCartData,
    ticketLessFb,
    seatSwapDetails,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    isLoggedIn: state.authReducer.isLoggedIn,
    userDetails: state.authReducer.userDetails,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    flims: state.ticketBookingReducer.films,
    modalType: state.ticketBookingReducer.modal.type,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    orderCartData: state.foodBeverageReducer.orderCartData,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    seatSwapDetails: state.ticketBookingReducer.seatSwapDetails,
  }));

  const dispatch = useDispatch();
    const { pathname } = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  const total = useMemo(() => {
    const concession = concessionPriceCalculation(orderCartData);
    return ((ticketDetails.total?ticketDetails.total:0) + concession).toFixed(2);
  }, [ticketDetails, orderCartData]);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: createValidationSchema("guest"),
    initialValues: {
      firstName: isLoggedIn ? userDetails?.firstName : "",
      lastName: isLoggedIn ? userDetails?.lastName : "",
      email: isLoggedIn ? userDetails?.email : "",
      phoneNumber: isLoggedIn
        ? userDetails?.phoneNumber
          ? userDetails.phoneNumber
          : userDetails?.result && userDetails.result?.loyaltyPhoneNumber
          ? userDetails.result.loyaltyPhoneNumber
          : ""
        : "",
      password: "",
      isChecked: false,
    },
    onSubmit: async (values) => {
      setErrorMessage("");
      const { firstName, lastName, email, phoneNumber, password } = values;
      const payload = {
        countryId: countryId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: !isLoggedIn ? password : undefined,
        phoneNumber: phoneNumber,
        saleId: ticketDetails?.userSessionId,
        theatreId: flims?.cinemaId,
        ticketlessFB: ticketLessFb
      };
      await dispatch(
        signUpAsGuest(
          payload,
          (response: any) => {
            const ticketType: TICKET_FLOW_TYPES = modalType;
            const next: MODAL_TYPE = nextModal;
            const modal = TICKET_FLOW[ticketType][next];
            dispatch(
              setModal({
                ...modal,
                type: modalType,
              })
            );
          },
          (error: any) => {
            setErrorMessage(error);
          }
        )
      );
    },
  });

  const prev = async () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const prev: MODAL_TYPE = prevModal;
    const modal = TICKET_FLOW[ticketType][prev];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  };

  const onCheckCreate = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("isChecked", checked);
    setFieldValue("password", "");
  };

  const isSeatSwap = useMemo(() => {
    return pathname.includes("/seat-swap/") && seatSwapDetails ? true : false;
  }, [pathname, seatSwapDetails]);

  return (
    <div className="login-form form_page">
      <div className="row">
        <div className="col-6 col-md-6 ">
          <label className="form-label">First Name*</label>
          <div className="form-group pass_show">
            <TextInput
              value={values.firstName}
              field={"firstName"}
              handleChange={handleChange}
              touched={touched.firstName!}
              error={errors.firstName}
            />
          </div>
        </div>
        <div className="col-6 col-md-6">
          <label className="form-label">Last Name</label>
          <div className="form-group pass_show">
            <TextInput
              value={values.lastName}
              field={"lastName"}
              handleChange={handleChange}
              touched={touched.lastName!}
              error={errors.lastName}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Email*</label>
          <div className="form-group pass_show">
            <TextInput
              value={values.email}
              field={"email"}
              handleChange={handleChange}
              touched={touched.email!}
              error={errors.email}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Phone Number*</label>
          <div className="form-group pass_show position-relative">
            <TextInput
              value={values.phoneNumber}
              field={"phoneNumber"}
              handleChange={handleChange}
              touched={touched.phoneNumber!}
              error={errors.phoneNumber}
            />
          </div>
        </div>
        {!isLoggedIn ? (
          <div className="col-md-12">
            <div className="custom_checkbox p-default form-group pass_show">
              <input type="checkbox" onClick={onCheckCreate} />
              <div className="state p-primary">
                <label className="form-label">Create account</label>
              </div>
            </div>
          </div>
        ) : null}
        {values.isChecked ? (
          <div className="col-md-12">
            <div>
              <label className="form-label">Password</label>
              <TextInput
                type="password"
                value={values.password}
                field={"password"}
                handleChange={handleChange}
                touched={touched.password!}
                error={errors.password}
              />
            </div>
          </div>
        ) : null}
        {errorMessage ? (
          <div className="err-message">{errorMessage}</div>
        ) : null}
      </div>
      {isLoggedIn ? (
        <div>
          <>
            <div className="welcome">Welcome back</div>
            <div className="my-account text-capti font-size-16">
              {userDetails?.firstName}
            </div>
          </>

          <div className="user-details">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="total">{isSeatSwap ? "NEW TOTAL" : "TOTAL ORDER"}</div>
                <div className="total-amount">${total}</div>
                {ticketDetails?.ticketTypes?.map((v: any) =>
                        v.qty > 0 ? (
                          <div>
                            <div className="total-det">
                              {v.qty} x {v.Description} SEATS: $
                              {(v.qty * v.price).toFixed(2)}
                            </div>
                          </div>
                        ) : null
                      )}
                {!ticketLessFb? <div className="total-det">
                  <span>
                    BOOKING FEE : ${ticketDetails?.bookingFees.toFixed(2)}
                  </span>
                </div>: null}
                {orderCartData?.map((foodItems: any) => (
                <div>
                  <div className="total-det">
                    {foodItems.quantity} x {foodItems.description} :{" "}
                    {foodItems.totalPrice}
                  </div>
                </div>
              ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="col-md-12 movie_footer_btn_el ml-auto">
        <div className="movie_button_wrap">
          <button type="button" className="btn gray_btn" onClick={prev}>
            BACK
          </button>
          <button
            type="button"
            className="btn black_btn"
            onClick={() => handleSubmit()}
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
};

export default Guest;
