import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../../../components/UI/Slider";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { screenCategoryApi } from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import {
  getSlidesWatcher,
  setFooterType,
} from "../../../../store/application/action";
import "./highlineTerrace.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { downloadFile } from "../../../../utils/helper";
import HelmetTitle from "../../../../components/Helmet";

interface IHighlineTerraceProps {}

const HighlineTerrace: FC<IHighlineTerraceProps> = () => {
  const { countryId, slides, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      slides: state.applicationReducer.slides,
      currentCinema: state.applicationReducer.currentCinema,
    })
  );

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const getData = async () => {
    const response = await screenCategoryApi({
      countryId,
      screenType: API_REQUEST_TYPE.HIGHLINE_TERRACE,
    });

    setData(
      response && response.length > 0
        ? response[0].content.map((v: any) => {
            v.file = v.buttons.find((v: any) => v.type === "file");
            v.images = v.buttons
              .filter((v: any) => v.type === "image")
              .map((v: any) => ({
                imageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.Id}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
                mobileImageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.Id}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
                showBannerInfo: 0,
              }));
            return v;
          })
        : []
    );
    dispatch(setFooterType(response && response.length > 0 ? 5 : 1));
  };

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.HIGHLINE_TERRACE,
        })
      );
      getData();
    }
  }, [countryId]);

  return (
    <div className="highline-terrace-wrapper">
      <HelmetTitle title="Highline Terrace" description="Highline Terrace"/>
      <div className="slider-filter">
        <Slider className="banner-slider" data={slides} isDotsNeeded={false} />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="thumb_wrap p-0 px-0">
            {data.map((list: any) => (
              <div className="list_thumb">
                <div className="thumb_image  d-md-none d-block">
                  <Slider className="banner-slider-us" data={list.images} />
                </div>
                <div className="thumb_content_wrap">
                  <div className="thumb_content">
                    <div className="thumb_title">{list.title}</div>
                    <div className="description">
                      {list.description ? (
                        <HtmlRender content={list.description} />
                      ) : null}
                      {list.description1 ? (
                        <HtmlRender content={list.description1} />
                      ) : null}
                      {list.description2 ? (
                        <HtmlRender content={list.description2} />
                      ) : null}
                      {list.description3 ? (
                        <HtmlRender content={list.description3} />
                      ) : null}
                    </div>
                    <div className="cust_desc_thumb_btn_wrap">
                      {list.showButton === 1 ? (
                        <div className="thumb_btn_wrap">
                          {list.buttonType === "file" && list.file ? (
                            <div className="thumb_btn_wrap">
                              <a
                                href="javascript:void(0)"
                                className="white_btn"
                                onClick={() =>
                                  downloadFile(
                                    `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${list.file.Id}.${list.file.extension}`
                                  )
                                }
                              >
                                {list.buttonName}
                              </a>
                            </div>
                          ) : list.buttonType === "link" &&
                            list.buttonLinkUrl ? (
                            <div className="thumb_btn_wrap">
                              <a
                                href={list.buttonLinkUrl}
                                className="white_btn"
                                target="blank"
                              >
                                {list.buttonName}
                              </a>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="thumb_image d-md-block d-none">
                  <Slider className="banner-slider-us" data={list.images} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighlineTerrace;
