import React, { FC, useEffect, useState, useRef } from "react";
import Slick from "react-slick";
import "./showTimeFilter.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { formatLocation } from "../../../../utils/formatter";
import { IDropDown } from "../../../../models/common";
import moment from "moment";
import { setQuickFilter } from "../../../../store/films/action";
import { SLICK_SETTING } from "../../../../constants/app";

interface IShowTimeFilterProps {
  session: IDropDown[];
  type: IDropDown[];
}

const ShowTimeFilter: FC<IShowTimeFilterProps> = ({ session, type }) => {
  
  const {
    quickFilters,
    cinemas,
    currentCinema,
    reinitializeFilter,
  } = useSelector((state: RootState) => ({
    currentCinema: state.applicationReducer.currentCinema,
    quickFilters: state.filmsReducer.quickFilters,
    cinemas: state.applicationReducer.cinemas,
    brandId: state.applicationReducer.brandId,
    nowShowing: state.filmsReducer.nowShowing,
    reinitializeFilter: state.applicationReducer.reinitializeFilter,
  }));

  const dispatch = useDispatch();
  const slideTypeRef = useRef<any>();
  const slideLocationRef = useRef<any>();
  const slideSessionRef = useRef<any>();
  const [cinemaList, setCinemaList] = useState([]);

  useEffect(() => {
    if (cinemas) {
      setCinemaList(formatLocation(cinemas, true));
    }
  }, [cinemas]);


  useEffect(() => {
    if (reinitializeFilter) {
      if (quickFilters.location) {
        slideSessionRef.current?.slickGoTo(
          cinemaList.findIndex(
            (v: IDropDown) => v.value === quickFilters.location || 0
          )
        );
      }
      if (quickFilters.format) {
        slideTypeRef.current?.slickGoTo(
          type.findIndex((v) => v.value === quickFilters.format) || 0
        );
      }
      if (quickFilters.date) {
        slideSessionRef.current?.slickGoTo(
          session.findIndex((v) => v.value === moment(quickFilters.date) || 0)
        );
      }
      // dispatch(setReinitializeSlider(false));
    }
  }, [quickFilters, reinitializeFilter]);

  const onChange = (value: any, type: string) => {
    switch (type) {
      case "format":
        dispatch(
          setQuickFilter({
            ...quickFilters,
            location: quickFilters.location?quickFilters.location:currentCinema.slug,
            format: value,
            movie: null,
            session: null,
            date: null,
          })
        );
        break;
      case "location":
        dispatch(
          setQuickFilter({
            ...quickFilters,
            location: value,
            movie: null,
            session: null,
            date: null,
          })
        );
        break;
      case "session":
        dispatch(
          setQuickFilter({
            ...quickFilters,
            location: quickFilters.location?quickFilters.location:currentCinema.slug,
            date: value,
            movie: null,
            session: null,
          })
        );
        break;
    }
  };

  return (
    <div className="showtime-content-table-flex">
      <div className="table-parent">
        <table>
          <tbody>
            <tr className="d-md-none">
              <td colSpan={2} className="label">
                <div>
                  <div className="filter">
                    <img src="/images/movie-img-icon.svg" />
                    <span>WHAT</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="label mobile-hide">
                <div>
                  <div className="filter">
                    <img src="/images/movie-img-icon.svg" />
                    <span>WHAT</span>
                  </div>
                </div>
              </td>
              <td className="action">
                <div className="wrapper">
                  <div
                    onClick={() => onChange(null, "format")}
                    className={
                      !quickFilters.format
                        ? `common-filter active`
                        : "common-filter"
                    }
                  >
                    EVERYTHING
                    <div
                      className={!quickFilters.format ? `active` : "non-active"}
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
              </td>
              <td className="slider">
                <div className="d-flex align-items-center">
                  <Slick {...SLICK_SETTING} ref={slideTypeRef}>
                    {type?.map((t: any, index: number) => (
                      <a
                        key={`date${index}`}
                        style={{ whiteSpace: "nowrap" }}
                        href="javascript:void(0)"
                        onClick={() => onChange(t.value, "format")}
                        className={
                          (`${quickFilters.format}`).toLowerCase() === (t.label).toLowerCase() ? "active" : ""
                        }
                      >
                        {t.label}
                        <div
                          className={
                            (`${quickFilters.format}`).toLowerCase() === (t.label).toLowerCase()
                              ? "active-slider"
                              : ""
                          }
                        >
                          &nbsp;
                        </div>
                      </a>
                    ))}
                  </Slick>
                </div>
              </td>
              <td className="sessionType mobile-hide">
                {currentCinema?.dolbyAtmosImageUrl?<img className="w-100" src={currentCinema.dolbyAtmosImageUrl} />: null}
              </td>
            </tr>
            <tr className="d-md-none">
              <td colSpan={2} className="label">
                <div>
                  <div className="filter">
                    <img src="/images/location-img-icon.svg" />
                    <span>WHERE</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="label mobile-hide">
                <div>
                  <div className="filter">
                    <img src="/images/location-img-icon.svg" />
                    <span>WHERE</span>
                  </div>
                </div>
              </td>
              <td className="action">&nbsp;</td>
              <td className="slider">
                <div className="d-flex align-items-center">
                  <Slick {...SLICK_SETTING} ref={slideLocationRef}>
                    {cinemaList?.map((cinema: any, index: number) => (
                      <a
                        key={`date${index}`}
                        style={{ whiteSpace: "nowrap" }}
                        href="javascript:void(0)"
                        onClick={() => onChange(cinema.value, "location")}
                        className={
                          currentCinema.slug === cinema.value
                            ? "active"
                            : ""
                        }
                      >
                        {cinema.alias}
                        <div
                          className={
                            currentCinema.slug === cinema.value
                              ? "active-slider"
                              : ""
                          }
                        >
                          &nbsp;
                        </div>
                      </a>
                    ))}
                  </Slick>
                </div>
              </td>
              <td className="sessionType mobile-hide"></td>
            </tr>
            <tr className="d-md-none">
              <td colSpan={2} className="label">
                <div>
                  <div className="filter">
                    <img src="/images/calendar-img-icon.svg" />
                    <span>WHEN</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="label mobile-hide">
                <div>
                  <div className="filter">
                    <img src="/images/calendar-img-icon.svg" />
                    <span>WHEN</span>
                  </div>
                </div>
              </td>
              <td className="action">
                <div className="wrapper">
                  <div
                    onClick={() => onChange(null, "session")}
                    className={
                      !quickFilters.date
                        ? `common-filter active`
                        : "common-filter"
                    }
                  >
                    ANYTIME
                    <div
                      className={!quickFilters.date ? `active` : "non-active"}
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
              </td>
              <td className="slider">
                <div className="d-flex align-items-center">
                  <Slick {...SLICK_SETTING} ref={slideSessionRef}>
                    {session?.map((s: any, index: number) => (
                      <a
                        key={`date${index}`}
                        style={{ whiteSpace: "nowrap" }}
                        href="javascript:void(0)"
                        onClick={() => onChange(s.value, "session")}
                        className={
                          quickFilters.date === s.value ? "active" : ""
                        }
                      >
                        {s.label}
                        <div
                          className={
                            quickFilters.date === s.value ? "active-slider" : ""
                          }
                        >
                          &nbsp;
                        </div>
                      </a>
                    ))}
                  </Slick>
                </div>
              </td>
              <td className="sessionType mobile-hide"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShowTimeFilter;
