import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { IPlf } from "../../../../models/cinema";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { plfApi } from "../../../../services/films";
import "./plfProgramming.scss";
import { FLIM_TYPE } from "../../../../constants/app";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/path";

interface IplfProgrammingProps {}
const PlfProgramming: FC<IplfProgrammingProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const navigate = useNavigate();
  const [plf, setPlf] = useState<IPlf[]>([]);

  const getData = async () => {
    const {
      data: { data, statusCode },
    } = await plfApi({
      cinemaId: currentCinema.slug,
      status: API_REQUEST_TYPE.GET_PLF,
      countryId: countryId,
      flag: "",
    });
    if (statusCode === 200 && Array.isArray(data) && data.length > 0) {
      setPlf(data);
    }
  };

  useEffect(() => {
    getData();
  }, [countryId, currentCinema]);

  const showSession = (data: IPlf) => {
    const flagType = FLIM_TYPE.find((v) => v.id === +data.filmType)!;
    if (flagType && flagType.alias) {
      navigate(`/${currentCinema?.alias}/${ROUTES.MOVIES}/${flagType?flagType.alias:''}`);
    }
  };

  return (
    <>
      {plf.length > 0
        ? plf.map((v) => (
            <section
              className="titan-luxe-section"
              style={{
                background: v.image
                  ? `url(${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.image}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT})`
                  : "none",
              }}
            >
              <div className="container-content">
                <div className="title">{v.Title}</div>
                <div className="titan-luxe-content">
                  <HtmlRender content={v.Description} />
                </div>
                <div className="d-flex justify-content-center my-3">
                  <button className="titan-btn" onClick={() => showSession(v)}>
                    {v.Button}
                  </button>
                </div>
              </div>
            </section>
          ))
        : null}
    </>
  );
};

export default PlfProgramming;
