import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../../../../store/ticketBooking/action";
import "./main.scss";
import { setUpModalHeaderText } from "../../../../../../store/foodAndBeverage/action";
import { TICKET_FLOW } from "../../../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../../models/tickets";
import EatAndDrinkDetails from "../../../../../../components/TicketBooking/EatAndDrink";
import { RootState } from "../../../../../../store";
import { BRANDS, COUNTRY } from "../../../../../../constants/app";
import { useSearchParams } from "react-router-dom";
import HelmetTitle from "../../../../../../components/Helmet";

interface IDirectFbProps {}

const DirectFb: FC<IDirectFbProps> = () => {
  const { countryId, currentCinema, brandId, canBookFood } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
    brandId: state.applicationReducer.brandId,
    canBookFood: state.ticketBookingReducer.canBookFood
  }));
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(setUpModalHeaderText("Food & Beverage"));
  }, []);

  const openOrderModal = (data?: any) => {
    if(currentCinema.CloseKitchenCounter || canBookFood === 0){
      dispatch(
        setModal({
          ...TICKET_FLOW[TICKET_FLOW_TYPES.KITCHEN_CLOSED][
            MODAL_TYPE.KITCHEN_CLOSED
          ],
          type: TICKET_FLOW_TYPES.KITCHEN_CLOSED,
        })
      );
    }

    dispatch(
      setUpModalHeaderText(
        brandId === BRANDS.US ? "Eat & Drink" : "Food & Drink"
      )
    );
    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE][
          MODAL_TYPE.FOOD_AND_BEVERAGE
        ],
        type: TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE,
        data: data,
      })
    );
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) {
      openOrderModal({tab});
    }
  }, [searchParams]);

  const orderNow = () => {
    openOrderModal();
  };

  const addToFuture = () => {
    dispatch(setUpModalHeaderText("Add Food & Drink"));
    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE][
          MODAL_TYPE.FOOD_AND_BEVERAGE_FUTURE
        ],
        type: TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE,
      })
    );
  };

  return (
    <div className="food_header_wrap my-5 py-5 my-lg-0 py-lg-0">
      <HelmetTitle title="Food & Beverage" description="Food & Beverage"/>
      <div className="col-12 pd-0 sub_title my-4">
        <div className="text-center d-lg-flex justify-content-center">
          <div className="my-4">
            <button
              type="button"
              className="btn black_btn mx-2 px-3"
              onClick={orderNow}
            >
              ORDER FOR NOW
            </button>
          </div>
          <div className="my-4 d-flex justify-content-center">
            <button
              type="button"
              className="btn gray_btn px-3 py-1 mx-2 d-flex align-items-center"
              onClick={addToFuture}
            >
              <span className="btn_plus_white">&nbsp;</span>{" "}
              <span className="mx-2">ADD TO EXISTING BOOKING</span>
            </button>
          </div>
        </div>
        {countryId === COUNTRY.US ? (
          <div>
            <EatAndDrinkDetails />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DirectFb;
