import React,{ FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Deal from "../../../../components/Menu/SideMenu/Deal";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { getDealsApi } from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import {
  getSlidesWatcher,
  setFooterType,
} from "../../../../store/application/action";
import "./deals.scss";
import { COUNTRY } from "../../../../constants/app";
import HelmetTitle from "../../../../components/Helmet";

interface IFaqProps {}

const Deals: FC<IFaqProps> = () => {
  const { countryId, slides } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    slides: state.applicationReducer.slides,
  }));

  const dispatch = useDispatch();
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.DEALS,
        })
      );

      const getDeals = async () => {
        const response = await getDealsApi({ countryId: countryId });
        setDeals(response.data.deals);
        dispatch(setFooterType(response.data.deals.length));
      };
      getDeals();
    }
  }, [countryId]);

  return (
    <section className="offer-events">
      <HelmetTitle title="Offers & Events" description="Offers & Events"/>
      <Slider data={slides} />
      <div className="split_section_header">
        <div className="label">{countryId === COUNTRY.ANG?'Offers':'Offers & Events'}</div>
      </div>
      {deals.length > 0?deals.map((deal) => (
        <Deal data={deal} />
      )):
       <div className="err-noMovie">No offers available</div>}
    </section>
  );
};

export default Deals;
