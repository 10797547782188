import React, { FC, useEffect, useMemo, useState, useRef } from "react";
import MovieDescription from "../../../../../components/TicketBooking/MovieDescription";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  IFormatedTicketType,
  ITicketVoucher,
  MODAL_TYPE,
  TICKET_FLOW_TYPES,
} from "../../../../../models/tickets";
import {
  generateUsersessionId,
  getRndInteger,
  ticketPriceCalculation,
} from "../../../../../utils/tickets";
import { COUNTRY } from "../../../../../constants/app";
import { API_REQUEST_TYPE, URLS } from "../../../../../constants/url";
import "./noOfSeats.scss";
import { addTicketApi, getVoucherTicketsApi } from "../../../../../services/films";
import {
  addTicketSuccess,
  setModal,
  setTicketVoucher,
} from "../../../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../../../constants/ticket";
import { formatUserDetails } from "../../../../../utils/formatter";
import { toast } from "react-toastify";
import SwapFilter from "../../../../../components/UI/Filters/SwapFilter";
import { useLocation } from "react-router";
interface INoOfSeatsProps {
  onCancel: (error?: string) => void;
}

const NoOfSeats: FC<INoOfSeatsProps> = ({ onCancel }) => {
  const {
    settings,
    films,
    ticketType,
    hasGL,
    countryId,
    ticketVoucher,
    currentCinema,
    userDetails,
    isLoggedIn,
    modalType,
    nextModal,
    inProgress,
    sharedUserSession,
    seatSwapDetails,
    ticketDetails
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
    settings: state.applicationReducer.settings,
    modalType: state.ticketBookingReducer.modal.type,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    films: state.ticketBookingReducer.films,
    ticketType: state.ticketBookingReducer.ticketType,
    hasGL: state.ticketBookingReducer.hasGL,
    ticketVoucher: state.ticketBookingReducer.ticketVoucher,
    userDetails: state.authReducer.userDetails,
    isLoggedIn: state.authReducer.isLoggedIn,
    inProgress: state.ticketBookingReducer.inProgress,
    sharedUserSession: state.ticketBookingReducer.sharedUserSession,
    seatSwapDetails: state.ticketBookingReducer.seatSwapDetails,
    ticketDetails: state.ticketBookingReducer.ticketDetails
  }));

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const voucherInputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [tickets, setTickets] = useState<IFormatedTicketType[]>([]);
  const [voucherErrors, setVoucherErrors] = useState<string>("");
  const [errors, setError] = useState<string>("");

  useEffect(() => {
    if (ticketType && ticketType.length > 0) {
      setTickets(
        ticketType.map((v: any) => {
          return {
            areaCategoryCode: v.AreaCategoryCode,
            qty: 0,
            bookingFees: 0,
            description: v.Description,
            price: +v.Price,
            ticketTypeCode: v.TicketTypeCode,
            total: 0,
            OptionalBarcode: "",
            loyaltyRecognitionId: v.LoyaltyRecognitionId?v.LoyaltyRecognitionId:undefined,
            QuantityAvailablePerOrder: (v.QuantityAvailablePerOrder && v.QuantityAvailablePerOrder<10)?(v.QuantityAvailablePerOrder):10
          };
        })
      );
    } else {
      if (
        (!Array.isArray(ticketType) &&
          ticketType.hasOwnProperty("ResponseCode") &&
          ticketType.ResponseCode != 0) ||
        (Array.isArray(ticketType) && ticketType.length == 0)
      ) {
        onCancel("Tickets not available for this session");
      }
    }
  }, [ticketType]);

  const { price, bookingFees, total } = useMemo(() => {
    setError("");
    return ticketPriceCalculation(tickets);
  }, [tickets]);

  const onClickMinus = (index: number) => {
    const ticketArray = [...tickets];
    const totalTicketCount = tickets.reduce(
      (acc: number, v: any) => (acc = acc + v.qty),
      0
    );
    const currentTicket: any = ticketArray[index];

    if (totalTicketCount <= 0 || currentTicket.qty <= 0) {
      return;
    }

    currentTicket.qty = currentTicket.qty - 1;
    currentTicket.bookingFees =
      currentTicket.bookingFees -
      Number(
        hasGL === 1 ? settings.glServiceChargeFee : (films.sessionAttributes === "Soho"? settings.sohoServiceChargeFee : settings.serviceChargeFee)
      );
    currentTicket.total = currentTicket.qty * currentTicket.price;
    ticketArray[index] = currentTicket;
    setTickets(ticketArray);
  };

  const onClickPlus = (index: number, barcode?: string) => {
    const ticketArray = [...tickets];
    const totalTicketCount = tickets.reduce(
      (acc: number, v) => (acc = acc + v.qty),
      0
    );
    const currentTicket = ticketArray[index];
    
    if (totalTicketCount >= 10 || currentTicket.qty >= currentTicket.QuantityAvailablePerOrder) {
      if (currentTicket.qty >= currentTicket.QuantityAvailablePerOrder) {
        setError(`A maximum of 10 tickets can be purchased per transaction and ${currentTicket.QuantityAvailablePerOrder} for ${currentTicket.description}.`);  
      }
      else {
        setError(`A maximum of 10 tickets can be purchased per transaction.`);
      }
      
      return;
    }

    currentTicket.qty = currentTicket.qty + 1;
    currentTicket.bookingFees =
      currentTicket.bookingFees +
      Number(
        hasGL === 1 ? settings.glServiceChargeFee :  (films.sessionAttributes === "Soho"? settings.sohoServiceChargeFee: settings.serviceChargeFee)
      );
    currentTicket.total = currentTicket.qty * currentTicket.price;
    if (barcode) {
      currentTicket.OptionalBarcode = barcode;
    }
    ticketArray[index] = currentTicket;
    setTickets(ticketArray);
  };

  const clearVoucherDetails = (type: string, index: number) => {
    const tempVouchers = ticketVoucher;
    dispatch(setTicketVoucher(tempVouchers));
    tempVouchers.splice(index, 1);
    const typeIndex = tickets.findIndex((object) => {
      return object.ticketTypeCode == type;
    });
    onClickMinus(typeIndex);
  };

  const getVoucherDetails = async () => {
    const barcode = voucherInputRef?.current?.value;
    setVoucherErrors("");
    if (!barcode) {
      setVoucherErrors("Voucher code is required");
      return;
    }
    const d = new Date(); // for now
    const currentTime =
      d.getDate() +
      "" +
      d.getHours() +
      "" +
      d.getMinutes() +
      "" +
      d.getMilliseconds();
    let theatreId = currentCinema.cinemaId;
    theatreId = theatreId.substr(0, 3);
    let rand = getRndInteger(1000000000, 9999999999);
    const userSessionID = `${films.sessionId}${theatreId}${currentTime}${rand}`;
    let checkCode = ticketVoucher.some(
      (el: ITicketVoucher) => el.cardNumber === barcode
    );
    if (checkCode) {
      setVoucherErrors("Voucher already added");
      return false;
    }
    const params = {
      cardNumber: barcode,
      cinemaId: currentCinema.cinemaId,
      sessionId: films.sessionId,
      requestType: "getVoucherTickets",
      countryId: countryId,
      userSessionId: userSessionID,
    };
    const {
      data: { data },
    } = await getVoucherTicketsApi(params);

    let res = JSON.parse(data);

    if (res.ErrorDescription != null) {
      setVoucherErrors(res.ErrorDescription);
      return;
    }
    const index = tickets.findIndex((object) => {
      return object.ticketTypeCode == res.Tickets[0].TicketTypeCode;
    });
    const tempVouchers = ticketVoucher;
    tempVouchers.push({
      description: res.Tickets[0].Description,
      cardNumber: barcode,
      type: res.Tickets[0].TicketTypeCode,
    });
    dispatch(setTicketVoucher(tempVouchers));
    onClickPlus(index, barcode);
  };

  const Voucher = () => {
    return countryId === COUNTRY.STA ? (
      <div className="voucher_box">
        <label className="seat-label">Have a voucher?</label>
        {ticketVoucher.map((voucher: ITicketVoucher, index: number) => (
          <div className="search_common_disabled">
            <label>{voucher.description}</label>
            <div>
              <input
                type="text"
                className="form-control"
                value={voucher.cardNumber}
                disabled
              />
              <button
                type="button"
                onClick={() => clearVoucherDetails(voucher.type, index)}
              >
                <img src={URLS.CLOSE_ICON} alt="" />
              </button>
            </div>
          </div>
        ))}
        <div className="search_common">
          {ticketVoucher.length > 0 ? <label>Add Another</label> : null}
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Enter card number"
              ref={voucherInputRef}
            />
            <button type="button" onClick={() => getVoucherDetails()}>
              <img src={URLS.RIGHT_ARROW} alt="" />
            </button>
          </div>
        </div>
        {voucherErrors ? (
          <div className="error_message">{voucherErrors}</div>
        ) : null}
      </div>
    ) : null;
  };

  const onCancelTicket = () => {
    onCancel();
  };

  const onNext = async () => {
    const qty = tickets.reduce((qty: number, num: IFormatedTicketType) => {
      return qty + num.qty;
    }, 0);
    if (qty === 0) {
      setError("Select atleast one ticket type");
      return;
    }

    const bookedTickets = tickets.filter((v) => v.qty > 0);
    const payload = {
      cinemaId: films?.cinemaId,
      countryId: films?.countryId,
      hasGL: hasGL,
      hasSoho: films.sessionAttributes === "Soho"? 1:0,
      movieId: films?.movieId,
      requestType: API_REQUEST_TYPE.ADD_TICKET,
      reservedSeating: 0,
      sessionId: films?.sessionId,
      ticketTypes: bookedTickets,
      userDetials:
        userDetails && userDetails?.result?.memberRefId!
          ? formatUserDetails(userDetails, countryId, films?.cinemaId!)
          : {},
      userSessionId: ticketDetails?.userSessionId,
      isMobile: 0,
      userAgent: navigator.userAgent,
    };
    const ticketResponse = await addTicketApi(payload);
    const {
      data: {
        data: { error, saleId },
      },
    } = ticketResponse;
    if (error) {
      // alert("error in booking ticket");
      return;
    }

    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][next];

    dispatch(
      addTicketSuccess({
        total,
        bookingFees,
        price,
        reservedSeating: payload.reservedSeating,
        selectedSeats: [],
        ticketTypes: payload.ticketTypes ? payload.ticketTypes : [],
        userSessionId: saleId,
      })
    );
    dispatch(setModal({ ...modal, type: modalType }));
  };

  const isSeatSwap = useMemo(() => {
    return pathname.includes("/seat-swap/") && seatSwapDetails ? true : false;
  }, [pathname, seatSwapDetails]);

  return (
    <div className="app-no-of-seats row">
      <MovieDescription />
      {settings.enableTicketBooking == 0 ? (
        <div className="col-md-8 bookingnotallowed">
          Online ticket booking not available
        </div>
      ) : (
        <div className="col-md-8 p-l-10">
          {modalType !== TICKET_FLOW_TYPES.MULTI_TICKETING &&
          modalType !== TICKET_FLOW_TYPES.MULTI_TICKETING_FB &&
          sharedUserSession &&
          sharedUserSession.length > 0 ? (
            <div className="col-12 socialSharing">
              <span>{sharedUserSession[0].userName}</span> is sharing their
              seats with you!
            </div>
          ) : null}
          {isSeatSwap ? <SwapFilter /> : null}
          <div className="no-seats form_page">
            <div className="seat_type">
              {tickets.map((ticketType: IFormatedTicketType, index: number) => (
                <div className="single_row">
                  {ticketType.QuantityAvailablePerOrder > 0 && (ticketType.price !== 0 || (isLoggedIn && ticketType.price === 0)) ? (
                    <>
                      <div className="person_type">
                        <label className="seat-label">
                          {ticketType.description}
                        </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="btn_minus"
                              onClick={() => onClickMinus(index)}
                            >
                              &nbsp;
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            aria-label="Seat"
                            value={ticketType.qty}
                            disabled
                          />
                          <div className="input-group-append">
                            <span
                              className="btn_plus"
                              onClick={() => onClickPlus(index)}
                            >
                              &nbsp;
                            </span>
                          </div>
                        </div>
                      </div>
                      {tickets.length > 0 ? (
                        <div className="price_wrap">
                          <div className="price">
                            <label className="seat-label">Price</label>
                            <p className="seat-label">
                              ${ticketType.price.toFixed(2)}
                            </p>
                          </div>
                          <div className="total">
                            <label className="seat-label">Total</label>
                            <p className="seat-label">
                              ${ticketType.total.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </>
                   ) : null} 
                </div>
              ))}
              <Voucher />
            </div>
            {errors ? (
              <div className="error_message mx-0 my-3">
                <span>{errors}</span>
              </div>
            ) : null}
            {/* <div *ngIf="errorEmpty" className="error_message">
        <span> No of Seat is required</span>
      </div>
      <div *ngIf="errorMessage != '' " className="error_message">
        <span>{{errorMessage}}</span>
      </div>
      <div *ngIf="seatError">
        <h2 className="text-left seats_not_available">
          Seats are currently not available
        </h2>
      </div> */}
          </div>

          <div className="user-pay-details">
            {/* <div class="row" style="margin-top: 30px;" *ngIf="showVoucherInfo">
    <div class="col-12"><p><span style="color: red">Please note - </span> NSW Parents vouchers may only redeemed for ticket purchases. If you’d like to add any Food and Beverage items to your transaction, you will be able to pay the difference during check out.</p></div>
  </div> */}
            <div className="row form_page">
              <div className="col-12 col-md-12 user_coloum">
                <div className="">
                  {isLoggedIn ? (
                    <>
                      <div className="welcome">Welcome back</div>
                      <div className="my-account text-capti font-size-16">
                        {userDetails?.firstName}
                      </div>
                    </>
                  ) : null}
                  {isSeatSwap ? (
                    <div className="user-details">
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <div className="total">REFUND TOTAL</div>
                          <div className="total-amount">
                            ${(seatSwapDetails.totalAmount / 100).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="user-details">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="total">
                          {isSeatSwap ? "NEW TOTAL" : "TOTAL ORDER"}
                        </div>
                        <div className="total-amount">${total.toFixed(2)}</div>
                        {tickets.map((v: IFormatedTicketType) =>
                          v.qty > 0 ? (
                            <div>
                              <div className="total-det">
                                {v.qty} x {v.description} SEATS: $
                                {(v.qty * v.price).toFixed(2)}
                              </div>
                            </div>
                          ) : null
                        )}
                        <div className="total-det">
                          <span>BOOKING FEE : ${bookingFees.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 movie_footer_btn_el">
                <div className="movie_button_wrap">
                  <button
                    type="button"
                    className="btn gray_btn"
                    onClick={onCancelTicket}
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="btn black_btn"
                    onClick={onNext}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoOfSeats;
