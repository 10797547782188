import { FC, useEffect, useState } from "react";
import Slider from "../../../../components/UI/Slider";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { getSlidesWatcher } from "../../../../store/application/action";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import "./corporatecinemony.scss";
import { getSlidesApi } from "../../../../services/application";

interface ICorporateCinemonyProps {}

const CorporateCinemony: FC<ICorporateCinemonyProps> = ({}) => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));

  const dispatch = useDispatch();
  const [ image, setImage] = useState();

  const getSlides = async () => {
    const {
      status,
      response: { data },
    } = (await getSlidesApi({
      countryId,
      pageName: API_REQUEST_TYPE.CINEMONY,
      location: "",
    })) as any;
    if (status && data && data.length > 0) {
      setImage(data[0]["mobileImageUrl"]);
    }
  };

  useEffect(() => {
    if (countryId) {
      getSlides();
    }
  }, [countryId]);

  return (
    <div className="corporate-cinemony-wrapper">
      <div className="modal-header p-0">
        <img
          src={image}
          alt="Corporate Cinemoney"
          title="Corporate Cinemoney"
        />
      </div>
      <div className="cinemoney_content_wrap two_section_content modal-body">
        <div className="row p-3">
          <div className="col-md-12">
            <h2 className="title">Corporate Cinemoney</h2>
          </div>
          <div className="col-md-6">
            <p>
              Corporate Cinemoney* represents great value for money and is an
              ideal gift or reward option for businesses, clubs and charities.
            </p>
            <p>
              For all Corporate Cinemoney sales or enquiries, please contact our
              National Corporate Sales department at
              groupsales@readingcinemas.com.au
            </p>
          </div>
          <div className="col-md-6">
            <p className="font_italic">
              * Note: Corporate Cinemoney is valid for redemption of standard
              session tickets at the counter. Corporate Cinemoney can also be
              upgraded to redeem Titan XC, Titan Luxe, Premium and Gold Lounge
              tickets. The difference is payable by the voucher holder at the
              time of redemption. Reel Club points are not awarded for Corporate
              Cinemoney purchases. See back of voucher for full terms and
              conditions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateCinemony;
