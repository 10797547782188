export const URLS = {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    CLOUDFRONT_URL: process.env.REACT_APP_CLOUDFRONT_URL,
    READING_LOGO: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/reading-cinemas-logo-white.svg',
    READING_LOGO_BLACK: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/reading-cinemas-logo-black.svg',
    STATE_LOGO: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/StateLogo.png',
    STATE_NAV_LOGO_DESKTOP: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/LogoStateCinema.png',
    STATE_NAV_LOGO_MOBILE: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/HeaderMobileLogoStateCinema.png',
    ANGELIKA_LOGO: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/angelika-logo.svg',
    ANGELIKA_NAV_LOGO_DESKTOP: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/angelika-logo-lg.svg',
    ANGELIKA_NAV_LOGO_MOBILE: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/angelika-logo-md.svg',
    STATE_LINK_LOGO: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/state-cinema.svg',
    ANGELIKA_LINK_LOGO: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/State-Angelika.png',
    FACEBOOK_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/facebook_icon.svg',
    FACEBOOK_ICON_WHITE: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/fb-white.png',
    INSTAGRAM_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/instagram_icon.svg',
    INSTAGRAM_ICON_WHITE: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/insta-white.png',
    CLOSE_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/close-icon.svg',
    CLOSE_WHITE_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/close-white.svg',
    CLOSE_ICON_BLACK: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/close-black-icon.svg',
    RIGHT_ARROW: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/arrow_right.svg',
    ARROW_BOTTOM_WHITE: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/arrow-bottom-white.svg',
    ICON_GO: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/search_icon_white.svg',
    DEALS_DESKTOP: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/LogoStateCinema.png',
    DEALS_MOBILE: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/HeaderMobileLogoStateCinema.png',
    SCREEN_VIEW: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/screen.png',
    GIFT_CARD_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + "/assets/images/card-image.jpg",
    APPLE_WALLET_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + "/assets/images/Add_to_Apple_Wallet_rgb_US-UK.png",
    DEFAULT_FOOD_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + "/assets/images/default_food_image.png",
    GOOGLE_WALLET_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + "/assets/images/google-pay-white.png",
    ERROR_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + "/wpdata/images/no-image.png",
    APPLE_WALLET_SIDEBAR_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + "/assets/images/Add_to_Apple_Wallet_rgb_US-UK.png",
    GOOGLE_WALLET_SIDEBAR_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + "/assets/images/google-pay-white.svg",
    GOOGLE_PAY_MARK: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/GPay_Acceptance_Mark.png',
    GPAY_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/gpay-icon.svg',
    APPLE_PAY_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/apple_icon_white.svg',
    CLOCK_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + "/assets/images/Clock.png",
    READING_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/reading-icon.png',
    CITY_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/city-icon.png',
    CONSOLIDATE_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/consolidated-icon.png',
    ANGELIKA_ICON: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/angelika-icon.png',
    CART_IMAGE: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/trolly-black.png',
    GIFT_CARD_EDIT: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/edit-icon.svg',
    GIFT_CARD_DEL: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/delete-icon.svg',
    CREDIT_CARD: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/credit_card_icon.svg',
    GIFT_CART: process.env.REACT_APP_CLOUDFRONT_URL + '/assets/images/trolly.svg',
    GIFT_CARD_AU: 'https://wwws-au1.givex.com/cws4.0/readingau/',
    GIFT_CARD_NZ: 'https://wwws-au1.givex.com/cws4.0/readingnz/',
    GIFT_CARD_ANG: 'https://wwws-au1.givex.com/cws4.0/readingang/',
    GIFT_CARD_STA: 'https://wwws-au1.givex.com/cws4.0/readingang/',
    FACEBOOK_AU: 'https://www.facebook.com/readingcinemasaustralia/',
    FACEBOOK_NZ: 'https://www.facebook.com/ReadingCinemasNewZealand/',
    FACEBOOK_ANG: 'https://www.facebook.com/angelikafilmcentre/',
    FACEBOOK_STA: 'https://www.facebook.com/StateCinema/',
    INSTAGRAM_AU: 'https://www.instagram.com/readingcinemasau/',
    INSTAGRAM_NZ: 'https://www.instagram.com/readingcinemasnz/',
    INSTAGRAM_ANG: 'https://www.instagram.com/angelikaaustralia/',
    INSTAGRAM_STA: 'https://www.instagram.com/statecinemahobart/',
    ANGELIKA_URL: 'https://www.angelikafilmcenter.com.au/',
    STATE_CINEMA_URL: 'https://statecinema.com.au/',
    ANGELIKA_ANYWHERE_URL: 'https://www.angelikaanywhere.com.au',
    CLUB_STA: 'https://ticketing.statecinema.com.au/Browsing/Loyalty/MembershipPrograms/4',
    CLUB_ANGELIKA: 'https://ticketing.statecinema.com.au/Browsing/Loyalty/MembershipPrograms/4',
    BOOK_STORE_STATE: 'https://www.statecinemabookstore.com.au/',
    GOOGLE_WALLET: 'https://pay.google.com/gp/v/save/',
    APPLE_WALLET: process.env.REACT_APP_APPLE_WALLET,
    ANGELIKA_FLIM_CENTER_US: 'https://www.angelikafilmcenter.com',
    ANGELIKA_ANY_WHERE_US: 'https://www.angelikaanywhere.com',
    CONSOLIDATED_THEATERS_US: 'https://www.consolidatedtheatres.com',
    INVESTOR: 'https://investor.readingrdi.com/'
}

export const IMAGE_URL_CONFIG = {
    IMAGE_PATH: "wpdata/images",
    FILE_PATH: "wpdata/files",
    SMALL_IMAGE_EXT: "-s.jpg",
    XSMALL_IMAGE_EXT: "-o.png",
    MEDIUM_IMAGE_EXT: "-m.jpg",
    LARGE_IMAGE_EXT: "-l.jpg",
    ORIGINAL_IMAGE_EXT: "-oj.jpg",
    PLACEHOLDER: "placeholder.svg",
    DEFAULT_IMAGE_PATH: URLS.CLOUDFRONT_URL + "/assets/images/",
};

export const API_REQUEST_TYPE = {
    MOVIES: 'movies',
    HOME: 'home',
    DEALS: 'deals',
    GET_SESSION_DATA: 'getSessionData',
    TICKET_TYPES_LENGTH: 'ticketTypesLength',
    SEAT_PLAN: 'seatPlan',
    ADD_TICKET: 'addTicket',
    UPCOMING_TRANSACTION: 'upcomingtransaction',
    LOYALTY: 'loyalty',
    REPLACE_CARD: "replaceCard",
    GIFT_SHOP: "giftcard-shop",
    ORDER_CONTINUE: "orderContinue",
    GIFTCARD_BALANCE_CHECK: "giftcardBalanceCheck",
    STRIPE_MAKE_PAYMENT: "stripeMakePayment",
    STRIPE_TOKEN_PAYMENT: "stripeTokenPayment",
    STRIPE_GIFTCARD_MAKE_PAYMENT: "stripeGiftcardMakePayment",
    STRIPE_GIFTCARD_MAKE_REFUND: 'stripeGiftcardMakeRefund',
    STRIPE_GIFTCARD_TOKEN_PAYMENT: "stripeGiftcardTokenPayment",
    FATZEBRA_MAKE_PAYMENT: "fatzebraMakePayment",
    FATZEBRA_TOKEN_PAYMENT: "fatzebraTokenPayment",
    FATZEBRA_GIFTCARD_MAKE_PAYMENT: "fatzebraGiftcardMakePayment",
    FATZEBRA_GIFTCARD_MAKE_REFUND: 'fatzebraGiftcardMakeRefund',
    FATZEBRA_GIFTCARD_TOKEN_PAYMENT: 'fatzebraGiftcardTokenPayment',
    PAYEEZY_MAKE_PAYMENT: "payeezyMakePayment",
    PAYEEZY_TOKEN_PAYMENT: "payeezyTokenPayment",
    PAYEEZY_GIFTCARD_MAKE_PAYMENT: "payeezyGiftcardMakePayment",
    PAYEEZY_GIFTCARD_MAKE_REFUND: 'payeezyGiftcardMakeRefund',
    PAYEEZY_GIFTCARD_TOKEN_PAYMENT: "payeezyGiftcardTokenPayment",
    GET_FILM_DETAILS: "getFilmDetails",
    GET_SIGNATURE_PROGRAMMING: "getSignatureProgramming",
    GET_SPECIAL_VALUE: "getSpecialValues",
    NOW_SHOWING: "nowShowing",
    GET_PLF: 'getPLF',
    GET_ORDER_HISTORY: "getOrderHistory",
    CONTACT_US: "contact-us",
    EMPLOYMENT: "employment",
    VIPEXPERIENCE: "vip-cinemas",
    GAMINGHIRE: "gaming-hire",
    GIFT_CARDS: "giftcards",
    GET_SALES_DATA: "getSaleData",
    HISTORY: "history",
    ADVERTISING:'advertising',
    REELCLUB:'member_register',
    CINEMONY: 'cinemoney',
    COMPETITIONS:'competitions',
    MAILINGLIST:'mailinglist',
    CAFEBAR: 'cafe-bar',
    SOHO_LOUNGE: 'soho-lounge',
    MEMBERSHIP: 'member_register',
    ACCOUNT: 'account',
    CINEMA_HIRE:'cinema-hire',
    ABOUT:'about',
    HIGHLINE_TERRACE:'highline-terrace',
};