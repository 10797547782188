import React, { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import "./footer.scss";
import { URLS } from "../../../constants/url";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/path";
import {
  setContentModal,
  toggleContentModal,
} from "../../../store/application/action";
import { shuffleArray } from "../../../utils/helper";
import { IMovieData } from "../../../models/cinema";
import { IFooterMenu } from "../../../models/sidemenu";
import { decode } from "html-entities";
import { LOCATION_MAPPER } from "../../../constants/app";

interface IFooterProps {}

const Footer: FC<IFooterProps> = () => {
  const { countryId, footerType, menu, currentCinema, comingSoon } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      menu: state.applicationReducer.menu,
      footerType: state.applicationReducer.footerType,
      currentCinema: state.applicationReducer.currentCinema,
      comingSoon: state.filmsReducer.comingSoon,
    }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const signupNewletter = () => {
    dispatch(setContentModal({ type: ROUTES.NEWS_LETTER }));
    dispatch(toggleContentModal(true));
  };

  const quickLinks = useMemo(() => {
    return menu.footer.filter(
      (v: IFooterMenu) => v.FooterTitle === "Quick Links"
    );
  }, [menu]);

  const joinUs = useMemo(() => {
    return menu.footer.filter((v: IFooterMenu) => v.FooterTitle === "Join Us");
  }, [menu]);

  const getInTouch = useMemo(() => {
    return menu.footer.filter(
      (v: IFooterMenu) => v.FooterTitle === "Get In Touch"
    );
  }, [menu]);

  const SocialMedia = () => {
    return (
      <ul className="footer-social-buttons footer-columns">
        <a href={currentCinema?.facebook_url} target="_blank">
          <img
            onMouseOver={(e) =>
              (e.currentTarget.src = "/images/Facebook_white.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = "/images/Facebook_red.svg")
            }
            src="/images/Facebook_red.svg"
          />
        </a>
        <a href={currentCinema?.instagramUrl} target="_blank">
          <img
            onMouseOver={(e) =>
              (e.currentTarget.src = "/images/Instagram_white.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = "/images/Instagram_red.svg")
            }
            src="/images/Instagram_red.svg"
          />
        </a>
        <a href={currentCinema?.twitter_url} target="_blank">
          <img
            onMouseOver={(e) =>
              (e.currentTarget.src = "/images/Twitter_white.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = "/images/Twitter_red.svg")
            }
            src="/images/Twitter_red.svg"
          />
        </a>
        <a href={currentCinema?.tiktok} target="_blank">
          <img
            onMouseOver={(e) =>
              (e.currentTarget.src = "/images/TikTok_white.svg")
            }
            onMouseOut={(e) => (e.currentTarget.src = "/images/TikTok_red.svg")}
            src="/images/TikTok_red.svg"
          />
        </a>
        <a className="youtube" href={currentCinema?.youtubeUrl} target="_blank">
          <img
            onMouseOver={(e) =>
              (e.currentTarget.src = "/images/youtube_white.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = "/images/Youtube_red.svg")
            }
            src="/images/Youtube_red.svg"
          />
        </a>
        <a
          className="email"
          href={`mailto:${currentCinema?.email}`}
          target="_blank"
        >
          <img
            onMouseOver={(e) =>
              (e.currentTarget.src = "/images/Mail_white.svg")
            }
            onMouseOut={(e) => (e.currentTarget.src = "/images/Mail_red.svg")}
            src="/images/Mail_red.svg"
          />
        </a>
      </ul>
    );
  };

  const Address = () => {
    return (
      <address className="d-flex d-lg-block">
        {/* <p className="cityOrZipCode d-none d-lg-block">
          <img src="/images/location.svg" />
          <input placeholder="City or Zip code" />
        </p> */}
        <h3 className="heading">THEATER INFO</h3>
        <a
          className="text-uppercase pointer-default"
          href="javascript::void(0)"
        >
          {currentCinema?.cinemaName}
        </a>
        <p className="d-flex">
          <img src="/images/location.svg" />
          <div>
            {currentCinema?.address}
            <br />
            {currentCinema?.address2 ? currentCinema.address2 : null}
          </div>
        </p>
        <p>
          <img src="/images/telephone_icon_red.svg" />
          {currentCinema?.phone}
        </p>
      </address>
    );
  };

  const groupSale = () => {
    navigate(`/${currentCinema?.alias}/group-sale`);
  };

  const contactUs = (type: string) => {
    navigate(`/${currentCinema?.alias}/contact-us${type}`);
  };

  const onClickComingSoon = (data: IMovieData) => {
    if (data && data.movieData) {
      let movieGroupId = "";
      if (
        data.movieData.showdates &&
        data.movieData.showdates[0] &&
        data.movieData.showdates[0].showtypes &&
        data.movieData.showdates[0].showtypes[0] &&
        data.movieData.showdates[0].showtypes[0].showtimes
      ) {
        movieGroupId =
          data.movieData.showdates[0].showtypes[0].showtimes[0].ScheduledFilmId;
      }
      navigate(`/${LOCATION_MAPPER[countryId][data.movieData.theater]?LOCATION_MAPPER[countryId][data.movieData.theater]:data.movieData.theater}/movies/details/${movieGroupId}`);
    } else {
      navigate(`/${currentCinema?.alias}/movies/details?id=${data.slug}`);
    }
  };

  const MobileView = () => {
    return (
      <div className="horizontal-footer-section">
        <div
          className="footer-columns footer-columns-large w-50"
          id="footer-middle-section"
        >
          <div>
            <Address />
          </div>
          {/* <div className="map">VIEW GOOGLE MAP</div> */}
        </div>
        <div
          className="footer-columns footer-columns-large"
          id="footer-middle-section"
        >
          <h3 className="heading d-flex justify-content-start">FOLLOW US</h3>
          <SocialMedia />
          <h3 className="heading d-flex justify-content-start">GET IN TOUCH</h3>
          <ul
            className="footer-column-menu d-flex align-items-start flex-column"
            role="menu"
          >
            <li className="footer-column-menu-item" role="menuitem">
              <a href="#">Lost & Found</a>
            </li>
            <li className="footer-column-menu-item" role="menuitem">
              <a href="#">Refunds</a>
            </li>
            <li className="footer-column-menu-item" role="menuitem">
              <a
                href="javascript:void(0)"
                onClick={() =>
                  navigate(
                    `/${ROUTES.FAQ_US.replace(
                      ":cinemaId",
                      currentCinema?.alias
                    )}`
                  )
                }
              >
                FAQ
              </a>
            </li>
            <li className="footer-column-menu-item" role="menuitem">
              <a href="#">Customer Service</a>
            </li>
          </ul>
          <div className="readings-icon">
            <a href="#">
              <img src="/images/readingCinemas_us_logo_white.svg" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="horizontal-footer-section">
        <div
          className="footer-columns footer-a footer-columns-large d-none d-lg-block"
          id="footer-middle-section"
        >
          <h3 className="heading">Quick Links</h3>
          <ul className="footer-column-menu" role="menu">
            {quickLinks.map((v: IFooterMenu) => (
              <li className="footer-column-menu-item" role="menuitem">
                {v.Url.includes("http") ? (
                  <a href={v.Url} target="_blank">
                    {decode(v.LinkTitle)}
                  </a>
                ) : (
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(`/${currentCinema?.alias}${v.Url}`)}
                  >
                    {decode(v.LinkTitle)}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="footer-columns footer-b footer-columns-large d-none d-lg-block"
          id="footer-middle-section"
        >
          <h3 className="heading">JOIN US</h3>
          <ul className="footer-column-menu" role="menu">
            {joinUs.map((v: IFooterMenu) => (
              <li className="footer-column-menu-item" role="menuitem">
                {v.Url.includes("http") ? (
                  <a href={v.Url} target="_blank">
                    {decode(v.LinkTitle)}
                  </a>
                ) : (
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(`/${currentCinema?.alias}${v.Url}`)}
                  >
                    {decode(v.LinkTitle)}
                  </a>
                )}
              </li>
            ))}
          </ul>
          <h3 className="heading">GET IN TOUCH</h3>
          <ul className="footer-column-menu">
            {getInTouch.map((v: IFooterMenu) => (
              <li className="footer-column-menu-item" role="menuitem">
                {v.Url.includes("http") ? (
                  <a href={v.Url} target="_blank">
                    {decode(v.LinkTitle)}
                  </a>
                ) : (
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(`/${currentCinema?.alias}${v.Url}`)}
                  >
                    {decode(v.LinkTitle)}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="footer-columns footer-c footer-columns-large"
          id="footer-middle-section"
        >
          {comingSoon && comingSoon.length > 0 ? (
            <>
              <h3 className="heading d-none d-lg-block">COMING SOON</h3>
              <ul className="footer-column-menu d-none d-lg-block" role="menu">
                {shuffleArray(comingSoon).map((v: any) => {
                  return (
                    <li className="footer-column-menu-item" role="menuitem">
                      <a onClick={() => onClickComingSoon(v)}>
                        {v.movieData ? v.movieData.movie : v.movieName}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null}
          <h3 className="heading">FOLLOW US</h3>
          <SocialMedia />

          <div className="readings-icon">
            <a href="#">
              <img src="/images/readingCinemas_us_logo_white.svg" />
            </a>
          </div>
        </div>
        <div
          className="footer-columns footer-d footer-columns-large"
          id="footer-middle-section"
        >
          {/* <h3 className="heading d-none d-lg-block">FIND US NEAR YOU</h3> */}
          <Address />

          {/* <div>
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
        >
          <Marker position={{ lat: 32.778080, lng: -117.011290 }} />
        </GoogleMap>
        </div> */}
        </div>
      </div>
    );
  };

  return (
    <footer className="footer-us">
      <div className="d-block d-lg-none">
        <MobileView />
      </div>
      <div className="d-none d-lg-block">
        <DesktopView />
      </div>
      <div className="divider">
        <hr />
      </div>
      <section className="entertainment-brands">
        <h3>OUR OTHER ENTERTAINMENT BRANDS</h3>
        <div className="entertainment-icons">
          <a href={URLS.CONSOLIDATED_THEATERS_US} target="_blank">
            <img className="consolidated" src="/images/Logo_Ctr_white.svg" />
          </a>
          <a href={URLS.ANGELIKA_FLIM_CENTER_US} target="_blank">
            <img
              className="angelikaFlimCenter"
              src="/images/Angelika_stacked_white.svg"
            />
          </a>
          <a href={URLS.ANGELIKA_ANY_WHERE_US} target="_blank">
            <img
              className="angelikaAnywhere"
              src="/images/Angelika_Anywhere_Stacked_White.svg"
            />
          </a>
        </div>
      </section>
      <div className="divider">
        <hr />
      </div>
      <section className="footer-bottom">
        <div className="terms">
          <p className="d-none d-lg-block">
            <a
              href="javascript:void(0)"
              onClick={() =>
                navigate(`/${currentCinema?.alias}/${ROUTES.RATING}`)
              }
            >
              MPAA Ratings
            </a>
          </p>
          <p>
            <a
              href="javascript:void(0)"
              onClick={() =>
                navigate(`/${currentCinema?.alias}/${ROUTES.TERMS}`)
              }
            >
              Terms of Use
            </a>
          </p>
          <p>
            <a
              href="javascript:void(0)"
              onClick={() =>
                navigate(`/${currentCinema?.alias}/${ROUTES.PRIVACY}`)
              }
            >
              Privacy Policy
            </a>
          </p>
          <p>
            <a
              href="javascript:void(0)"
              onClick={() =>
                navigate(
                  `/${currentCinema?.alias}/${ROUTES.PRIVACY}/${ROUTES.PRIVACY_POLICY_US_STATE}`
                )
              }
            >
              Your US State Privacy Rights
            </a>
          </p>
          <p className="d-none d-lg-block">
            <a
              href="javascript:void(0)"
              onClick={() =>
                navigate(
                  `/${currentCinema?.alias}/${ROUTES.PRIVACY}/${ROUTES.PRIVACY_POLICY_CHILDREN}`
                )
              }
            >
              Children's Online Privacy Policy
            </a>
          </p>
          <p>
            <a
              href="javascript:void(0)"
              onClick={() =>
                navigate(
                  `/${currentCinema?.alias}/${ROUTES.PRIVACY}/${ROUTES.INVESTORS_BASED_ADS}`
                )
              }
            >
              Interest-Based Ads
            </a>
          </p>
          <p className="d-block d-lg-none">
            <a href={URLS.INVESTOR} target="_blank">
              Investors
            </a>
          </p>
          <p>
            <a
              href="javascript:void(0)"
              onClick={() =>
                navigate(
                  `/${currentCinema?.alias}/${ROUTES.PRIVACY}/${ROUTES.PERSONAL_INFORMATION}`
                )
              }
            >
              Do Not Sell or Share My Personal Information
            </a>
          </p>
        </div>
        <div className="copyright">
          <p>Reading International, Inc.</p>
          <p className="d-none d-lg-block">
            <a href={URLS.INVESTOR} target="_blank">
              Investors
            </a>
          </p>
          <p>Copyright ©2023 Reading IP, LLC All rights reserved</p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
