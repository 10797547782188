import React, { FC, useEffect, useState } from "react";
import Slider from "../../../components/UI/Slider";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { searchApi } from "../../../services/films";
import { ISearch } from "../../../models/cinema";
import moment from "moment";
import { useNavigate } from "react-router";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import { getSlidesWatcher } from "../../../store/application/action";
import "./search.scss";
import { ROUTES } from "../../../constants/path";

interface ISearchProps {}

const Search: FC<ISearchProps> = () => {
  const { countryId, slides, currentCinema, searchFilter } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      slides: state.applicationReducer.slides,
      currentCinema: state.applicationReducer.currentCinema,
      searchFilter: state.filmsReducer.searchFilter,
    }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sessions, setSessions] = useState<ISearch[]>([]);

  const getSearch = async () => {
    const {
      data: { data },
    } = await searchApi({
      countryId,
      searchkey: searchFilter,
    });
    if (data) {
      setSessions(data);
    }
  };

  useEffect(() => {
    if (countryId && currentCinema && slides.length === 0) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.MOVIES,
          location: currentCinema.slug,
        })
      );
    }
  }, [countryId, currentCinema, slides]);

  useEffect(() => {
    if (searchFilter) {
      getSearch();
    } else {
      setSessions([]);
      navigate(`/${currentCinema?.alias}/${ROUTES.MOVIES}`);
    }
  }, [searchFilter]);


  const learnMore = (data: ISearch) => {
    navigate(`/${currentCinema?.alias}/movies/details?id=${data.id}`);
  };

  return (
    <div className="search-page">
      <div className="slider-filter">
        <Slider
          className="banner-slider-us"
          data={slides}
          isDotsNeeded={true}
        />
      </div>

      <section className="search-section">
        {sessions.length > 0 ? (
          <div className="container-content">
            <div className="img-section-parent-cont-white">
              {/* <h2 className="heading"></h2> */}
              <div className="img-section-parent img-section-parent-four-list">
                {sessions.map((data: any) => (
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="img-content-parent">
                      <div
                        className="img-container"
                        onClick={() => learnMore(data)}
                      >
                        <img
                          className="align-self-start"
                          src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${data.image}${IMAGE_URL_CONFIG.LARGE_IMAGE_EXT}`}
                          alt={data.movie}
                          title={data.movie}
                        />
                      </div>
                      <div
                        style={{ minHeight: "120px" }}
                        className="img-content"
                      >
                        <h3>{data.name}</h3>

                        <div className="action">
                          <div className="action-left">
                            {data.release_date
                              ? moment(data.movieReleaseDate).format("MMM DD")
                              : ""}
                          </div>
                          <div className="action-right">
                            <a
                              href="javascript:void(0)"
                              className="btn-new btn-border-danger-new"
                              onClick={() => learnMore(data)}
                            >
                              LEARN MORE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="err-noMovie">No sessions available</div>
        )}
      </section>
    </div>
  );
};

export default Search;
