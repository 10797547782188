import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { getActivitiesApi } from "../../../../services/sideMenus";
import {
  getSlidesWatcher,
  setFooterType,
} from "../../../../store/application/action";
import Activity from "../../../../components/Session/AU/Activity";

interface IActivitiesProps {}

const Activities: FC<IActivitiesProps> = () => {
  const { countryId, slides } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    slides: state.applicationReducer.slides,
  }));

  const dispatch = useDispatch();
  const [funstuffs, setFunstuffs] = useState([]);

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.DEALS,
        })
      );

      const getDeals = async () => {
        const response = await getActivitiesApi({ countryId: countryId });
        setFunstuffs(response.data.funstuffs);
        dispatch(setFooterType(response.data.deals.length));
      };
      getDeals();
    }
  }, [countryId]);
  return (
    <section className="offer-events">
      {funstuffs.map((funstuff) => (
        <Activity data={funstuff} />
      ))}
    </section>
  );
};

export default Activities;
