import { ICinemas } from "../../models/cinema";
import { IPromoAd, ISlide } from "../../models/common";
import { IFooterMenu, IMainNav } from "../../models/sidemenu";

export interface IApplication {
    countryId: string,
    brandId: string,
    settings: {
        VistaUrl?: string;
        countryId?: string;
        defaultCinema?: string;
        enableSeatGap?: number;
        enableTicketBooking?: number;
        enableUndermaintenance?: number;
        paymentGateway?: string;
        serviceChargeFee?: number;
        glServiceChargeFee?: number;
        sohoServiceChargeFee?: number,
        underMaintenanceEmail?: string;
        uniqueKey?: string;
        voucher_access_token?: string;
        voucherTicketing: string,
        voucherFB: string,
        membership: {
            upgrade: {
                itemId: number,
                recognitionId: number
            }
        } | null
    },
    token: string,
    cinemas: {
        [key: string]: ICinemas[]
    } | null,
    currentCinema: ICinemas | null
    slides: ISlide[],
    promoAd: IPromoAd[],
    promoAdSuccess: boolean,
    openSlider: { open: boolean, type: string },
    footerType: any,
    page: string,
    movieDetailBackUrl: string,
    loader: boolean,
    reinitializeScroll: boolean,
    reinitializeFilter: boolean,
    infoPopup: {
        open: boolean,
        type: string
    }
    error: any
    openContentModal: boolean,
    contentModal: {
        type: string,
        data: any
    }
    menu: {
        header: IMainNav[],
        footer: IFooterMenu[]
    },
    dateObject: any;
}


export const ACTIONS = {
    GET_SETTINGS_WATCHER: 'SETTINGS_WATCHER',
    GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
    GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
    SET_COUNTRY_ID: 'SET_COUNTRY_ID',
    OPEN_SLIDER: 'OPEN_SLIDER',
    GET_CINEMAS_WATCHER: 'GET_CINEMAS_WATCHER',
    GET_CINEMAS_SUCCESS: 'GET_CINEMAS_SUCCESS',
    GET_CINEMAS_ERROR: 'GET_CINEMAS_ERROR',
    SET_SELECTED_CINEMA: 'SET_SELECTED_CINEMA',
    SET_LOADER: 'SET_LOADER',
    GET_SLIDES_WATCHER: 'GET_SLIDES_WATCHER',
    GET_SLIDES_SUCCESS: 'GET_SLIDES_SUCCESS',
    GET_SLIDES_FAILURE: 'GET_SLIDES_FAILURE',
    GET_PROMO_AD_WATCHER: 'GET_PROMO_AD_WATCHER',
    GET_PROMO_AD_SUCCESS: 'GET_PROMO_AD_SUCCESS',
    GET_PROMO_AD_FAILURE: 'GET_PROMO_AD_FAILURE',
    CLEAR_PROMO_AD: 'CLEAR_PROMO_AD',
    SET_FOOTER_TYPE: 'SET_FOOTER_TYPE',
    SET_MOVIE_DETAIL_BACK_URL: 'SET_MOVIE_DETAIL_BACK_URL',
    SET_REINITIALIZE_SCROLL: 'SET_REINITIALIZE_SCROLL',
    SET_POPUP_INFO: 'SET_POPUP_INFO',
    SIGN_IN_WATCHER: 'SIGN_IN_WATCHER',
    SET_BRAND_ID: 'SET_BRAND_ID',
    SET_REINITIALIZE_FILTER: 'SET_REINITIALIZE_FILTER',
    TOGGLE_CONTENT_MODAL: 'TOGGLE_CONTENT_MODAL',
    SET_CONTENT_MODAL: 'SET_CONTENT_MODAL',
    MENU_WATCHER: 'MENU_WATCHER',
    MENU_SUCCESS: 'MENU_SUCCESS',
    MENU_ERROR: 'MENU_ERROR',
    SET_DATE_OBJECT:'SET_DATE_OBJECT'
}