import { FC, useEffect, useState } from "react";
import { IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import { ROUTES } from "../../../constants/path";
import "./advertiseWithUs.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { pageContentApi } from "../../../services/sideMenus";
import { IAdvertise, IAdvetisement, IButton } from "../../../models/sidemenu";
import HtmlRender from "../../../components/UI/HtmlRender";
import { useNavigate } from "react-router-dom";
import {
  setContentModal,
  toggleContentModal,
} from "../../../store/application/action";
import { downloadFile } from "../../../utils/helper";

interface IAdvertiseWithUsProps {}

const AdvertiseWithUs: FC<IAdvertiseWithUsProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [advertise, setAdvertise] = useState<IAdvetisement | null>(null);

  const getAdvertisment = async () => {
    const {
      data: { data, status },
    } = await pageContentApi({
      countryId,
      pageName: "advertising",
    });
    if (status && data) {
      setAdvertise(data);
    }
  };

  useEffect(() => {
    if (countryId) {
      getAdvertisment();
    }
  }, [countryId]);

  const contactUs = () => {
    dispatch(
      setContentModal({ type: ROUTES.CONTACT_US, category: "advertising" })
    );
    dispatch(toggleContentModal(true));
  };

  const openLink = (v: IButton) => {
    const { ButtonLinkURL } = v;
    if (ButtonLinkURL) {
      if (ButtonLinkURL.includes("https")) {
        window.open(v.ButtonLinkURL, "_blank");
      } else {
        navigate(`/${currentCinema?.alias}${ButtonLinkURL}`);
      }
    }
  };

  return advertise ? (
    <div className="advertise-wrapper">
      <div className="advertise-container">
        <div className="advertise-header">
          <h3>{advertise.title}</h3>
          <HtmlRender content={advertise.description} />
        </div>
      </div>
      <div className="advertise-section">
        {advertise.advertisement.map((v: IAdvertise) => (
          <div
            className="pre-show"
            style={{
              backgroundImage: `linear-gradient(90deg,rgba(0, 0, 0, 0.4) 10%,rgba(0, 0, 0, 1) 99.05%), url(${
                URLS.CLOUDFRONT_URL +
                "/" +
                IMAGE_URL_CONFIG.IMAGE_PATH +
                "/" +
                v.image +
                IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT
              })`,
            }}
          >
            <HtmlRender content={v.Title} {...{ className: "title" }} />
            <HtmlRender
              content={v.Description}
              {...{ className: "mt-3 mb-0" }}
            />
          </div>
        ))}
        <div className="contact">
          <h3 className="my-3">Contact Information</h3>
          <HtmlRender content={advertise.contactInformation} />
          {/* <button className="pricing my-2">
            <img src="/images/Download.svg" />
            PRICING & REQUIREMENTS
          </button> */}
          <>
            {advertise.buttons.length > 0
              ? advertise.buttons.map((v: IButton) => {
                  return v.ButtonType === "file" ? (
                    !v.ButtonLinkURL ? (
                      <a  className="download-btn" 
                        href={v.ButtonLinkURL}
                        onClick={() => downloadFile(v.ButtonLinkURL)}
                      >
                        {v.ButtonText}
                      </a>
                    ) : null
                  ) : (
                    <button  className="download-btn" onClick={() => openLink(v)}>
                      {v.ButtonText}
                    </button>
                  );
                })
              : null}
          </>
        </div>
      </div>
    </div>
  ) : null;
};

export default AdvertiseWithUs;
