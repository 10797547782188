import React, { FC, useEffect, useState } from "react";
import "./specialValue.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { specialValueApi } from "../../../../services/films";
import {
  ISignatureProgramming,
  ISpecialValues,
} from "../../../../models/cinema";

interface ISpecialValuesProps {}
const SpecialValues: FC<ISpecialValuesProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const [specialValues, setSpecialValues] = useState<ISpecialValues[]>([]);

  const getData = async () => {
    const {
      data: { data, statusCode },
    } = (await specialValueApi({
      cinemaId: currentCinema.slug,
      status: API_REQUEST_TYPE.GET_SPECIAL_VALUE,
      countryId: countryId,
    })) as any;

    if (statusCode === 200 && data && data.specialValues) {
      setSpecialValues(data.specialValues.filter((v:ISpecialValues)=>v.ShowOnMovies === 1));
    }
  };

  useEffect(() => {
    getData();
  }, [countryId, currentCinema]);

  const downloadFile = (v:ISpecialValues)=>{
    window.open(`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${v.DownloadableFile}.pdf`, "_blank")?.focus();
  }

  return (
    <section className="special-value-section">
      {specialValues.length > 0 ? (
        <div className="special-value-content">
          <h2 className="heading">SPECIAL VALUES & PROGRAMS</h2>
          <div className="img-section row">
            {specialValues.map((v) => (
              <div className="col-lg-4 col-md-6">
                <div className="img-section-list-cont">
                  <h3 className="header">{v.Title}</h3>
                  <div className="img-container">
                    <img
                      src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.image}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT}`}
                    />
                    {v.ShowDownloadButton === 1 && v.DownloadButtonText?<div className="download">
                      <button className="btn-download" onClick={()=>downloadFile(v)}>
                        <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}download_icon_white.svg`}/>
                        {v.DownloadButtonText}
                        </button>
                    </div>: null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default SpecialValues;
