import React, { FC, useState, useEffect } from "react";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  getSlidesWatcher,
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { vipCinemasApi } from "../../../../services/sideMenus";
import Slider from "../../../../components/UI/Slider";
import "./vipcinemas.scss";
import { IVipCinemas } from "../../../../models/sidemenu";
import { ROUTES } from "../../../../constants/path";

interface IVipCinemasProps {}

const VipCinemas: FC<IVipCinemasProps> = () => {
  const { countryId, slides, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      slides: state.applicationReducer.slides,
    })
  );

  const [data, setData] = useState<IVipCinemas[]>([]);
  const dispatch = useDispatch();

  const getData = async () => {
    const {
      data: { data, statusCode },
    } = await vipCinemasApi("1");
    if (statusCode && data) {
      setData(data.vipScreen)
    }
  };

  useEffect(() => {
    if (countryId) {
      getData();
    }
  }, [countryId]);

  useEffect(() => {
    if (countryId && currentCinema) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.VIPEXPERIENCE,
          location: currentCinema?.slug,
        })
      );
    }
  }, [countryId, currentCinema]);

  const booknow = () => {
    dispatch(setContentModal({ type: ROUTES.VIP_EXPERIENCE }));
    dispatch(toggleContentModal(true));
  };

  return (
    <div className="vip-cinema">
      <div className="slider-filter">
        <Slider
          className="banner-slider-us"
          data={slides}
          isDotsNeeded={true}
        />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="thumb_wrap p-0">
            {data.map((list: any) => (
              <div className="list_thumb">
                <React.Fragment key={list.title}>
                  <div className="thumb_content_wrap">
                    <div className="thumb_content">
                      <div className="thumb_title">{list.title}</div>
                      <div className="description">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: list.description1,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="thumb_image">
                    <Slider data={list.images} />
                  </div>
                </React.Fragment>
              </div>
            ))}
          </div>
          <div className="vip_footer_section">
            <h2>Ready to live it up?</h2>
            <p>Book your experience now</p>
            <a className="white_btn" onClick={booknow}>
              BOOK NOW
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VipCinemas;
