import React, { FC, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useParams } from "react-router";
import {
  getMenuWatcher,
  setCinemaAction,
} from "../../store/application/action";
import { findCinemaObject, formatLocation } from "../../utils/formatter";
import Storage from "../../utils/storage";
import { toggleTicketModal } from "../../store/ticketBooking/action";
import { useNavigate } from "react-router-dom";
import SelectDropdown from "../../components/UI/DropDown/SelectDropDown";
import Modal from "../../components/UI/Modal";
import { ROUTES } from "../../constants/path";

interface MainProps {
  children: React.ReactNode;
}

const USLayout: FC<MainProps> = ({ children }) => {
  const { currentCinema, countryId, cinemas } = useSelector(
    (state: RootState) => ({
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
      countryId: state.applicationReducer.countryId,
    })
  );

  const { cinemaId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [cinemaList, setCinemaList] = useState([]);

  useEffect(()=>{
    document.body.classList.add('layout-us');
    document.body.classList.remove('layout-au');
  },[])

  useEffect(() => {
    if (countryId && cinemas && cinemaList.length === 0) {
      setCinemaList(formatLocation(cinemas, true));
    }
  }, [countryId, cinemas]);

  useEffect(() => {
    if (countryId && currentCinema) {
      dispatch(
        getMenuWatcher({
          countryId,
          cinemaId: currentCinema?.slug,
          flag: "menu",
        })
      );
    }
  }, [countryId, currentCinema]);

  useEffect(() => {
    if (
      (!currentCinema || currentCinema.alias !== cinemaId) &&
      cinemaId &&
      Object.keys(cinemas).length > 0
    ) {
      const cinema = findCinemaObject(cinemas, cinemaId);
      if (cinema) {
        dispatch(setCinemaAction(cinema));
      }
    } else {
      const sessonCinema = Storage.getItem("LOCAL", "currentCinema");
      if (
        countryId &&
        cinemas &&
        sessonCinema &&
        Object.keys(sessonCinema).length > 0 &&
        !currentCinema
      ) {
        const storageCinema = findCinemaObject(cinemas, sessonCinema?.alias);
        if (storageCinema) {
          dispatch(setCinemaAction(storageCinema));
        }
        setOpenModal(false);
        navigate(`/${sessonCinema.alias}/${ROUTES.MOVIES}`);
      } else if (
        countryId &&
        cinemas &&
        (!currentCinema || Object.keys(currentCinema).length === 0)
      ) {
        if(sessonCinema.alias){
        navigate(`/${sessonCinema.alias}/${ROUTES.MOVIES}`);
        }
        setOpenModal(true);
        dispatch(toggleTicketModal(false));
      }
    }
  }, [cinemas, cinemaId]);

  const onChangeCinema = (value: string) => {
    if (cinemas) {
      const curcinema = findCinemaObject(cinemas, value);
      dispatch(setCinemaAction(curcinema));
      navigate(`/${curcinema && curcinema.slug ? `${curcinema.alias}/${ROUTES.MOVIES}` : ""}`);
      setOpenModal(false);
    }
  };

  return (
    <>
      <div className="layout-us">
        <Header />
        <div>{children}</div>
        <Footer />
      </div>
      <Modal
        className="header-us-modal"
        visible={openModal}
        showCloseBtn={false}
      >
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="logo">
                  <img src="/images/reading_logo_us.svg" />
                </div>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <SelectDropdown
                    placeholder="Select your nearest location"
                    options={cinemaList}
                    onChange={onChangeCinema}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default USLayout;
