import React,{ FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Modal from "../../../UI/Modal";
import { showReelClubModal } from "../../../../store/auth/action";
import { redeemPointsApi } from "../../../../services/auth";
import { IRedeemPoints } from "../../../../models/auth";
import "./redeem.scss";
import { URLS } from "../../../../constants/url";

interface IRedeemProps {}

const Redeem: FC<IRedeemProps> = () => {
  const { memberDetail, openReelClubModal, countryId } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      memberDetail: state.authReducer.memberDetail,
      openReelClubModal: state.authReducer.openReelClubModal,
    })
  );

  const dispatch = useDispatch();
  const [redeemData, setRedeemData] = useState<IRedeemPoints | null>(null);

  const onCloseModal = () => {
    dispatch(showReelClubModal(false));
  };

  const getRedeemPoints = async () => {
    const {
      data: { data, statusCode },
    } = await redeemPointsApi({
      cardNumber: memberDetail?.LoyaltyMember?.CardNumber!,
      countryId: countryId,
    });
    if (statusCode === 200) {
      setRedeemData(data.memberItems);
    }
  };

  useEffect(() => {
    if (memberDetail) {
      getRedeemPoints();
    }
  }, [memberDetail]);

  const remainingPoints = useMemo(() => {
    if (
      memberDetail &&
      memberDetail.LoyaltyMember &&
      memberDetail.LoyaltyMember.BalanceList &&
      memberDetail.LoyaltyMember.BalanceList.length > 0
    ) {
      return memberDetail.LoyaltyMember.BalanceList[0].PointsRemaining;
    } else {
      return 0;
    }
  }, [memberDetail]);

  useEffect(() => {
    redeemPointsList(50, "ticketTypeList");
  }, [redeemData]);

  const redeemPointsList = (maxPoint: number, type: keyof IRedeemPoints) => {
    if (redeemData && redeemData[type]) {
      let points;
      if (type == "ticketTypeList") {

        points = redeemData.ticketTypeList?.find((val: any) => {
          return val.points >= maxPoint;
        });
      } else {
        points = redeemData.concessionList.find(
          (val: any) => val.points >= maxPoint
        );
      }

      if (points) {
        return points.qty;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const checkStringMatch = (matchString: any) => {
    if (redeemData?.concessionList && redeemData?.concessionList.length > 0) {
      let isMatched = false;
      redeemData?.concessionList.forEach((val: any) => {
        const reg = new RegExp(matchString, "i");
        const res: any = val.name.match(reg);
        if (res && res != "") {
          isMatched = true;
        }
      });
      return isMatched;
    } else {
      return false;
    }
  };

  const getQty = (matchString: any) => {
    if (redeemData?.concessionList && redeemData?.concessionList.length > 0) {
      let qty = 0;
      redeemData?.concessionList.forEach((val: any) => {
        const reg = new RegExp(matchString, "i");
        const res: any = val.name.match(reg);
        if (res && res != "") {
          qty += val.qty;
        }
      });
      return qty;
    } else {
      return 0;
    }
  };

  return (
    <Modal visible={openReelClubModal} className="redeem-popup" showCloseBtn={false}>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-lg ">
       
          <div className="modal-content">
          <button type="button" className="close-modal" onClick={onCloseModal}>
            <img
              className="close_icon"
              src={URLS.CLOSE_ICON}
              alt="close_icon"
              title="close_icon"
            />
          </button>
            <div className="modal-body p-0">
              <div className="full_banner_wrap">
                <img
                  src="https://d209ktab91tz2c.cloudfront.net/wpdata/images/94577-oj.jpg"
                  alt=""
                />
              </div>
              <div className="rewards_redeem_content_wrap">
                <div className="top_section two_section_content">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="title">REEL Club Rewards</h2>
                    </div>
                    <div className="col-12">
                      <p>
                        To redeem you Reel Club rewards, simply present your
                        Club card or scan the barcode on your online membership
                        login at the counter and choose from the available
                        rewards below.
                      </p>
                      <p>
                        Upon redemption, your reward will be immediately
                        deducted from your account.
                      </p>
                      <p>
                        Remember each dollar you spend gets you 1 point closer
                        to your next reward!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bottom_section black_bg two_section_content">
                  <div className="row">
                    <div className="col-12">
                      <div className="header_wrap">
                        <h2 className="title">Your Rewards</h2>
                        <h2 className="points">{remainingPoints} Points</h2>
                      </div>
                    </div>
                    <div className="col-12">
                      <ul className="card_list">
                        <li
                          className={`ticket ${
                            redeemPointsList(50, "ticketTypeList") >= 1
                              ? "active"
                              : ""
                          } `}
                        >
                          <div>
                            <p className="heading">$5 off your next movie</p>
                            <p className="sub_text">
                              {redeemPointsList(50, "ticketTypeList")
                                ? redeemPointsList(50, "ticketTypeList") +
                                  " Available"
                                : "50 points needed to redeem"}
                            </p>
                          </div>
                        </li>
                        <li
                          className={`drink  ${
                            redeemPointsList(150, "concessionList") >= 1 ||
                            checkStringMatch("Free Regular Drink - Manual")
                              ? "active"
                              : ""
                          } `}
                        >
                          <div>
                            <p className="heading">FREE Regular Drink</p>
                            {redeemPointsList(150, "concessionList") ? (
                              <p className="sub_text">
                                {redeemPointsList(150, "concessionList") +
                                  " Available"}
                              </p>
                            ) : (
                              <p className="sub_text">
                                {getQty("Free Regular Drink - Manual")
                                  ? getQty("Free Regular Drink - Manual") +
                                    " Available"
                                  : "150 points needed to redeem"}
                              </p>
                            )}
                          </div>
                        </li>
                        <li
                          className={`popcorn ${
                            redeemPointsList(250, "concessionList") >= 1 ||
                            checkStringMatch("Free Regular Popcorn - Manual")
                              ? "active"
                              : ""
                          }`}
                        >
                          <div>
                            <p className="heading">FREE Regular Popcorn</p>
                            {redeemPointsList(250, "concessionList") ? (
                              <p className="sub_text">
                                {redeemPointsList(250, "concessionList") +
                                  " Available"}
                              </p>
                            ) : (
                              <p className="sub_text">
                                {getQty("Free Regular Popcorn - Manual")
                                  ? getQty("Free Regular Popcorn - Manual") +
                                    " Available"
                                  : "250 points needed to redeem"}
                              </p>
                            )}
                          </div>
                        </li>
                        <li
                          className={`icecream ${
                            redeemPointsList(350, "concessionList") >= 1 ||
                            checkStringMatch("Free Choc Top - Manual")
                              ? "active"
                              : ""
                          }`}
                        >
                          <div>
                            <p className="heading">FREE Choc Top</p>
                            {redeemPointsList(250, "concessionList") ? (
                              <p className="sub_text">
                                {redeemPointsList(250, "concessionList") +
                                  " Available"}
                              </p>
                            ) : (
                              <p className="sub_text">
                                {getQty("Free Choc Top - Manual")
                                  ? getQty("Free Choc Top - Manual") +
                                    " Available"
                                  : "250 points needed to redeem"}
                              </p>
                            )}
                          </div>
                        </li>
                        <li
                          className={`drink ${
                            redeemPointsList(450, "concessionList") >= 1
                              ? "active"
                              : ""
                          }`}
                        >
                          <div>
                            <p className="heading">FREE Large Drink</p>
                            <p className="sub_text">
                              {redeemPointsList(450, "concessionList")
                                ? redeemPointsList(450, "concessionList") +
                                  " Available"
                                : "450 points needed to redeem"}
                            </p>
                          </div>
                        </li>
                        <li
                          className={`ticket ${
                            redeemPointsList(500, "ticketTypeList") >= 1
                              ? "active"
                              : ""
                          }`}
                        >
                          <div>
                            <p className="heading">FREE Movie Ticket</p>
                            <p className="sub_text">
                              {redeemPointsList(500, "ticketTypeList")
                                ? redeemPointsList(500, "ticketTypeList") +
                                  " Available"
                                : "500 points needed to redeem"}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Redeem;
