import { FC } from 'react'; 

interface ICinemasProps{}

const Cinemas: FC<ICinemasProps> = ()=>{
    return <div>
        Cinemas
    </div>
}

export default Cinemas