import React, { FC } from "react";
import { Routes, Route } from "react-router";
import { Navigate } from "react-router-dom";
import PublicRoutes from "../../components/Routes/PublicRoutes";
import { ROUTES } from "../../constants/path";
import Cinemas from "../AU/Cinemas";
import GiftShop from "../Common/GiftShop";
import GiftCards from "../Common/GiftShop/Cards";
import Movies from "../AU/Movies";
import Activities from "../AU/Movies/Activities";
import ComingSoon from "../AU/Movies/ComingSoon";
import MovieDetails from "../AU/Movies/MovieDetails";
import NowShowing from "../AU/Movies/NowShowing";
import PrintSession from "../AU/Movies/PrintSession";
import SpecialEvents from "../AU/Movies/SpecialEvents";
import Deals from "../Common/ContentPage/Deals";
import Faq from "../Common/ContentPage//Faq";
import FoodAndBeverage from "../Common/FoodAndBeverage";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import AULayout from "../../layout/AU";
import { BRANDS } from "../../constants/app";
import USLayout from "../../layout/US";
import Home from "../US/Home";
import UsMovieDetails from "../US/MovieDetails";
import Ticketing from "../Common/Ticketing";
import SignaturePrograming from "../US/SignatureProgramming";
import SpecialValueProgram from "../US/SpecialValueProgram";
import Rewards from "../Common/Membership";
import AdvertiseWithUs from "../US/AdvertiseWithUs";
import ContactUs from "../Common/ContentPage/ContactUs";
import PrivacyPolicy from "../Common/ContentPage/PrivacyPolicy";
import Terms from "../Common/ContentPage/Terms";
import RatingIndex from "../Common/ContentPage/RatingIndex";
import Careers from "../US/Careers";
import GroupSale from "../Common/ContentPage/GroupSale";
import CinemaHire from "../Common/ContentPage/CinemaHire";
import VipCinemas from "../Common/ContentPage/VipCinemas";
import GiftCart from "../Common/GiftShop/Cart";
import TitanMovies from "../US/TitanMovies";
import Accessibility from "../US/Accessibility";
import ResetPassword from "../Common/ContentPage/ResetPassword";
import ConfirmUser from "../Common/ContentPage/ConfirmUser";
import NewsLetter from "../Common/ContentPage/NewsLetter";
import SearchUS from "../US/Search";
import GamingHires from "../Common/ContentPage/GamingHire";
import History from "../Common/ContentPage/History";
// import Advertising from "../Common/ContentPage/Advertising";
import ReelClub from "../Common/ContentPage/ReelClub";
import Competitions from "../Common/ContentPage/Competitions";
import CafeAndBar from "../Common/ContentPage/CafeAndBar";
import Advertising from "../Common/ContentPage/Advertising";
import SohoLounge from "../Common/ContentPage/SohoLounge";
import CustomPage from "../US/CustomPage";
import PageNotFound from "../Common/PageNotFound";
import SearchAU from "../AU/Search";
import About from "../Common/ContentPage/About";
import HighlineTerrace from "../Common/ContentPage/HighlineTerrace";
import FoodCollect from "../Common/Modals/FoodCollect";

interface RoutesProps {}

const AppRoutes: FC<RoutesProps> = () => {
  const { brandId, currentCinema } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  return brandId === BRANDS.AU ? (
    <Routes>
      {/* Movie Pages */}
      <Route
        path={ROUTES.MOVIES}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={ROUTES.NOW_SHOWING} element={<NowShowing />} />
        <Route path={ROUTES.COMING_SOON} element={<ComingSoon />} />
        <Route path={ROUTES.SPECIAL_EVENTS} element={<SpecialEvents />} />
        <Route path={ROUTES.KIDS_NOW_SHOWING} element={<NowShowing />} />
        <Route path={ROUTES.KIDS_COMING_SOON} element={<ComingSoon />} />
        <Route path={ROUTES.KIDS_ACTIVITIES} element={<Activities />} />
      </Route>
    
      <Route
        path={ROUTES.MOVIE_DETAIL_SEARCH}
        element={<PublicRoutes layout={AULayout} component={MovieDetails} />}
      />
        <Route
        path={ROUTES.MOVIE_DETAILS_AU}
        element={<PublicRoutes layout={AULayout} component={MovieDetails} />}
      />
      <Route path={ROUTES.PRINT_SESSIONS} element={<PrintSession />}></Route>

      {/* Ticket Booking*/}
      <Route
        path={ROUTES.TICKET_BOOKING}
        element={<PublicRoutes layout={AULayout} component={Ticketing} />}
      ></Route>

      <Route
        path={`${ROUTES.HOME}/${ROUTES.PAGE_NOT_FOUND}`}
        element={<PublicRoutes layout={AULayout} component={PageNotFound} />}
      />
      {/* Food and beverage */}
      <Route
        path={ROUTES.FOOD_AND_BEVERAGE}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={""} element={<FoodAndBeverage />} />
      </Route>
       {/* Food and beverage I am here*/}
       <Route
        path={"am-here"}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={":code"} element={<FoodCollect/>} />
      </Route>
     

      {/* Cinemas */}
      <Route
        path={ROUTES.CINEMAS}
        element={<PublicRoutes layout={AULayout} component={Cinemas} />}
      ></Route>

      {/* vip experience */}
      <Route
        path={`${ROUTES.VIP_EXPERIENCE}`}
        element={<PublicRoutes layout={AULayout} component={VipCinemas} />}
      />
      {/* history */}
      <Route
        path={`${ROUTES.HISTORY}`}
        element={<PublicRoutes layout={AULayout} component={History} />}
      />
      <Route
        path={`${ROUTES.ABOUT}`}
        element={<PublicRoutes layout={AULayout} component={About} />}
      />

      {/* Membership */}
      <Route
        path={`${ROUTES.MEMBERSHIP}`}
        element={<PublicRoutes layout={AULayout} component={ReelClub} />}
      />
      <Route
        path={`${ROUTES.REWARDS}`}
        element={<PublicRoutes layout={AULayout} component={Rewards} />}
      />

      {/* Cafe & Bar */}
      <Route
        path={`${ROUTES.CAFEBAR}`}
        element={<PublicRoutes layout={AULayout} component={CafeAndBar} />}
      />
      <Route
        path={`${ROUTES.HIGHLINE_TERRACE}`}
        element={<PublicRoutes layout={AULayout} component={HighlineTerrace} />}
      />

      {/* advertising */}
      <Route
        path={`${ROUTES.ADVERTISING}`}
        element={<PublicRoutes layout={AULayout} component={Advertising} />}
      />
      {/* reel club */}
      <Route
        path={`${ROUTES.REELCLUB}`}
        element={<PublicRoutes layout={AULayout} component={ReelClub} />}
      />
      {/* Search */}
      <Route
        path={`${ROUTES.SEARCH_RESULT}`}
        element={<PublicRoutes layout={AULayout} component={SearchAU} />}
      />

      {/* Competitions */}
      <Route
        path={`${ROUTES.COMPETITIONS}`}
        element={<PublicRoutes layout={AULayout} component={Competitions} />}
      />
      {/* mailinglist */}
      <Route
        path={`${ROUTES.MAILINGLIST}`}
        element={<PublicRoutes layout={AULayout} component={Competitions} />}
      />

      {/* SohoLounge */}
      <Route
        path={`${ROUTES.SOHOLOUNGE}`}
        element={<PublicRoutes layout={AULayout} component={SohoLounge} />}
      />

      {/* gaming hire */}
      <Route
        path={`${ROUTES.GAMING_HIRE}`}
        element={<PublicRoutes layout={AULayout} component={GamingHires} />}
      />

      {/* Gift Shop */}
      <Route
        path={ROUTES.GIFTSHOP}
        element={<PublicRoutes layout={AULayout} component={GiftShop} />}
      >
        <Route path={ROUTES.ECARDS} element={<GiftCards />} />
        <Route path={ROUTES.PHYSICAL_CARDS} element={<GiftCards />} />
        <Route path={ROUTES.EXPERIENCE} element={<GiftCards />} />
      </Route>

      <Route
        path={`/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`}
        element={<PublicRoutes layout={AULayout} component={GiftCart} />}
      />

      {/* Side Menus */}
      <Route
        path={ROUTES.OFFERS_EVENTS}
        element={<PublicRoutes layout={AULayout} component={Deals} />}
      />
      <Route
        path={ROUTES.FAQ}
        element={<PublicRoutes layout={AULayout} component={Faq} />}
      />
      <Route
        path={ROUTES.CINEMA_HIRE}
        element={<PublicRoutes layout={AULayout} component={CinemaHire} />}
      />

      {/* account Verification */}
      <Route
        path={ROUTES.CONFIRM_USER}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={`:code`} element={<ConfirmUser />} />
      </Route>

      <Route path={ROUTES.PRIVACY_POLICY_AU} element={<PrivacyPolicy />}>
        {/* <Route path={":type"} element={null} /> */}
      </Route>
      <Route path={ROUTES.CONTACT_US_AU} element={<ContactUs />}>
        {/* <Route path={":type"} element={null} /> */}
      </Route>
      <Route
        path={`${ROUTES.RESET_PASSWORD}`}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={`:code`} element={<ResetPassword />} />
      </Route>
      {/* Wild card */}
      <Route
        path="*"
        element={<Navigate to={`${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`} />}
      />
    </Routes>
  ) : brandId === BRANDS.US ? (
    <Routes>
      {/* Movie Pages */}
      <Route
        path={`${ROUTES.HOME}${ROUTES.MOVIES}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      />
      <Route
        path={ROUTES.HOME}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      >
        {/* Food and beverage */}
        <Route path={ROUTES.EAT_AND_DRINK} element={<FoodAndBeverage />} />
        {/* Ticketing */}
        <Route path={ROUTES.TICKETS} element={<Ticketing type="ticket" />} />
        {/* Refund */}
        <Route path={ROUTES.REFUND} element={<Ticketing type="refund" />} />
        {/* Refund */}
        <Route
          path={ROUTES.SWAP_SEAT}
          element={<Ticketing type="swapSeat" />}
        />
        {/* Soical Share */}
        <Route
          path={ROUTES.SOCIAL_SHARE}
          element={<Ticketing type="ticket" />}
        />
        {/* Contact Us */}
        <Route path={ROUTES.CONTACT_US} element={<ContactUs />}>
          <Route path={":type"} element={null} />
        </Route>
        {/* Privacy policy */}
        <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />}>
          <Route path={":type"} element={null} />
        </Route>
        {/* Terms */}
        <Route path={ROUTES.TERMS} element={<Terms />} />
        {/* Rating */}
        <Route path={ROUTES.RATING} element={<RatingIndex />} />
        {/* Group booking */}
        <Route path={ROUTES.GROUP_SALE} element={<GroupSale />} />
        {/* Cinema Hire */}
        <Route path={ROUTES.CINEMA_HIRE} element={<CinemaHire />} />
      </Route>

      {/* Movie details */}
      <Route
        path={ROUTES.MOVIE_DETAILS}
        element={<PublicRoutes layout={USLayout} component={UsMovieDetails} />}
      />

      {/* Movie details */}
      <Route
        path={ROUTES.MOVIE_DETAILS_COMING_SOON}
        element={<PublicRoutes layout={USLayout} component={UsMovieDetails} />}
      />

      {/* Signature Programming */}
      <Route
        path={ROUTES.SIGNATURE_PROGRAMS}
        element={
          <PublicRoutes layout={USLayout} component={SignaturePrograming} />
        }
      />
      {/* Signature Programming */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.SIGNATURE_PROGRAM}`}
        element={
          <PublicRoutes layout={USLayout} component={SignaturePrograming} />
        }
      />

      {/* News letter */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.NEWS_LETTER}`}
        element={<PublicRoutes layout={USLayout} component={NewsLetter} />}
      />

      {/* Titan luxe */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.MOVIES}/${ROUTES.TITAN_FLAG}`}
        element={<PublicRoutes layout={USLayout} component={TitanMovies} />}
      />

      {/* Special Value Program*/}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.SPECIAL_VALUES}`}
        element={
          <PublicRoutes layout={USLayout} component={SpecialValueProgram} />
        }
      />

      {/* Rewards */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.REWARDS}`}
        element={<PublicRoutes layout={USLayout} component={Rewards} />}
      />

      {/* Search */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.SEARCH}`}
        element={<PublicRoutes layout={USLayout} component={SearchUS} />}
      />

      {/* Advertise with us */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.ADVERTISE}`}
        element={<PublicRoutes layout={USLayout} component={AdvertiseWithUs} />}
      />

      {/* Accessibility */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.ACCESSIBILITY}`}
        element={<PublicRoutes layout={USLayout} component={Accessibility} />}
      />

      {/* Careers */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.CAREER}`}
        element={<PublicRoutes layout={USLayout} component={Careers} />}
      />

      {/* FAQ */}
      <Route
        path={ROUTES.FAQ_US}
        element={<PublicRoutes layout={USLayout} component={Faq} />}
      />

      {/* Cinema Hire */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.VIP_EXPERIENCE}`}
        element={<PublicRoutes layout={USLayout} component={VipCinemas} />}
      />

      {/* Gift Shop */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.GIFTSHOP}`}
        element={<PublicRoutes layout={USLayout} component={GiftShop} />}
      >
        <Route path={""} element={<GiftCards />} />
        <Route path={ROUTES.ECARDS} element={<GiftCards />} />
        <Route path={ROUTES.PHYSICAL_CARDS} element={<GiftCards />} />
      </Route>

      {/* Gift Cart */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`}
        element={<PublicRoutes layout={USLayout} component={GiftCart} />}
      />

      <Route
        path={`${ROUTES.RESET_PASSWORD}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      >
        <Route path={`:code`} element={<ResetPassword />} />
      </Route>

      <Route
        path={`${ROUTES.CONFIRM_USER}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      >
        <Route path={`:code`} element={<ConfirmUser />} />
      </Route>

      {/* Wild card */}
      <Route
        path="*"
        element={<PublicRoutes layout={USLayout} component={Home} />}
      />
      {/* Custom Page*/}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.CUSTOM_PAGE}`}
        element={<PublicRoutes layout={USLayout} component={CustomPage} />}
      />

      {/* Page Not Found */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.PAGE_NOT_FOUND}`}
        element={<PublicRoutes layout={USLayout} component={PageNotFound} />}
      />
    </Routes>
  ) : (
    <Routes></Routes>
  );
};

export default AppRoutes;
