import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";

interface ITermsProps {}

const Terms: FC<ITermsProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setContentModal({ type: ROUTES.TERMS }));
    dispatch(toggleContentModal(true));
  }, []);

  return null;
};

export default Terms;
