import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IMovieData, ISessionDetails } from "../../../../models/cinema";
import moment from "moment";
import "./singleShowTime.scss";
import { formatFlags, seatFillStatus } from "../../../../utils/formatter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { enableShowTime } from "../../../../utils/helper";

interface ISingleShowTimeProps {
  data: IMovieData;
}

const SingleShowTime: FC<ISingleShowTimeProps> = ({ data }) => {
  const { currentCinema } = useSelector((state: RootState) => ({
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const navigate = useNavigate();

  const openTicketModal = (data: ISessionDetails, movie:IMovieData) => {
    if(enableShowTime(movie)){
      navigate(
        `/${currentCinema.alias}/sessions/${data.id}/${data.ScheduledFilmId}`
      );
    }else{
      toast.error('Sale not started');
    }
  };


  return (
    <div className="movie-details-section-new">
      <div className="movie-details-content-new">
        <div className="movie-details-left-new">
          <div className="movie-details-img">
            <img className="w-100" src={data.film_image_large_size} />
          </div>
          <div className="d-flex justify-content-start justify-content-md-center mt-2">
            <div className="movie-details-action">
              <div className="movie-details-action-list">
                <div className="movie-details-action-list-img">
                  <img src="/images/img-icon.svg" />
                </div>
                <div className="movie-details-action-list-cont">
                  <h3 className="active-cls">92%</h3>
                  <p className="rotten-tomatoes">Rotten Tomatoes Score</p>
                </div>
              </div>
              <div className="movie-details-action-list">
                <div className="movie-details-action-list-img">
                  <img src="/images/wishlist-icon.svg" />
                </div>
                <div className="movie-details-action-list-cont">
                  <h3>
                    <a href="javascript:void(0)" className="active-hover">
                      Add to Watch List
                    </a>
                  </h3>
                </div>
              </div>
              <div className="movie-details-action-list">
                <div className="movie-details-action-list-img">
                  <img src="/images/share-icon.svg" />
                </div>
                <div className="movie-details-action-list-cont">
                  <h3>
                    <a href="javascript:void(0)" className="active-hover">
                      Share
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="movie-details-right">
          <h3>{data.name}</h3>
          <p>
          <span className="vt-line">{data.ratingName} </span>
            {data.length
              ? moment.duration(data.length, "minutes").humanize()
              : ""}
          </p>
          <p>{data.gentre}</p>
          <div style={{maxHeight:500, overflowY:'scroll'}}>
          {data.showdates &&
            data.showdates.map((show: any) => {
              return show.showtypes.map((session: any) => (
                <>
                  {/* <div className="btn-section d-flex">
                          <a
                            href="javascript:void(0)"
                            className="btn-new btn-border-danger-big-new"
                          >
                            EXPERIENCE&nbsp;&nbsp;IT&nbsp;&nbsp;IN&nbsp;&nbsp;
                            {session.type}
                          </a>
                        </div> */}
                  <div className="movie-show-time-parent-section">
                    <div className="movie-show-time-parent">
                      <h4> {session.type}</h4>
                      <div className="movie-show-time-flex">
                        {session.showtimes.map((time: any) => (
                          <div
                            className={`movie-show-time  ${!enableShowTime(data)?'show-disable':''}`}
                            onClick={() => openTicketModal(time, data)}
                          >
                            {moment.parseZone(time.date_time).format("hh:mm A")}
                            {time.statusCode === "1" ? (
                              <span className="sold_out_txt">SOLD OUT</span>
                            ) : null}
                            {time.statusCode === "0" ? (
                              <div className="seat_wrap">
                                <div
                                  className={`fill_status ${
                                    seatFillStatus(
                                      time.available_seats,
                                      time.totalNumberOfSeats
                                    ) === 0
                                      ? "no_fill"
                                      : seatFillStatus(
                                          time.available_seats,
                                          time.totalNumberOfSeats
                                        ) === 1
                                      ? "slow_fill"
                                      : seatFillStatus(
                                          time.available_seats,
                                          time.totalNumberOfSeats
                                        ) === 2
                                      ? "fast_fill"
                                      : ""
                                  }`}
                                ></div>
                                <div className={`recliner`}></div>

                                {time.statusCode === "0" &&
                                  formatFlags(time).map((flagObj: any) => (
                                    <div className="flag_wrap">
                                      <span className="flag_text">
                                        {flagObj.short}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ));
            })}
            </div>
        </div>
      </div>
    </div>
  );
};

export default SingleShowTime;
