import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setModal,
  toggleTicketModal,
} from "../../../store/ticketBooking/action";
import {
  clearFoodItemsAction,
  setTicketLessFb,
  setUpModalHeaderText,
} from "../../../store/foodAndBeverage/action";
import { TICKET_FLOW } from "../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import { RootState } from "../../../store";
import { useParams } from "react-router";
import { setCinemaAction } from "../../../store/application/action";
import { findCinemaObject } from "../../../utils/formatter";
import { BRANDS, COUNTRY } from "../../../constants/app";

interface IDirectFbProps {}

const DirectFb: FC<IDirectFbProps> = () => {
  const { currentCinema, brandId, cinemas } = useSelector(
    (state: RootState) => ({
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
      brandId: state.applicationReducer.brandId,
    })
  );

  const dispatch = useDispatch();
  const { cinemaId } = useParams();


  useEffect(() => {
    if (cinemaId && currentCinema) {
      dispatch(clearFoodItemsAction());
      dispatch(setUpModalHeaderText(brandId === BRANDS.US?"Eat & Drink":"Food & Beverage"));
      dispatch(setTicketLessFb(true));
      dispatch(
        setModal({
          ...TICKET_FLOW[TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE][
            MODAL_TYPE.FOOD_AND_BEVERAGE_MAIN
          ],
          type: TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE,
        })
      );
      dispatch(toggleTicketModal(true));
    }
  }, [cinemaId, currentCinema]);

  return null;
};

export default DirectFb;
