import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { IMovieData } from "../../../../models/cinema";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { LOCATION_MAPPER } from "../../../../constants/app";

interface IComingSoonProps {
  data: any;
}

const ComingSoon: React.FC<IComingSoonProps> = ({ data }) => {
  const { currentCinema, countryId } = useSelector((state: RootState) => ({
    currentCinema: state.applicationReducer.currentCinema,
    countryId: state.applicationReducer.countryId
  }));

  const navigate = useNavigate();

  const onClickBuy = (data: IMovieData) => {
    let movieGroupId = "";
    if (
      data.showdates &&
      data.showdates[0] &&
      data.showdates[0].showtypes &&
      data.showdates[0].showtypes[0] &&
      data.showdates[0].showtypes[0].showtimes
    ) {
      movieGroupId =
        data.showdates[0].showtypes[0].showtimes[0].ScheduledFilmId;
    }
    navigate(`/${LOCATION_MAPPER[countryId][data.theater]?LOCATION_MAPPER[countryId][data.theater]:data.theater}/movies/details/${movieGroupId}`);
  };

  const learnMore = (data: IMovieData) => {
    navigate(`/${currentCinema?.alias}/movies/details?id=${data.slug}`);
  };

  return (
    <div className="col-xl-3 col-lg-4 col-md-6">
      <div className="img-content-parent">
        <div
          className="img-container"
          onClick={() =>
            Array.isArray(data.showdates) && data.showdates.length > 0
              ? onClickBuy(data)
              : learnMore(data)
          }
        >
          <img
            className="align-self-start"
            src={data.film_image_large_size}
            alt={data.movie}
            title={data.movie}
          />
        </div>
        <div style={{ minHeight: "120px" }} className="img-content">
          <h3>{data.movie}</h3>

          <div className="action">
            <div className="action-left">
              {data.release_date
                ? moment(data.release_date).format("MMM DD")
                : ""}
            </div>
            <div className="action-right">
              {data.showdates &&
              Array.isArray(data.showdates) &&
              data.showdates.length > 0 ? (
                <a
                  href="javascript:void(0)"
                  className="btn-new btn-border-danger-new"
                  onClick={() => onClickBuy(data)}
                >
                  BUY TICKETS
                </a>
              ) : (
                <a
                  href="javascript:void(0)"
                  className="btn-new btn-border-danger-new"
                  onClick={() => learnMore(data)}
                >
                  LEARN MORE
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
