import { FC, useEffect, useState } from "react";
import TextInput from "../../../../components/UI/TextInput";
import SelectDropdown from "../../../../components/UI/DropDown/SelectDropDown";
import { dateArray, monthArray, yearArray, yearSignupArray } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { formatLocation } from "../../../../utils/formatter";
import "./military.scss";
import { toast } from "react-toastify";
import { showLogin, signUpAsGuest } from "../../../../store/auth/action";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { IDropDown } from "../../../../models/common";
import {
  CAPTCHA_SITE_KEY,
  COUNTRY,
  GENDER_LIST,
  GENDER_LIST_STA,
  MONTH_LIST,
} from "../../../../constants/app";
import ReCAPTCHA from "react-google-recaptcha";
import {
  setModal,
  toggleTicketModal,
} from "../../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";

interface IMilitarySignUpProps {
  onCancel: () => void;
}

const MilitarySignUp: FC<IMilitarySignUpProps> = ({ onCancel }) => {
  const { countryId, cinemas, currentCinema, isLoggedIn } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
      isLoggedIn: state.authReducer.isLoggedIn,
    })
  );

  const relationship = [
    { value: "Self", label: "Self" },
    { value: "Spouse", label: "Spouse" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
  ];
  const dispatch = useDispatch();
  const [cinemaList, setCinemaList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [genderList, setGenderList] = useState<IDropDown[]>(GENDER_LIST);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (cinemas && Object.keys(cinemas).length > 0) {
      setCinemaList(formatLocation(cinemas));
    }
  }, [cinemas]);

  useEffect(() => {
    if (countryId === COUNTRY.AUS || countryId === COUNTRY.US) {
      setGenderList(GENDER_LIST);
    } else if (countryId === COUNTRY.STA) {
      setGenderList(GENDER_LIST_STA);
    }
  }, [countryId]);

  const { handleChange, handleSubmit,values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("signupMilitary"),
      enableReinitialize: true,
      initialValues: {
        countryId: countryId,
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        gender: "",
        genres: [],
        password: "",
        confirmPassword: "",
        cardNumber: "",
        day: "",
        month: "",
        year: "",
        cinemaId: currentCinema?.cinemaId || "",
        cardnumber: "",
        weeklyNews: 0,
        captcha: false,
        zipCode: "",
        city: "",
        isMilitary: true,
        militaryTerms: false,
        hasAdditionalMember: false,
        militaryMemberId: "",
        relationship: "",
        relationshipFirstName: "",
        relationshipLastName: "",
        relationshipEmail: "",
        relationshipContactNumber: "",
        relationshipPreferredLocation: currentCinema?.cinemaId || "",
        relationshipDay: "",
        relationshipMonth: "",
        relationshipYear: "",
        relationshipCity: "",
        relationshipZipCode: "",
      },
      onSubmit: (values) => {
        setErrorMessage("");
        const {
          firstName,
          lastName,
          middleName,
          email,
          phoneNumber,
          gender,
          genres,
          password,
          confirmPassword,
          cardNumber,
          day,
          month,
          year,
          cinemaId,
          weeklyNews,
          zipCode,
          city,
          isMilitary,
          militaryMemberId,
          relationship,
          relationshipFirstName,
          relationshipLastName,
          relationshipEmail,
          relationshipContactNumber,
          relationshipPreferredLocation,
          relationshipDay,
          relationshipMonth,
          relationshipYear,
          relationshipCity,
          relationshipZipCode,
        } = values;

        const payload: any = {
          cardNumber,
          countryId: countryId,
          dateOfBirth: `${year}-${month}-${day}`,
          email,
          firstName,
          middleName,
          gender,
          lastName,
          password,
          phoneNumber,
          preferredGenres: genres,
          theatreId: cinemaId,
          weeklyNews,
          zipCode,
          city,
          isMilitary,
          militaryMemberId,
        };
        if (values.hasAdditionalMember) {
          payload.additionalUser = {
            firstName: relationshipFirstName,
            lastName: relationshipLastName,
            email: relationshipEmail,
            phoneNumber: relationshipContactNumber,
            theatreId: relationshipPreferredLocation,
            relationship,
            dateOfBirth: `${relationshipYear}-${relationshipMonth}-${relationshipDay}`,
            city: relationshipCity,
            ZipCode: relationshipZipCode,
            countryId: countryId,
          };
        }
        dispatch(
          signUpAsGuest(
            payload,
            (response: any) => {
              if (response.statusCode === 200) {
                dispatch(showLogin(false));
                const modal =
                  TICKET_FLOW[TICKET_FLOW_TYPES.MILITARY_MEMBER][
                    MODAL_TYPE.MILITARY_MEMBER_CONFIRMATION
                  ];
                dispatch(
                  setModal({
                    ...modal,
                    type: TICKET_FLOW_TYPES.MILITARY_MEMBER,
                    data: {
                      header: "Thank you for signing up as a military member!",
                      title: ` Upon successful validation, an activation link will be sent to: ${values.email}`,
                      description:
                        "Activate your membership to start earning rewards!",
                    },
                  })
                );
                dispatch(toggleTicketModal(true));
              }
            },
            (error: any) => {
              setErrorMessage(error);
            }
          )
        );
      },
    });

  const setAddMember = (status: boolean) => {
    setFieldValue("hasAdditionalMember", status);
  };

  const onChangeWeeklyLetter = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("weeklyNews", checked ? 1 : 0);
  };

  const onChangeMilitaryTerms = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("militaryTerms", checked ? true : false);
  };

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  return (
    <div className="account_details_form form_page">
      <h6>Military Member Details</h6>
      <div className="row">
        <div className="col-4 col-md-4 ">
          <label className="form-label">First Name*</label>
          <div className="form-group">
            <TextInput
              value={values.firstName}
              field={"firstName"}
              handleChange={handleChange}
              touched={touched.firstName!}
              error={errors.firstName}
            />
          </div>
        </div>
        <div className="col-4 col-md-4">
          <label className="form-label">Middle Name</label>
          <div className="form-group">
            <TextInput
              value={values.middleName}
              field={"middleName"}
              handleChange={handleChange}
              touched={touched.middleName!}
              error={errors.middleName}
            />
          </div>
        </div>
        <div className="col-4 col-md-4">
          <label className="form-label">Last Name*</label>
          <div className="form-group">
            <TextInput
              value={values.lastName}
              field={"lastName"}
              handleChange={handleChange}
              touched={touched.lastName!}
              error={errors.lastName}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Email*</label>
          <div className="form-group">
            <TextInput
              value={values.email}
              field={"email"}
              handleChange={handleChange}
              touched={touched.email!}
              error={errors.email}
            />
          </div>
        </div>
        <div className="col-6 col-md-6">
          <label className="form-label">Contact Number*</label>
          <div className="form-group position-relative">
            <TextInput
              value={values.phoneNumber}
              field={"phoneNumber"}
              handleChange={handleChange}
              touched={touched.phoneNumber!}
              error={errors.phoneNumber}
            />
          </div>
        </div>
        <div className="col-6 col-md-6">
          <label className="form-label">Preferred Location</label>
          <div className="form-group">
            <SelectDropdown
              field={"cinemaId"}
              options={cinemaList}
              setFieldValue={setFieldValue}
              touched={touched.cinemaId!}
              error={errors.cinemaId}
              value={values.cinemaId}
            />
          </div>
        </div>
        <div className="col-6 col-md-6">
          <label className="form-label">Gender*</label>
          <div className="form-group">
            <SelectDropdown
              field={"gender"}
              options={genderList}
              setFieldValue={setFieldValue}
              touched={touched.gender!}
              error={errors.gender}
              value={values.gender}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Date of Birth*</label>
          <div className="form-group">
            <div className="row">
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"day"}
                  options={dateArray().map(v=>{
                    v.label = Math.round(+v.label).toString()
                    return v})}
                  setFieldValue={setFieldValue}
                  touched={touched.day!}  
                  error={errors.day}
                  value={values.day}
                />
              </div>
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"month"}
                  options={MONTH_LIST}
                  setFieldValue={setFieldValue}
                  touched={touched.month!}
                  error={errors.month}
                  value={values.month}
                />
              </div>
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"year"}
                  options={yearSignupArray()}
                  setFieldValue={setFieldValue}
                  touched={touched.year!}
                  error={errors.year}
                  value={values.year}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 col-md-3">
          <label className="form-label">City*</label>
          <div className="form-group position-relative">
            <TextInput
              value={values.city}
              field={"city"}
              handleChange={handleChange}
              touched={touched.city!}
              error={errors.city}
            />
          </div>
        </div>
        <div className="col-3 col-md-3">
          <label className="form-label">Zip Code*</label>
          <div className="form-group">
            <TextInput
              value={values.zipCode}
              field={"zipCode"}
              handleChange={handleChange}
              touched={touched.zipCode!}
              error={errors.zipCode}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Password*</label>
          <div className="form-group">
            <TextInput
              type="password"
              value={values.password}
              field={"password"}
              handleChange={handleChange}
              touched={touched.password!}
              error={errors.password}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Confirm Password*</label>
          <div className="form-group">
            <TextInput
              type="password"
              value={values.confirmPassword}
              field={"confirmPassword"}
              handleChange={handleChange}
              touched={touched.confirmPassword!}
              error={errors.confirmPassword}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Military Member ID*</label>
          <div className="form-group">
            <TextInput
              type="text"
              value={values.militaryMemberId}
              field={"militaryMemberId"}
              handleChange={handleChange}
              touched={touched.militaryMemberId!}
              error={errors.militaryMemberId}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="custom_checkbox p-default form-group">
            <input
              type="checkbox"
              value="1"
              onClick={onChangeMilitaryTerms}
              checked={values.militaryTerms}
            />
            <div className="state p-primary">
              <label className="form-label">
                I understand that the military information I have entered will
                be checked for validity.
              </label>
            </div>
          </div>
        </div>
        {touched && isSubmitted && errors && errors.militaryTerms ? (
          <div className="error_message">{errors.militaryTerms}</div>
        ) : null}
        <div className="col-md-12">
          <div className="custom_checkbox p-default form-group">
            <input
              type="checkbox"
              value="1"
              onClick={onChangeWeeklyLetter}
              checked={values.weeklyNews === 1 ? true : false}
            />
            <div className="state p-primary">
              <label className="form-label" style={{ fontWeight: "bold" }}>
                Keep me updated with weekly news
              </label>
            </div>
          </div>
        </div>
        {!values.hasAdditionalMember && (
          <div className="d-flex my-3" onClick={() => setAddMember(true)}>
            <div className="col-md-12 add-member">
              <span className="col-md-12 btn_plus"></span>
              <label className="addition-mem-title">
                ADD ADDITIONAL MEMBER
              </label>
            </div>
          </div>
        )}
        {values.hasAdditionalMember && (
          <div className="col-12">
          <div className="add-additional-member my-3">
            <h6
              className="addition-mem-title"
              onClick={() => setAddMember(false)}
            >
              {" "}
              <span className="col-md-12 btn_minus"></span>Add Additional Member
            </h6>
            <div className="row">
              <div className="col-4 col-md-4 ">
                <label className="form-label">Relationship*</label>
                <div className="form-group">
                  <SelectDropdown
                    field={"relationship"}
                    options={relationship}
                    setFieldValue={setFieldValue}
                    touched={touched.relationship!}
                    error={errors.relationship}
                    value={values.relationship}
                  />
                </div>
              </div>
              <div className="col-4 col-md-4 ">
                <label className="form-label">First Name*</label>
                <div className="form-group">
                  <TextInput
                    value={values.relationshipFirstName}
                    field={"relationshipFirstName"}
                    handleChange={handleChange}
                    touched={touched.relationshipFirstName!}
                    error={errors.relationshipFirstName}
                  />
                </div>
              </div>

              <div className="col-4 col-md-4">
                <label className="form-label">Last Name</label>
                <div className="form-group">
                  <TextInput
                    value={values.relationshipLastName}
                    field={"relationshipLastName"}
                    handleChange={handleChange}
                    touched={touched.relationshipLastName!}
                    error={errors.relationshipLastName}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="form-label">Email*</label>
                <div className="form-group">
                  <TextInput
                    value={values.relationshipEmail}
                    field={"relationshipEmail"}
                    handleChange={handleChange}
                    touched={touched.relationshipEmail!}
                    error={errors.relationshipEmail}
                  />
                </div>
              </div>
              <div className="col-3 col-md-3">
                <label className="form-label">Contact Number*</label>
                <div className="form-group position-relative">
                  <TextInput
                    value={values.relationshipContactNumber}
                    field={"relationshipContactNumber"}
                    handleChange={handleChange}
                    touched={touched.relationshipContactNumber!}
                    error={errors.relationshipContactNumber}
                  />
                </div>
              </div>
              <div className="col-3 col-md-3">
                <label className="form-label">Preferred Location</label>
                <div className="form-group">
                  <SelectDropdown
                    field={"relationshipPreferredLocation"}
                    options={cinemaList}
                    setFieldValue={setFieldValue}
                    touched={touched.relationshipPreferredLocation!}
                    error={errors.relationshipPreferredLocation}
                    value={values.relationshipPreferredLocation}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="form-label">Date of Birth*</label>
                <div className="form-group">
                  <div className="row">
                    <div className="col-4 col-md-4">
                      <SelectDropdown
                        field={"relationshipDay"}
                        options={dateArray().map(v=>{
                          v.label = Math.round(+v.label).toString()
                          return v})}
                        setFieldValue={setFieldValue}
                        touched={touched.relationshipDay!}
                        error={errors.relationshipDay}
                        value={values.relationshipDay}
                      />
                    </div>
                    <div className="col-4 col-md-4">
                      <SelectDropdown
                        field={"relationshipMonth"}
                        options={MONTH_LIST}
                        setFieldValue={setFieldValue}
                        touched={touched.relationshipMonth!}
                        error={errors.relationshipMonth}
                        value={values.relationshipMonth}
                      />
                    </div>
                    <div className="col-4 col-md-4">
                      <SelectDropdown
                        field={"relationshipYear"}
                        options={yearSignupArray()}
                        setFieldValue={setFieldValue}
                        touched={touched.relationshipYear!}
                        error={errors.relationshipYear}
                        value={values.relationshipYear}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 col-md-3">
                <label className="form-label">City*</label>
                <div className="form-group position-relative">
                  <TextInput
                    value={values.relationshipCity}
                    field={"relationshipCity"}
                    handleChange={handleChange}
                    touched={touched.relationshipCity!}
                    error={errors.relationshipCity}
                  />
                </div>
              </div>
              <div className="col-3 col-md-3">
                <label className="form-label">Zip Code*</label>
                <div className="form-group">
                  <TextInput
                    value={values.relationshipZipCode}
                    field={"relationshipZipCode"}
                    handleChange={handleChange}
                    touched={touched.relationshipZipCode!}
                    error={errors.relationshipZipCode}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
        )}
        <div className="col-12  col-md-12">
          <label className="form-label">Verification Code*</label>
          <div className="form-group">
            <ReCAPTCHA
              sitekey={`${CAPTCHA_SITE_KEY}`}
              onChange={onChangeCaptcha}
            />
            {errors.captcha && touched.captcha ? (
              <div className="error_message">
                <span>Verification Code is required</span>
              </div>
            ) : null}
          </div>
        </div>
        {errorMessage ? (
          <div className="err-message">{errorMessage}</div>
        ) : null}
        <div className="col-md-12 movie_footer_btn_el">
          <div className="signup-button">
            <button type="button" className="btn gray_btn" onClick={onCancel}>
              CANCEL
            </button>
            <button
              type="button"
              className="btn black_btn"
              onClick={() => {
                handleSubmit();
                setIsSubmitted(true);
              }}
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MilitarySignUp;
