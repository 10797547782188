import React, { FC, useEffect, useState } from "react";
import "./specialvalue.scss";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../constants/url";
import { specialValueApi } from "../../../services/films";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ISpecialValues } from "../../../models/cinema";
import HtmlRender from "../../../components/UI/HtmlRender";
import Slider from "../../../components/UI/Slider";
import { pageContentApi } from "../../../services/sideMenus";

interface ISpecialValueProgramProps {}

const SpecialValueProgram: FC<ISpecialValueProgramProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const [specialValues, setSpecialValues] = useState<ISpecialValues[]>([]);
  const [slides, setSlides] = useState([]);

  const getSlides = async () => {
    const {
      data: { data, status },
    } = await pageContentApi({
      countryId,
      pageName: `${currentCinema?.slug}#specialValues`,
    });
    if (status && data && data.specialValues) {
      setSlides(
        data.specialValues.map((v: any) => ({
          imageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.image}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
          mobileImageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.image}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
          showBannerInfo: 1,
          textColor: "#000",
          title: v.Title,
          description: v.Description
        }))
      );
    }
  };

  useEffect(() => {
    if (countryId && currentCinema) {
      getSlides();
    }
  }, [countryId, currentCinema]);

  const getData = async () => {
    const {
      data: { data, statusCode },
    } = (await specialValueApi({
      cinemaId: currentCinema.slug,
      status: API_REQUEST_TYPE.GET_SPECIAL_VALUE,
      countryId: countryId,
    })) as any;

    if (statusCode === 200 && data && data.specialValues) {
      setSpecialValues(
        data.specialValues.filter(
          (v: ISpecialValues) => v.ShowOnSpecialValues === 1
        )
      );
    }
  };

  useEffect(() => {
    getData();
  }, [countryId, currentCinema]);

  const downloadFile = (v: ISpecialValues) => {
    window
      .open(
        `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${v.DownloadableFile}.pdf`,
        "_blank"
      )
      ?.focus();
  };

  return (
    <div className="special-values">
      <div className="slider-filter">
        <Slider className="banner-slider-us" data={slides} />
      </div>
      <div className="container py-3">
        {specialValues.map((ele: any, index) => (
          <div className="row my-3">
            <div className="col-md-4">
              <div className="list_poster ng-star-inserted">
                <img
                  src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${ele.image}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT}`}
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="mt-0 title mobile_hide">{ele.Title}</h5>
                  <HtmlRender
                    {...{ className: "description ng-star-inserted" }}
                    content={ele.Description}
                  />

                  {ele.ShowDownloadButton === 1 && ele.DownloadButtonText ? (
                    <div className="col-md-12">
                      <div className="download">
                        <button
                          className="btn-download"
                          onClick={() => downloadFile(ele)}
                        >
                          <img
                            src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}download_icon_white.svg`}
                          />
                          {ele.DownloadButtonText}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialValueProgram;
