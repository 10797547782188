import React, { FC, useEffect, useMemo, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./showTimes.scss";
import ShowTimeFilter from "../../../../components/UI/Filters/ShowTimeFilter";
import {
  getNowShowingSuccess,
  getNowShowingWatcher,
} from "../../../../store/films/action";
import { setCinemaAction } from "../../../../store/application/action";
import {
  dateToFromNowDaily,
  findCinemaObject,
} from "../../../../utils/formatter";
import AllShowTimes from "../../../../components/Session/US/AllShowTimes";
import SingleShowTime from "../../../../components/Session/US/SingleShowTime";
import { getNowShowingApi } from "../../../../services/films";
import { IDropDown } from "../../../../models/common";
import moment from "moment";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { IMovieData } from "../../../../models/cinema";

interface IShowTimesProps {}

const ShowTimes: FC<IShowTimesProps> = () => {
  const {
    countryId,
    quickFilters,
    cinemas,
    brandId,
    nowShowing,
    currentCinema,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    quickFilters: state.filmsReducer.quickFilters,
    cinemas: state.applicationReducer.cinemas,
    brandId: state.applicationReducer.brandId,
    nowShowing: state.filmsReducer.nowShowing,
    currentCinema: state.applicationReducer.currentCinema,
  }));
  const limitLength = window.innerWidth < 600 ? 4 : 8;
  const dispatch = useDispatch();
  const [type, setType] = useState<IDropDown[]>([]);
  const [session, setSession] = useState<IDropDown[]>([]);
  const [limit, setLimit] = useState<number>(limitLength);
  const [allSession, setAllSession] = useState<IMovieData[]>([]);

  useEffect(() => {
    if (quickFilters.location) {
      const payload: any = {
        brandId,
        countryId: countryId,
        cinemaId: quickFilters.location,
        status: API_REQUEST_TYPE.NOW_SHOWING,
        flag: "",
      };
      if (quickFilters.movie) {
        payload.selectedMovie = quickFilters.movie;
      }
      if (quickFilters.format) {
        payload.selectedAttribute = quickFilters.format;
      }
      if (quickFilters.session) {
        payload.selectedSessionId = quickFilters.session;
      }
      if (quickFilters.date) {
        payload.selectedDate = quickFilters.date;
      }
      dispatch(getNowShowingWatcher(payload));
    }

    const cinema = findCinemaObject(cinemas, quickFilters.location!);
    if (cinema) {
      dispatch(setCinemaAction(cinema));
    }
  }, [quickFilters]);

  const getFilterData = async () => {
    const {
      status,
      response: { data },
      error,
    } = (await getNowShowingApi({
      cinemaId: currentCinema.slug,
      status: "nowShowing",
      countryId: countryId,
      flag: "",
    })) as any;

    if (status && data && data.statusCode === 200 && data.data.length > 0) {
      dispatch(getNowShowingSuccess(data.data));
      let format: string[] = [];
      let sessionDate: string[] = [];
      data.data.forEach((v: any) => {
        v.showdates.forEach((show: any) => {
          sessionDate.push(show.date);
          show.showtypes.forEach((t: any) => {
            format.push(t.type);
          });
        });
      });
      setType(
        [...new Set(format)].map((v) => {
          return { label: v, value: v };
        })
      );
      sessionDate = sessionDate.sort((a:string,b:string)=> new Date(a).getTime() - new Date(b).getTime())
      setSession(
        [...new Set(sessionDate)].map((v) => ({
          label: dateToFromNowDaily(v),
          value: v,
        }))
      );
    } else {
      setSession([]);
      setType([]);
    }
  };

  useEffect(() => {
    if (countryId && currentCinema.slug) {
      getFilterData();
    }
  }, [countryId, currentCinema]);

  const showAllSession = useMemo(() => {
    return quickFilters.session || quickFilters.date ? false : true;
  }, [quickFilters]);

  useEffect(() => {
    if (showAllSession && nowShowing) {
      setAllSession(nowShowing.slice(0, limit));
    }
  }, [showAllSession, nowShowing]);

  const onShowMore = () => {
    if (nowShowing.length != allSession.length) {
      setAllSession(nowShowing.slice(0, limit + limitLength));
      setLimit(limit + limitLength);
    }
  };

  const onShowLess = () => {
    setAllSession(nowShowing.slice(0, limitLength));
    setLimit(limitLength);
  };

  const showMore = useMemo(() => {
    return (
      nowShowing &&
      nowShowing.length > limitLength &&
      nowShowing.length != allSession.length
    );
  }, [nowShowing, allSession]);

  const showLess = useMemo(() => {
    return (
      nowShowing &&
      nowShowing.length > limitLength &&
      nowShowing.length == allSession.length
    );
  }, [nowShowing, allSession]);

  return (
    <section className="showtime-section">
      <div className="container-content">
        <div className="showtime-content">
          <h2 className="heading">SHOWTIMES & TICKETS</h2>
          <ShowTimeFilter session={session} type={type} />
          {showAllSession ? (
            <div className="showtime-section-thumbnail row justify-content-center">
              {allSession.length > 0 ? (
                <>
                  {allSession.map((session: IMovieData) => {
                    return <AllShowTimes session={session} />;
                  })}
                  {showMore || showLess ? (
                    <div className="show-more">
                      {showMore ? (
                        <p onClick={onShowMore}>SHOW MORE</p>
                      ) : (
                        <p onClick={onShowLess}>SHOW LESS</p>
                      )}
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="w-100 d-flex justify-content-center">
                  <div className="no-session">No Sessions Available</div>
                </div>
              )}
            </div>
          ) : (
            <div className="single-show-time-container">
              {nowShowing && nowShowing.length > 0 ? (
                nowShowing.map((v: IMovieData) => <SingleShowTime data={v} />)
              ) : (
                <div className="w-100 d-flex justify-content-center">
                  <div className="no-session">No Sessions Available</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ShowTimes;
