import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import React, { FC, useEffect, useMemo, useState } from "react";
import { getComingSoonWatcher } from "../../../../store/films/action";
import { IFormatMovie, IMovieData } from "../../../../models/cinema";
import ComingSoon from "../../../../components/Session/US/ComingSoon";
import "./comingSoon.scss";
import { IDropDown } from "../../../../models/common";

interface IComingSoonProps {}

const ComingSoonSection: FC<IComingSoonProps> = () => {
  const { countryId, cinemaId, comingSoon } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      cinemaId: state.applicationReducer.currentCinema?.cinemaId,
      comingSoon: state.filmsReducer.comingSoon,
    })
  );
  const limitLength = window.innerWidth < 600 ? 4 : 8;

  const [limit, setLimit] = useState<number>(limitLength);
  const [allSession, setAllSession] = useState<IMovieData[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cinemaId) {
      const payload = {
        countryId: countryId,
        cinemaId: cinemaId,
        flag: "",
        requestType: "",
      };
      dispatch(getComingSoonWatcher(payload));
    }
  }, [cinemaId]);

  useEffect(() => {
    if (comingSoon) {
      setAllSession(comingSoon.slice(0, limit));
    }
  }, [comingSoon]);

  const onShowMore = () => {
    if (comingSoon.length != allSession.length) {
      setAllSession(comingSoon.slice(0, limit + limitLength));
      setLimit(limit + limitLength);
    }
  };

  const onShowLess = () => {
    setAllSession(comingSoon.slice(0, limitLength));
    setLimit(limitLength);
  };

  const showMore = useMemo(() => {
    return (
      comingSoon &&
      comingSoon.length > limitLength &&
      comingSoon.length != allSession.length
    );
  }, [comingSoon, allSession]);

  const showLess = useMemo(() => {
    return (
      comingSoon &&
      comingSoon.length > limitLength &&
      comingSoon.length == allSession.length
    );
  }, [comingSoon, allSession]);

  return (
    <section className="coming-soon-section">
      {allSession.length > 0 ? (
        <div className="container-content">
          <div className="coming-soon img-section-parent-cont-white">
            <h2 className="heading">COMING SOON</h2>
            <div className="img-section-parent img-section-parent-four-list">
              {allSession.map((data: any) => (
                <ComingSoon data={data.movieData ? data.movieData : data} />
              ))}
            </div>
            {showMore || showLess ? (
                <div className="show-more">
                  {showMore ? (
                    <p onClick={onShowMore}>SHOW MORE</p>
                  ) : (
                    <p onClick={onShowLess}>SHOW LESS</p>
                  )}
                </div>
              ) : null}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default ComingSoonSection;
