import React,{ FC, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TICKET_FLOW } from "../../../../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../../../models/tickets";
import { RootState } from "../../../../../../../store";
import { addSelectedTickets, clearFoodItemsAction, getBookedTickets } from "../../../../../../../store/foodAndBeverage/action";
import { setModal } from "../../../../../../../store/ticketBooking/action";
import './prePurchaseList.scss';

interface IPrePurchaseListProps {
  concessions: any
}

const PrePurchaseList: FC<IPrePurchaseListProps> = ({
  concessions
}) => {
  const {     
    countryId,
    prePurchasedTicket,
    hasGL,
    modalType,
    nextModal
  } = useSelector((state: RootState)=>({
      countryId: state.applicationReducer.countryId,
      prePurchasedTicket: state.foodBeverageReducer.prePurchasedTicket,
      hasGL: state.ticketBookingReducer.hasGL,
      modalType: state.ticketBookingReducer.modal.type,
      nextModal: state.ticketBookingReducer.modal.nextModal
  }))

  const dispatch = useDispatch();
  const [concession, setConcession] = useState<any>([]);

  useEffect(() => {
    if (concessions) {
      setConcession(concessions);
    }
  }, [concessions]);

  const editGL = (data: any, index: any) => {
    const selectedData = JSON.parse(JSON.stringify(data));
    selectedData.selectedItemIndex = index;
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][MODAL_TYPE.EDIT_FUTURE_GL];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
        data: selectedData,
      })
    );
  }


  return (
    <div className="col-12 col-md-12 p-0">
      <ul className="list_wrap">
        {
           concession.length > 0 ? concession?.map((foodItems: any, index: any) => {
            return (
            <li>
              {
                foodItems ? 
                <div className="media_wrap">
                  <div className="media_content w-100">
                    <div className="thumb_img">
                      <img alt="Food Image" />
                    </div>
                    <div className="list_details" style={{width: "75%;"}}>
                      <p className="title">{foodItems.Description}</p>
                      <p className="item_list">Quantity: {foodItems.Quantity}</p>
                      <p className="item_list">{foodItems.modifierItem}</p>
                      <div className="divider my-2">
                        <div className="item_list my-1">
                          {foodItems.Delivery === 'Self Collection'?'Self collect from counter':'Deliver to Seat '+foodItems?.deliveryInfo?.SeatNumber+' - '+ foodItems?.deliveryInfo?.Comment +' of movie'}
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                     hasGL ?
                     <div className="edit_btn" onClick={()=>editGL(foodItems, index)}>&nbsp;</div>
                     : null
                  }    
                </div>
                : null
              }              
            </li>
            )
          }): null
        }        
      </ul>
  </div>
  );
};

export default PrePurchaseList;
