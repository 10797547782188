import { FC, useEffect, useMemo, useState } from "react";
import "./membershipRenewal.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { purchaseHistoryApi } from "../../../../../services/auth";
import { ITransactionHistory } from "../../../../../models/auth";
import { COUNTRY } from "../../../../../constants/app";
import moment from "moment";
import { memberValidationWatcher } from "../../../../../store/auth/action";

interface IMembershipRenewalProps {
  onClose: () => void;
  onRenewal: () => void;
}

const MembershipRenewal: FC<IMembershipRenewalProps> = ({
  onClose,
  onRenewal,
}) => {
  const { userDetails, memberDetail, countryId } = useSelector(
    (state: RootState) => ({
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
      countryId: state.applicationReducer.countryId,
    })
  );

  const dispatch = useDispatch();
  const [purchaseHistory, setPurchaseHistory] = useState<ITransactionHistory[]>(
    []
  );

  const getPurchaseHistory = async (member: any) => {
    const payload = {
      countryId: countryId,
      MemberRefId: member?.LoyaltyMember?.MemberId,
      cardNumber: member?.LoyaltyMember?.CardNumber,
      UserSessionId: member?.LoyaltyMember?.CardNumber,
    };
    const {
      status,
      data: { data, statusCode },
    } = (await purchaseHistoryApi(payload)) as any;
    if (status === 200 && statusCode === 200 && data) {
      if (
        data.result &&
        data.result.MemberTransactions &&
        data.result.MemberTransactions.length > 0
      ) {
        setPurchaseHistory(data.result.MemberTransactions);
      }
    }
  };

  const points = useMemo(() => {
    return memberDetail?.LoyaltyMember?.BalanceList &&
      memberDetail?.LoyaltyMember?.BalanceList.length > 0
      ? memberDetail?.LoyaltyMember?.BalanceList.reduce(
          (total: number, v: any) => {
            total = total + Number(v.PointsRemaining);
            return total;
          },
          0
        )
      : 0;
  }, [memberDetail]);

  const redemptionRate = useMemo(() => {
    return memberDetail?.LoyaltyMember?.BalanceList &&
      memberDetail?.LoyaltyMember?.BalanceList.length > 0
      ? memberDetail?.LoyaltyMember?.BalanceList.reduce(
          (total: number, v: any) => {
            total = total + Number(v.RedemptionRate);
            return total;
          },
          0
        )
      : 0;
  }, [memberDetail]);

  const expiryDate = useMemo(() => {
    return memberDetail?.LoyaltyMember
      ? moment(memberDetail?.LoyaltyMember.ExpiryDate).format("DD-MM-YYYY")
      : "";
  }, [memberDetail]);

  const expireDays = useMemo(() => {
    if (memberDetail?.LoyaltyMember.ExpiryDate) {
      const a = moment();
      const b = moment(
        moment(memberDetail?.LoyaltyMember.ExpiryDate).format("YYYY-MM-DD")
      );
      return b.diff(a, "days");
    }else{
      return 0;
    }
  }, [memberDetail]);



  useEffect(() => {
    if (userDetails) {
      const payload = {
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userDetails?.result?.cardNumber,
        cardNumber: userDetails?.result?.cardNumber,
        countryId: countryId,
      };

      dispatch(
        memberValidationWatcher(
          payload,
          (res: any) => {
            getPurchaseHistory(res);
          },
          (err: any) => {}
        )
      );
    }
  }, [userDetails]);

  return (
    <div className="membership-wrapper">
      <div className="membership-details">
        <div>
          <label>Membership Number</label>
          <div className="membership-number">
            {memberDetail?.LoyaltyMember?.CardNumber}
          </div>
        </div>
        <div className="points-available">
          <p>POINTS AVAILABLE</p>
          <h3>{points}</h3>
        </div>
        <div className="reward-details">
          <p className="rewards">
            Reward: <span>${redemptionRate}</span>
          </p>
          {expiryDate ? (
            <p className="expires">
              Expires: <span>{expiryDate}</span>
            </p>
          ) : null}
        </div>
      </div>
      {/* {expireDays && expireDays < 20?<> */}
      <hr className="ruler" />
    <div className="renew-membership">
        <p>
          Your loyalty membership expires in {expireDays} days. Extend your membership for
          another year.
        </p>
        <button onClick={onRenewal}>RENEW MEMBERSHIP</button>
      </div>
      {/* </>: null} */}
      {/* <hr className="ruler" /> */}
      {/* <div className="points-for-rewards">
                 <div className="rewards">
                    <p>Start earning points for rewards</p>
                    <div className="reward-items">
                        <img src="/images/popcorn_small.svg"/>
                        <label className="grey">FREE Small Popcorn</label>
                    </div>
                    <div className="reward-items">
                        <img src="/images/fastfood_highlighted.svg"/>
                        <label className="grey">FREE Extra Value Combo</label>
                    </div>
                    <div className="reward-items">
                        <img src="/images/ticket_highlighted.svg"/>
                        <label className="black">FREE Admission</label>
                    </div>
                    <div className="reward-items">
                        <img src="/images/popcorn-large.svg"/>
                        <label>FREE Small Popcorn</label>
                    </div>
                    <div className="reward-items">
                        <img src="/images/fastfood.svg"/>
                        <label>FREE Extra Value Combo</label>
                    </div>
                    <div className="reward-items">
                        <img src="/images/ticket.svg"/>
                        <label>FREE Admission</label>
                    </div>
                 </div>
                 <div className="progressBar">
                    <ProgressBar
                    completed={50}
                    className="wrapper"
                    bgColor= "#69B041"
                    isLabelVisible={false}
                    height="8px"
                    width="670px"  
                    ariaValuemin = {0}
                    ariaValuemax = {300}
                    borderRadius="0px"
                    />
                    <div className="point-scale">
                        <div>0</div>
                        <div>50</div>
                        <div>100</div>
                        <div>150</div>
                        <div>200</div>
                        <div>250</div>
                        <div>300</div>
                    </div>
                    
                </div>
            </div> */}

      <hr className="ruler" />
      <div className="col-md-12">
        <div className="purchase-history-card section_divided">
          <div className="title">History</div>
          <div className="table-responsive">
            <table
              className="table table-borderless table-hover table-sm"
              aria-describedby="Purchase History"
            >
              <thead>
                <tr>
                  <th scope="col">DATE</th>
                  <th scope="col">LOCATION</th>
                  <th scope="col">POINTS</th>
                </tr>
              </thead>
              <tbody>
                {purchaseHistory.map((item: any, index: number) => (
                  <tr>
                    <td scope="row">
                      {moment(
                        new Date(parseFloat(item.TransactionDate.substr(6)))
                      ).format("DD MMM YYYY")}
                    </td>
                    <td>{item.CinemaName}</td>
                    <td>{item.PointsEarned}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {purchaseHistory.length === 0 ? (
            <div>
              <p className="text-center text-danger h6">No data available</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="return-button">
        <button onClick={onClose}>RETURN TO HOME PAGE</button>
      </div>
    </div>
  );
};

export default MembershipRenewal;
