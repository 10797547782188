import React, { FC, useEffect, useState } from "react";
import HtmlRender from "../../../components/UI/HtmlRender";
import Slider from "../../../components/UI/Slider";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../constants/url";
import "./custompage.scss";
import { useParams } from "react-router";
import { ICustomContent, ICustomPage } from "../../../models/sidemenu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getSlidesWatcher } from "../../../store/application/action";
import { pageContentApi } from "../../../services/sideMenus";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/path";
import { downloadFile } from "../../../utils/helper";

interface ICustomPageProps {}

const CustomPage: FC<ICustomPageProps> = () => {
  const { page } = useParams();

  const { countryId, currentCinema, slides } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      slides: state.applicationReducer.slides,
    })
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState<ICustomPage | null>(null);

  const getPageContent = async () => {
    const {
      data: { data, status },
    } = await pageContentApi({
      countryId,
      cinemaId: currentCinema?.slug,
      pageName: `/${page}`,
      flag: "customPage",
    });
    if (status && data && data.length > 0) {
      setContent(data[0]);
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: data[0].name,
          location: currentCinema.slug,
        })
      );
    } else {
      navigate(`/${currentCinema?.alias}/${ROUTES.PAGE_NOT_FOUND}`);
    }
  };

  useEffect(() => {
    if (countryId && page && currentCinema) {
      getPageContent();
    }
  }, [countryId, page, currentCinema]);

  const openLink = (link: string) => {
    if (link.includes("https://")) {
      window.open(link, "_blank");
    } else {
      navigate(`/${currentCinema?.alias}${link}`);
    }
  };

  const download = (data: any) => {
      if(data.File_Id){
        downloadFile(`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${data.File_Id}.${data.extension}`);
      }
  };

  const RegularContainer: FC<any> = ({ data, index }) => {
    return (
      <div
        className={`pre-show`}
        style={{
          backgroundImage: `${
            index % 2 == 0
              ? "linear-gradient(90deg,rgba(0, 0, 0, 0.4) 10%,rgba(0, 0, 0, 1) 99.05%)"
              : ""
          }, url(${
            URLS.CLOUDFRONT_URL +
            "/" +
            IMAGE_URL_CONFIG.IMAGE_PATH +
            "/" +
            data.Tile_Image +
            IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT
          })`,
        }}
      >
        <HtmlRender content={data.Tile_Title} {...{ className: "title" }} />
        <HtmlRender
          content={data.Tile_Description}
          {...{ className: "mt-3 mb-0" }}
        />
        {data.Show_Buttons === 1 ? (
          <div className="d-flex justify-content-center flex-wrap">
            {data.buttons.map((v: any) => {
              return v.Button_Type === "file" ? (
                <a
                  className="btn white-btn my-3 mx-3"
                  onClick={() =>download(v)}
                >
                  {v.Button_Text}
                </a>
              ) : (
                <button
                  className="btn white-btn my-3 mx-3"
                  onClick={() =>  openLink(v.Button_Link_URL)}
                >
                  {v.Button_Text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const ImageContainer: FC<any> = ({ data, index }) => {
    return (
      <div
        className={`pre-show`}
        style={{
          backgroundImage: `${
            index % 2 == 0
              ? "linear-gradient(90deg,rgba(0, 0, 0, 0.4) 10%,rgba(0, 0, 0, 1) 99.05%)"
              : ""
          }, url(${
            URLS.CLOUDFRONT_URL +
            "/" +
            IMAGE_URL_CONFIG.IMAGE_PATH +
            "/" +
            data.Tile_Image +
            IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT
          })`,
        }}
      >
             {data.Show_Buttons === 1 ? (
          <div className="d-flex justify-content-center flex-wrap">
            {data.buttons.map((v: any) => {
              return data.Button_Type === "file" ? (
                <a
                  href="javascript:void(0)"
                  className="btn white-btn my-3 mx-3"
                  onClick={() =>download(v)}
                >
                  {v.Button_Text}
                </a>
              ) : (
                <button
                  className="btn white-btn my-3 mx-3"
                  onClick={() => openLink(v.Button_Link_URL) }
                >
                  {v.Button_Text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const CenterContainer: FC<any> = ({ data, index }) => {
    return (
      <div className="list_thumb ng-star-inserted">
        <div className="thumb_content_wrap">
          <div className="thumb_content">
            <div className="thumb_title">
              <HtmlRender
                content={data.Tile_Title}
                {...{ className: "title" }}
              />
            </div>
            <div className="description">
              <HtmlRender
                content={data.Tile_Description}
                {...{ className: "mt-3 mb-0" }}
              />
            </div>
            {data.Show_Buttons === 1 ? (
          <div className="d-flex justify-content-center flex-wrap">
            {data.buttons.map((v: any) => {
              return data.Button_Type === "file" ? (
                <a
                  href="javascript:void(0)"
                  className="btn white-btn my-3 mx-3"
                  onClick={() =>  download(v)}
                >
                  {v.Button_Text}
                </a>
              ) : (
                <button
                  className="btn white-btn my-3 mx-3"
                  onClick={() =>openLink(v.Button_Link_URL)}
                >
                  {v.Button_Text}
                </button>
              );
            })}
          </div>
        ) : null}
          </div>
        </div>
        <div className="thumb_image">
          <img
            src={`${
              URLS.CLOUDFRONT_URL +
              "/" +
              IMAGE_URL_CONFIG.IMAGE_PATH +
              "/" +
              data.Tile_Image +
              IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT
            }`}
          />
        </div>
      </div>
    );
  };

  return content ? (
    <div className="custom-content-wrapper">
      <div className="slider-filter">
        <Slider className="banner-slider-us" data={slides} />
      </div>
      <div className="custom-page-container">
        <div className="custom-page-header">
          <h3>{content.headerTitle}</h3>
          <div className="content-desc"><HtmlRender content={content?.description} /></div>
        </div>
      </div>
      {content.content && content.content.length > 0 ? (
        <div className="custom-page-section">
          {content.content.map((v: any, i: number) =>
            v.Tile_Style === "regular" ? (
              <RegularContainer data={v} index={i} />
            ) : v.Tile_Style === "image" ? (
              <ImageContainer data={v} index={i} />
            ) : (
              <CenterContainer data={v} index={i} />
            )
          )}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default CustomPage;
