import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { useParams } from "react-router";
import { userConfirmationApi } from "../../../../services/auth";
import { RootState } from "../../../../store";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { BRANDS } from "../../../../constants/app";
import { ROUTES } from "../../../../constants/path";

interface IConfirmUserProps {}

const ConfirmUser: FC<IConfirmUserProps> = () => {
  const { countryId, data, brandId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    brandId: state.applicationReducer.brandId,
    data: state.ticketBookingReducer.modal.data,
  }));
  const { code } = useParams();
  const navigate = useNavigate()

  const userConfirmation = async () => {
    const {
      data: { data },
    } = await userConfirmationApi({ code: code });
    if (data && data.status == true) {
      toast.success("Email Verified successfully.");
    } else {
      toast.error("Given link expired.");
    }
    setTimeout(()=>{
      navigate(brandId === BRANDS.US?`/${ROUTES.HOME}/${ROUTES.MOVIES}`:`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    },1000)
   
  };

  useEffect(() => {
    if (code && countryId) {
      userConfirmation();
    }
  }, [code, countryId]);

  return null;
};

export default ConfirmUser;
