import React, { FC, useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { calTotal } from "../../../../../utils/giftshop";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../../utils/validatorSchema";
import { sendMailApi } from "../../../../../services/payment";
import { toast } from "react-toastify";
import { ICartData } from "../../../../../store/giftshop/type";
import { GiftType } from "../../../../../models/giftcard";
import moment from "moment";
import './confirmation.scss'
import { IMAGE_URL_CONFIG, URLS } from "../../../../../constants/url";
import Barcode from "react-barcode";
import TextInput from "../../../../../components/UI/TextInput";

export interface GiftshopConfirmationProps {
    onCloseModal:()=>void
}

const GiftshopConfirmation: FC<GiftshopConfirmationProps> = ({onCloseModal}) => {
  const { countryId, cart, bookingId, orderResponse } =
    useSelector((state: RootState) => ({
      cart: state.giftCardReducer.cart,
      bookingId: state.giftCardReducer.bookingId,
      orderResponse: state.giftCardReducer.orderResponse,
      countryId: state.applicationReducer.countryId,
    }));

  const [cartData, setCartData] = useState<{
    deliveryFee: number;
    deliveryQty: number;
    total: number;
  }>({ deliveryFee: 0, deliveryQty: 0, total: 0 });

  const [isMailSent, setIsMailSent] = useState(false);

  useEffect(() => {
    if (cart && countryId) {
      setCartData(calTotal(cart, countryId));
    }
  }, [cart, countryId]);


  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("sendReceipt"),
      enableReinitialize: true,
      initialValues: {
        email: "",
      },
      onSubmit: async (values) => {
        const payload = {
          id: orderResponse?.userSessionId!,
          countryId: countryId,
          templateName: "Giftcard order confirmation",
          email: values.email,
        };
        const { status, error }: any = await sendMailApi(payload);
        if (status) {
          setIsMailSent(true);
          toast.success("Mail sent successfully");
        } else {
          setIsMailSent(false);
          toast.error(error || "Something went wrong");
        }
      },
    });

  return (
    <div>
      <div className="modal-giftcard-thankspg mx-3">
        <div className="modal-purchase-code">
          <div className="purchase-code-left">
            <div>
            <p className="m-0">PURCHASE REFERENCE</p>
            <h5>{bookingId}</h5>
            </div>
            <div className="bar_code">
                <Barcode
                  width={2}
                  height={80}
                  displayValue={false}
                  value={bookingId }
                />
              </div>
          </div>
          <div className="purchase-code-right">
            <img src="purchaseCode" alt="" />
          </div>
        </div>
        <div className="mx-3">
        <div className="purchase-details-main">
          {cart &&
            cart.length > 0 &&
            cart.map((item: ICartData) => (
              <div className="purchase-details-sec">
                <h3>{item.description}</h3>
                {item.giftcardType == GiftType.ecard ? (
                  <p>
                    <span>Gift Type:</span> E-Gift Card
                  </p>
                ) : item.giftcardType == GiftType.physical ? (
                  <p>
                    <span>Gift Type:</span> Physical Gift Card
                  </p>
                ) : item.giftcardType == GiftType.exp ? (
                  <p>
                    <span>Gift Type:</span> Experience
                  </p>
                ) : null}
                <p>
                  <span>Gift To:</span> {item.recipientName}
                </p>
                {item.giftcardType == GiftType.ecard ? (
                  <p>
                    <span>Deliver To:</span> {item.recipientemail}
                  </p>
                ) : null}
                {item.giftcardType !== GiftType.ecard ? (
                  <p>
                    <span>Deliver To: </span>
                    {item.address1}
                    {item.address2},{item.city},{item.state},{item.postCode}
                  </p>
                ) : null}
                {item.giftcardType == GiftType.ecard ? (
                  <p>
                    <span>Delivery Date: </span>
                    {moment(item.deliveryDate).format("DD MMM YYYY")}
                  </p>
                ) : null}
                <p>
                  <span>From:</span> {item.senderName}
                </p>
                <p>
                  <span>Message:</span> {item.giftMessage}
                </p>
                <p>
                  <span>Quantity:</span> {item.quantity}
                </p>
                <p>
                  <span>Item Total:</span> ${item.itemTotal.toFixed(2)}
                </p>
              </div>
            ))}
        </div>
        {cartData.deliveryFee ? (
          <div className="cart-detail-delivery">
            <h4>${cartData.deliveryFee.toFixed(2)} Delivery Fee</h4>
            <ul>
              {cart.map((v: ICartData) => {
               return v.giftcardType !== GiftType.ecard ? (
                  <li>
                    <p>
                      Deliver To:{" "}
                      <span>
                        {v.address1}
                        {v.address2}
                        {v.city},{" "}{v.state},{" "}{v.postCode}
                      </span>
                    </p>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        ) : null}
        <div className="delivery_fee_det">
          <label>TOTAL PURCHASE</label>
          <h3>${cartData.total.toFixed(2)}</h3>
        </div>
        </div>
        <div className="send_sec_main">
          {isMailSent ? (
            <div className="send_sec">
              <h2>
                <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}success-Selected.png`} alt="" /> Receipt Sent!
              </h2>
              <p>
                <span>Emailed To</span> {values.email}
              </p>
              <div className="btn-section">
                <a
                  href="javascript:void(0)"
                  className="btn light_gray_btn"
                  onClick={() => handleSubmit()}
                >
                  Resend
                </a>
              </div>
            </div>
          ) : (
            <div className="sent_receipt">
              <h3>Send digital confirmation to your email</h3>
              <div className="sent_receipt_maingrp">
                <div className="form-group">
                  <label>Email To</label>
                  <div className="email-parent-close">
                  <TextInput
                  value={values.email}
                  field={"email"}
                  handleChange={handleChange}
                  touched={touched.email!}
                  error={errors.email}
                />
                    
                    <img src="closeIcon" alt="" />
                  </div>
                </div>
                <div className="btn-section m-0 ">
                  <button
                    type="button"
                    className="btn black_btn"
                    onClick={() => handleSubmit()}
                  >
                    <img src={`${URLS.CLOUDFRONT_URL}/assets/images/envelope-white.png`} alt="" /> Send receipt
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row btn-section thanks_modal_btnsec">
        <div className="col-md-12 movie_footer_btn_el ml-auto">
          <div className="movie_button_wrap d-flex justify-content-end">
            <button
              type="button"
              className="btn black_btn px-3"
              onClick={onCloseModal}
            >
              RETURN TO GIFT SHOP HOME
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftshopConfirmation;
