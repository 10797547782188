import { FC, useEffect, useState } from "react";
import "./signupBanner.scss";
import {
  setModal,
  toggleTicketModal,
} from "../../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import {
  showLogin,
  updateMembbershipPurchase,
} from "../../../../store/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { ILoyaltyPackage } from "../../../../models/auth";
import { getMembershipPackageApi } from "../../../../services/auth";
import { RootState } from "../../../../store";
import { COUNTRY } from "../../../../constants/app";
import HelmetTitle from "../../../Helmet";


interface ISignupBannerProps {
  onSignup: () => void;
  onCancel: () => void;
}

const SignupBanner: FC<ISignupBannerProps> = ({ onSignup, onCancel }) => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId as COUNTRY,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const dispatch = useDispatch();
  const [packages, setPackages] = useState<ILoyaltyPackage[]>([]);

  const getPackageContent = async () => {
    const {
      data: {
        data: { loyaltyMembershipPackages },
        status,
      },
    } = await getMembershipPackageApi({
      countryId,
      // cinemaId: currentCinema?.slug,
      cinemaId: "0000000001"
    });

    if (loyaltyMembershipPackages && loyaltyMembershipPackages.length > 0) {
      setPackages(loyaltyMembershipPackages);
    }
  };

  useEffect(() => {
    if (countryId && currentCinema) {
      getPackageContent();
    }
  }, [countryId, currentCinema]);

  const onSignupGold = () => {
    const loyaltyPackage = packages.find((v: ILoyaltyPackage) => {
      return v.activationData && Number(v.levelId) === 4;
    });
    console.log(loyaltyPackage, loyaltyPackage)
    dispatch(
      updateMembbershipPurchase({
        membershipType: "new",
        clubId: loyaltyPackage ? loyaltyPackage.clubId : 0,
        loyaltyPackage: loyaltyPackage ? loyaltyPackage.activationData : null,
      })
    );
    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
          MODAL_TYPE.MEMBERSHIP_AUTH
        ],
        type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
      })
    );
    dispatch(toggleTicketModal(true));
    dispatch(showLogin(false));
  };

  return (
    <div>
      <HelmetTitle title="Sign-up" description="Sign-up"/>
      <div className="gold_club_signup">
        <div className="gold_module">
          <div className="gold_title">ANGELIKA REWARDS GOLD</div>
          <div className="fee_details">$20 Annual Membership</div>
          <div>
            <ul>
              <li className="bold_text">• 20% off Tickets*</li>
              <li className="bold_text">• 10% off Food and Drinks</li>
              <li className="bold_text">
                • Collect 20 Points per dollar spent
              </li>
            </ul>
            <div className="wrapper">
              <ul>
                <li>• Redeem your points online or at the cinema</li>
                <li className="text-center">
                  • Access exclusive member only competitions and screenings
                </li>
                <li>• Stay up to date with our weekly e-newsletter</li>
              </ul>
            </div>
          </div>
          <button className="signup-black_btn" onClick={onSignupGold}>
            Sign up
          </button>
          <div className="my-2">*20% off the Adult ticket price</div>
        </div>

        <div className="club_module">
          <div className="club_title">ANGELIKA REWARDS CLUB</div>
          <div className="fee_details">Free Membership</div>
          <div>
            <ul>
              <li className="bold_text">
                • Collect 10 Points per dollar spent
              </li>
            </ul>
            <div className="wrapper">
              <ul>
                <li>• Redeem your points online or at the cinema</li>
                <li className="text-center">
                  • Access exclusive member only competitions and screenings
                </li>
                <li>• Stay up to date with our weekly e-newsletter</li>
              </ul>
            </div>
          </div>
          <button className="signup-black_btn my-2" onClick={onSignup}>
            Sign up
          </button>
        </div>

        <button type="button" className="gray_btn mt-4" onClick={onCancel}>
          CANCEL
        </button>
      </div>
    </div>
  );
};

export default SignupBanner;
