import { takeLatest, call, put } from 'redux-saga/effects';
import { ACTIONS } from './type';
import { IAction, ISlide } from '../../models/common';
import { getCinemasApi, getPromoApi, getSettingsApi, getSlidesApi } from '../../services/application';
import {
    getCinemasError, getCinemasSuccess, getPromoAdError,
    getPromoAdSuccess, getSettingsError, getSettingsSuccess, getSlidesSuccess, getSlidesError, getMenuFailure, getMenuSuccess
} from './action';
import { formatCinemaList } from '../../utils/formatter';
import { pageContentApi } from '../../services/sideMenus';
import { IMainNav } from '../../models/sidemenu';
import moment from 'moment';



function* getSettings(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getSettingsApi, payload);
    if (status) {
        yield put(getSettingsSuccess(response.data.data.settings))
    } else {
        yield put(getSettingsError(error))
    }
}

function* getCinemas(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getCinemasApi, payload);
    if (status) {
        yield put(getCinemasSuccess(formatCinemaList(response.data, payload.countryId)))
    } else {
        yield put(getCinemasError(error))
    }
}

function* getSlides(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getSlidesApi, payload);
    if (status) {
        const { data } = response
        const slides: ISlide[] = [];
        data.forEach((v: ISlide) => {
            if (
                v.postLaterFlag == 1 && v.autoArchiveFlag === 1
            ) {
                if (moment() >= moment(v.postDateTime) &&
                    moment() <= moment(v.archiveDateTime)) {
                    slides.push(v);
                }
            } else if (
                (v.postLaterFlag == 1 &&
                    moment() >= moment(v.postDateTime)) ||
                (v.autoArchiveFlag === 1 &&
                    moment() <= moment(v.archiveDateTime))
            ) {
                slides.push(v);
            } else if (!v.postLaterFlag && !v.autoArchiveFlag) {
                slides.push(v);
            }

        })
        yield put(getSlidesSuccess(slides))
    } else {
        yield put(getSlidesError(error))
    }
}

function* getPromoAd(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getPromoApi, payload);
    if (status) {
        yield put(getPromoAdSuccess(response.data))
    } else {
        yield put(getPromoAdError(error))
    }
}

function* getMenus(action: IAction) {
    const { payload } = action;
    const { data: { data, status } } = yield call(pageContentApi, payload) as any;
    if (status && data) {
        const { mainMenu, footerMenu } = data;
        yield put(getMenuSuccess({
            header: mainMenu.sort((a: IMainNav, b: IMainNav) =>
                a.View_order > b.View_order ? 1 : -1
            ),
            footer: footerMenu
        }));
    } else {
        yield put(getMenuFailure(data));
    }
}

export function* applicationWatcher() {
    yield takeLatest(ACTIONS.GET_SETTINGS_WATCHER, getSettings);
    yield takeLatest(ACTIONS.GET_CINEMAS_WATCHER, getCinemas);
    yield takeLatest(ACTIONS.GET_SLIDES_WATCHER, getSlides);
    yield takeLatest(ACTIONS.GET_PROMO_AD_WATCHER, getPromoAd);
    yield takeLatest(ACTIONS.MENU_WATCHER, getMenus);
}