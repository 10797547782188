import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "../../../components/UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import Header from "./Header";
import {
  cancelTicketAction,
  setModal,
  toggleTicketModal,
} from "../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import NoOfSeats from "./Ticketing/NoOfseats";
import SeatSelection from "./Ticketing/SeatSelection";
import FoodAndBeverage from "./Ticketing/Concession/FoodAndBeverage";
import OrderSummary from "./Ticketing/OrderSummary";
import Auth from "./Ticketing/Auth";
import Payment from "./Ticketing/Payment";
import TicketConfirmation from "./Ticketing/TicketConfirmation";
import TicketError from "./Ticketing/TicketError";
import { ROUTES } from "../../../constants/path";
import MainFbPage from "./Ticketing/Concession/Main";
import { clearFoodItemsAction } from "../../../store/foodAndBeverage/action";
import FutureFb from "./Ticketing/Concession/FutureFb";
import MovieSessions from "./Ticketing/Concession/FutureFb/MovieSessions";
import PrePurchase from "./Ticketing/Concession/FutureFb/PrePurchasedOrders";
import { toast } from "react-toastify";
import { COUNTRY } from "../../../constants/app";
import SocialSharing from "./SocialShare";
import Refund from "./Refund";
import RefundConfirmation from "./Refund/RefundConfirmation";
import GiftshopError from "./GiftShop/GiftshopError";
import GiftshopConfirmation from "./GiftShop/GiftshopConfirmation";
import GiftshopPayment from "./GiftShop/GiftShopPayment";
import { clearOrdersAction } from "../../../store/giftshop/action";
import MilitaryConfirmation from "./MilitaryConfirmation";
import KitchenClose from "./KitchenClosed";
import MembershipAuth from "./Membership/Auth";
import MembershipPayment from "./Membership/Payment";
import MembershipConfirmation from "./Membership/Confirmation";
import MembershipError from "./Membership/Error";

interface ITicketBookingProps {}

const TicketBooking: FC<ITicketBookingProps> = () => {
  const {
    countryId,
    openModal,
    films,
    modal,
    currentCinema,
    seatLayout,
    ticketType,
    ticketDetails,
    ticketLessFb,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
    openModal: state.ticketBookingReducer.openModal,
    films: state.ticketBookingReducer.films,
    modal: state.ticketBookingReducer.modal,
    seatLayout: state.ticketBookingReducer.seatLayout,
    ticketType: state.ticketBookingReducer.ticketType,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stopTimer, setStopTimer] = useState(false);

  /*   Ticketing flow type checking
   * No of seats - with & without F&B
   * Seat selection - with & without F&B
   * Multi ticketing type  - with & without F&B
   */
  const checkModalType = () => {
    if (
      currentCinema.multiple_ticket_type === 1 &&
      seatLayout.length > 0 &&
      Number(films?.reservedSeating) === 1
      // ticketType.length > 1
    ) {
      dispatch(
        setModal({
          ...TICKET_FLOW[
            currentCinema.food_beverage === 1
              ? TICKET_FLOW_TYPES.MULTI_TICKETING_FB
              : TICKET_FLOW_TYPES.MULTI_TICKETING
          ][MODAL_TYPE.NO_OF_SEAT],
          type:
            currentCinema.food_beverage === 1
              ? TICKET_FLOW_TYPES.MULTI_TICKETING_FB
              : TICKET_FLOW_TYPES.MULTI_TICKETING,
        })
      );
    } else if (
      Number(films?.reservedSeating) === 1 &&
      (currentCinema.multiple_ticket_type !== 1 ||
        (currentCinema.multiple_ticket_type === 1 && ticketType.length <= 1)) &&
      seatLayout.length > 0
    ) {
      dispatch(
        setModal({
          ...TICKET_FLOW[
            currentCinema.food_beverage === 1
              ? TICKET_FLOW_TYPES.SEAT_SELECTION_FB
              : TICKET_FLOW_TYPES.SEAT_SELECTION
          ][MODAL_TYPE.SEAT_SELECTION],
          type:
            currentCinema.food_beverage === 1
              ? TICKET_FLOW_TYPES.SEAT_SELECTION_FB
              : TICKET_FLOW_TYPES.SEAT_SELECTION,
        })
      );
    } else {
      dispatch(
        setModal({
          ...TICKET_FLOW[
            currentCinema.food_beverage === 1
              ? TICKET_FLOW_TYPES.NO_OF_SEAT_FB
              : TICKET_FLOW_TYPES.NO_OF_SEAT
          ][MODAL_TYPE.NO_OF_SEAT],
          type:
            currentCinema.food_beverage === 1
              ? TICKET_FLOW_TYPES.NO_OF_SEAT_FB
              : TICKET_FLOW_TYPES.NO_OF_SEAT,
        })
      );
    }
  };

  useEffect(() => {
    if (films && currentCinema && !ticketLessFb) {
      checkModalType();
    }
  }, [films]);

  const cancelTicket = () => {
    if (ticketDetails && ticketDetails.userSessionId) {
      dispatch(
        cancelTicketAction({
          countryId: countryId,
          requestType: "cancelOrder",
          userSessionId: ticketDetails.userSessionId,
        })
      );
    }
  };

  const onCancel = (error?: any) => {
    cancelTicket();
    dispatch(toggleTicketModal(false));
    if (countryId === COUNTRY.US) {
      navigate(`/${currentCinema.alias}/${ROUTES.MOVIES}`);
    } else {
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    }
    if (error) {
      toast.error(error);
    }
    return;
  };

  const onCloseModal = () => {
    if (modal.type === TICKET_FLOW_TYPES.GIFTSHOP) {
      dispatch(toggleTicketModal(false));
      dispatch(clearOrdersAction());
      if (countryId === COUNTRY.US) {
        navigate(`/${currentCinema.alias}/${ROUTES.GIFTSHOP}`);
      } else {
        navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`);
      }
    } 
    if (modal.type === TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE || modal.type === TICKET_FLOW_TYPES.MEMBERSHIP_FREE_PURCHASE) {
      dispatch(toggleTicketModal(false));
    } else {
      cancelTicket();
      dispatch(clearFoodItemsAction());
      dispatch(toggleTicketModal(false));
      if (countryId === COUNTRY.US) {
        navigate(`/${currentCinema.alias}/${ROUTES.MOVIES}`);
      } else {
        navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
      }
    }
  };

  const closeModal = () => {
    dispatch(toggleTicketModal(false));
  };
 
  const Content = useMemo(() => {
    setStopTimer(false);
    switch (modal?.modal) {
      case MODAL_TYPE.NO_OF_SEAT:
        return <NoOfSeats onCancel={onCancel} />;
      case MODAL_TYPE.SEAT_SELECTION:
        return <SeatSelection onCancel={onCancel} />;
      case MODAL_TYPE.FOOD_AND_BEVERAGE:
        return <FoodAndBeverage onCancel={onCancel} />;
      case MODAL_TYPE.ORDER_SUMMARY:
        return <OrderSummary />;
      case MODAL_TYPE.AUTH:
        return <Auth />;
      case MODAL_TYPE.PAYMENT:
        return <Payment onCancel={onCancel}/>;
      case MODAL_TYPE.TICKET_CONFIRMATION:
        setStopTimer(true);
        return <TicketConfirmation onCloseModal={onCloseModal} />;
      case MODAL_TYPE.TICKET_ERROR:
        setStopTimer(true);
        return <TicketError onCloseModal={onCloseModal} />;
      case MODAL_TYPE.FOOD_AND_BEVERAGE_MAIN:
        return <MainFbPage />;
      case MODAL_TYPE.FOOD_AND_BEVERAGE_FUTURE:
        return <FutureFb />;
      case MODAL_TYPE.MOVIE_LISTING:
        return <MovieSessions />;
      case MODAL_TYPE.PRE_PURCHASE_ORDER:
        return <PrePurchase />;
      case MODAL_TYPE.SOCIAL_SHARE:
        return <SocialSharing onCloseModal={onCloseModal} />;
      case MODAL_TYPE.REFUND:
        return <Refund onCloseModal={onCloseModal} />;
      case MODAL_TYPE.REFUND_CONFIRMATION:
        return <RefundConfirmation onCloseModal={onCloseModal} />;
      case MODAL_TYPE.GIFTSHOP_PAYMENT:
        return <GiftshopPayment />;
      case MODAL_TYPE.GIFTSHOP_CONFIRMATION:
        return <GiftshopConfirmation onCloseModal={onCloseModal} />;
      case MODAL_TYPE.GIFTSHOP_ERROR:
        return <GiftshopError onCloseModal={onCloseModal} />;
      case MODAL_TYPE.MILITARY_MEMBER_CONFIRMATION:
        return <MilitaryConfirmation onCloseModal={onCloseModal} />;
      case MODAL_TYPE.KITCHEN_CLOSED:
        return <KitchenClose onCloseModal={onCloseModal} />;
      case MODAL_TYPE.MEMBERSHIP_AUTH:
        return <MembershipAuth onCloseModal={closeModal} />;
      case MODAL_TYPE.MEMBERSHIP_PAYMENT:
        return <MembershipPayment onCloseModal={closeModal}/>;
      case MODAL_TYPE.MEMBERSHIP_CONFIRMATION:
        return <MembershipConfirmation onCloseModal={closeModal} />;
      case MODAL_TYPE.MEMBERSHIP_ERROR:
        return <MembershipError onCloseModal={closeModal} />;
      default:
        return null;
    }
  }, [modal?.modal]);

  return (
    <Modal
      className="ticket-booking-modal"
      visible={openModal}
      showCloseBtn={false}
    >
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content" id="ticket-booking-modal">
            <Header onCloseModal={onCloseModal} stopTimer={stopTimer} />
            <div className="modal-body">{Content}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TicketBooking;
