import { call, put, takeLatest } from "redux-saga/effects";
import { IAction } from "../../models/common";
import { ACTIONS } from "./type";
import { cancelTicketsApi, getTicketDetailApi } from "../../services/films";
import { cancelTicketSuccess, ticketBookingDetailsError, ticketBookingDetailsSuccess } from "./action";
import { memberValidationSuccess } from "../auth/action";



function* getTicketDetails(action: IAction) {
 
    const { response, error } = yield call(getTicketDetailApi, action.payload);
    if (response) {
        const {films:{data},memberResponse } = response;
        if (data.length > 0) {
            yield put(ticketBookingDetailsSuccess(response));
        } else {
            yield put(ticketBookingDetailsError(error));
        }
        if (memberResponse && memberResponse.status) {
            yield put(memberValidationSuccess(memberResponse.response));
        }
    } else {
        yield put(ticketBookingDetailsError(error));
    }
}

function* canceTickets(action: IAction) {
    const { status, response } = yield call(cancelTicketsApi, action.payload)
    if (status) {
        yield put(cancelTicketSuccess(response));
    }
}

export function* ticketBookingWatcher() {
    yield takeLatest(ACTIONS.GET_TICKET_BOOKING_DETAILS, getTicketDetails);
    yield takeLatest(ACTIONS.CANCEL_TICKET, canceTickets);
}