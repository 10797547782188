import { URLS } from "../constants/url";
import AES from 'crypto-js/aes'
import { enc } from 'crypto-js'
import moment from "moment";

export const downloadFile = (url: string) => {
    const link = document.createElement("a");
    link.setAttribute("target", "_self");
    link.setAttribute("href", url);
    document.body.appendChild(link);
    link.click();
    link.remove();
}


export function ltrim(str: any) {
    if (!str) return str;
    return str.replace(/^\s+/g, "");
}


export const onImgError = (event: any) => {
    event.target.src = URLS.DEFAULT_FOOD_IMAGE;
};

export const yearArray = () => {
    const year = new Array(100).fill(1);
    return year.map((v, i) => {
        return {
            label: new Date().getFullYear() - i,
            value: `${new Date().getFullYear() - i}`
        }
    })
}

export const yearSignupArray = () => {
    const year = new Array(90).fill(1);
    return year.map((v, i) => {
        return {
            label: new Date().getFullYear() - (i + 13),
            value: `${new Date().getFullYear() - (i + 13)}`
        }
    })
}

export const futureYear = () => {
    const year = new Array(21).fill(1);
    return year.map((v, i) => {
        return {
            label: new Date().getFullYear() + i,
            value: `${new Date().getFullYear() + i}`
        }
    })
}

export const dateArray = () => {
    const dayArr = [];
    for (let i = 0; i <= 30; i++) {
        let value = (i + 1) < 10 ? `0${(i + 1)}` : `${(i + 1)}`;
        dayArr.push({
            label: value,
            value: value
        });
    }
    return dayArr;
}

export const monthArray = () => {
    const monthArr = [];
    for (let i = 0; i <= 11; i++) {
        let value = (i + 1) < 10 ? `0${(i + 1)}` : `${(i + 1)}`;
        monthArr.push({
            label: value,
            value: value
        });
    }
    return monthArr;
}

export const decryptData = (ciphertext: string, key: string) => {
    let bytes = AES.decrypt(ciphertext, key);
    let originalText = bytes.toString(enc.Utf8);
    return originalText;
};

export const encryptData = (text: string, key: string) => {
    let ciphertext = AES.encrypt(text, key).toString();
    return ciphertext;
};

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const numberOnly = (e: any) => {
    const { value } = e.target;
    const re = /^[0-9]+$/;
    if (value === "" || re.test(value)) {
        return value;
    }
}

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const shuffleArray = (data: any) => {
    return data.map((value: any) => ({ value, sort: Math.random() }))
        .sort((a: any, b: any) => a.sort - b.sort).slice(0, 5)
        .map((v: any) => v.value)
}

export const enableShowTime = (session: any) => {
    if (session) {
        if (
            session.scheduledFor && session.scheduledFor !== '0000-00-00 00:00:00'
        ) {
            return moment() >= moment(session.scheduledFor, "YYYY-MM-DD hh:mm:ss");
        } else {
            return true;
        }
    }
};
