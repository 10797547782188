import React, { FC, useEffect } from "react";
import { encryptData } from "../../../../utils/helper";
import { useNavigate } from "react-router";
import { AES_KEY } from "../../../../constants/app";

interface IRefundHeaderProps {}

const RefundHeader: FC<IRefundHeaderProps> = () => {

  const navigator = useNavigate();

  const openSeatSwap = ()=>{
    // const encrypt = encryptData(ticketDetails.userSessionId, AES_KEY);
    // navigator(
    //   `/${currentCinema.alias}/seat-swap/${encodeURIComponent(encrypt)}`
    // );
  }

  return (
    <div className="refund-wrapper">
      <div className="col-12 px-2">
        <div className="refund-header">
          Are you sure you want to refund your order?{" "}
        </div>
        <div className="refund-subheader">
          You have up to 2 hours prior to your session start time to refund your
          tickets.
          <br />
          Alternatively, you have up to 30 minutes prior to your session to
          {" "}<span className="ticket-highlight" onClick={openSeatSwap}>Swap Seats.</span>
          <br />
          Please note: Service fees are non-refundable.
        </div>
      </div>
    </div>
  );
};

export default RefundHeader;
