import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store";
import "./header.scss";
import {
  getCinemaWatcher,
  openSliderAction,
  setCinemaAction,
} from "../../../store/application/action";
import { findCinemaObject, formatLocation } from "../../../utils/formatter";
import QuickTickets from "../../../components/UI/Filters/QuickTickets";
import LocationDropDown from "../../../components/UI/DropDown/Location";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/path";
import {
  logoutAction,
  showLogin,
  showUpdateProfileModal,
} from "../../../store/auth/action";
import { logOutApi } from "../../../services/auth";
import { sleep } from "../../../utils/helper";
import UpdateProfile from "../../../components/Menu/AuthMenu/UpdateProfile";
import { IMainNav } from "../../../models/sidemenu";
import { IMAGE_URL_CONFIG } from "../../../constants/url";
import { searchMovie } from "../../../store/films/action";

interface IHeaderProps {}

const Header: FC<IHeaderProps> = () => {
  const {
    menu,
    countryId,
    cinemas,
    currentCinema,
    isLoggedIn,
    quickFilters,
    brandId,
    userDetails,
    memberDetail,
    ticketModal,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    menu: state.applicationReducer.menu,
    brandId: state.applicationReducer.brandId,
    cinemas: state.applicationReducer.cinemas,
    currentCinema: state.applicationReducer.currentCinema,
    isLoggedIn: state.authReducer.isLoggedIn,
    quickFilters: state.filmsReducer.quickFilters,
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
    ticketModal: state.ticketBookingReducer.openModal,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [cinemaList, setCinemaList] = useState([]);
  const [showQuickTickets, setShowQuickTickets] = useState<boolean>(false);
  const [filterClass, setFilterClass] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const resolution = window.innerWidth;
  const isMobile = resolution >= 320 && resolution <= 480;

  useEffect(() => {
    if (countryId) {
      dispatch(getCinemaWatcher({ countryId }));
    }
  }, [countryId]);

  useEffect(() => {
    setShowQuickTickets(false);
  }, [quickFilters]);

  useEffect(() => {
    if (countryId && cinemas && cinemaList.length === 0) {
      setCinemaList(formatLocation(cinemas, true));
    }
  }, [countryId, cinemas]);

  const points = useMemo(() => {
    return memberDetail &&
      memberDetail.LoyaltyMember &&
      memberDetail.LoyaltyMember.BalanceList &&
      memberDetail.LoyaltyMember.BalanceList.length > 0 &&
      memberDetail.LoyaltyMember.BalanceList[0].PointsRemaining
      ? memberDetail.LoyaltyMember.BalanceList[0].PointsRemaining
      : 0;
  }, [memberDetail]);

  const onChangeCinema = (value: string) => {
    if (cinemas) {
      const curcinema = findCinemaObject(cinemas, value);
      dispatch(setCinemaAction(curcinema));
      navigate(`/${curcinema && curcinema.alias ? `${curcinema.alias}/${ROUTES.MOVIES}` : ""}`);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (ticketModal || pathname) {
      setShowQuickTickets(false);
    }
  }, [ticketModal, pathname]);

  const onShowQuickTickets = async () => {
    setFilterClass(!showQuickTickets);
    if (showQuickTickets) {
      await sleep(500);
    }
    setShowQuickTickets(!showQuickTickets);
  };

  const onSignIn = () => {
    if (isLoggedIn) {
      dispatch(showLogin(false));
      dispatch(openSliderAction({ open: true, type: "account" }));
    } else {
      dispatch(showLogin(true));
    }
  };

  const logout = async () => {
    const { status } = await logOutApi();
    if (status === 200) {
      dispatch(logoutAction({}));
    }
  };

  const onClickEdit = () => {
    dispatch(showUpdateProfileModal(true));
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      const {
        target: { value },
      } = event;
      if (value.trim()) {
        dispatch(searchMovie(value.trim()));
        navigate(`/${currentCinema?.alias}/${ROUTES.SEARCH}`);
        setShowSearch(false);
      }
    }
  };

  return (
    <>
      <div className="header-us d-md-none">
        <div className="header-mobile">
          <div className="header-top-section">
            <div className="wrapper" onClick={() => setShowQuickTickets(false)}>
              <div className="sign-in">
                {isLoggedIn ? (
                  <span className="mx-2">Log out</span>
                ) : (
                  <span className="mx-2" onClick={onSignIn}>
                    Sign in
                  </span>
                )}
                <img
                  src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}signin_icon.svg`}
                />
              </div>
              <div>
                <ul className="social-media">
                  <li>
                    <a href={currentCinema?.facebook_url} target="_blank">
                      <img src="/images/facebook_icon.svg" />
                    </a>
                  </li>
                  <li>
                    <img src="/images/instagram_icon.svg" />
                  </li>
                  <li>
                    <a href={currentCinema?.twitter_url} target="_blank">
                      <img src="/images/twitter_icon.svg" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="logo-location">
            <div className="logo-wrapper">
              <div
                className="logo"
                onClick={() =>
                  navigate(`/${currentCinema?.alias}/${ROUTES.MOVIES}`)
                }
              >
                <img src="/images/LOGO_EMBLEM.svg" />
              </div>
              <div>
                <div className="site-name">READING CINEMAS</div>
                <div>
                  {currentCinema ? (
                    <div>
                      <LocationDropDown
                        value={{
                          value: currentCinema ? currentCinema.slug : "",
                          label: currentCinema
                            ? currentCinema.name.toUpperCase()
                            : "",
                        }}
                        options={cinemaList}
                        placeholder="Select Cinema"
                        onChange={onChangeCinema}
                        className="location-dropdown-us"
                        classNamePrefix="location-us"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="navigation">
              <div className="menuToggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>

                <ul className="menu">
                  <a href="javascript:void(0)">
                    <li onClick={() => navigate(`/${currentCinema?.alias}`)}>
                      MOVIES
                    </li>
                  </a>
                  <Link
                    to={`/${currentCinema?.alias}/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`}
                    className={`nav-link`}
                  >
                    GIFT CARDS
                  </Link>
                  <Link
                    to={`/${currentCinema?.alias}/${ROUTES.EAT_AND_DRINK}`}
                    className={`nav-link`}
                  >
                    EAT & DRINK
                  </Link>
                  <a href="javascript:void(0)">
                    <li
                      onClick={() =>
                        navigate(
                          `${currentCinema?.alias}/${ROUTES.REWARDS}`
                        )
                      }
                    >
                      REWARDS
                    </li>
                  </a>
                  <a href="#">
                    <li>GROUPS & EVENTS</li>
                  </a>
                  <ul className="sub-menu">
                    <a href="#">
                      <li>PRIVATE EVENTS & PARTIES</li>
                    </a>
                  </ul>
                  <a href="#">
                    <li>SPECIALS</li>
                  </a>
                  <ul className="sub-menu">
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          `/${ROUTES.SIGNATURE_PROGRAMS.replace(
                            ":cinemaId",
                            currentCinema?.alias
                          )}`
                        )
                      }
                    >
                      <li>SIGNATURE PROGRAMMING</li>
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          `/${currentCinema?.alias}/${ROUTES.MOVIES}/titan-xc`
                        )
                      }
                    >
                      <li>TITAN LUXE</li>
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          `/${currentCinema?.alias}/${ROUTES.SPECIAL_VALUES}`
                        )
                      }
                    >
                      <li>SPECIAL VALUE & PROGRAMS</li>
                    </a>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-us d-none d-md-block">
        <div className="wrapper" onClick={() => setShowQuickTickets(false)}>
          {!showSearch ? (
            <div className="search-icon">
              <div>
                <img
                  src="/images/search.svg"
                  className="pointer"
                  onClick={() => setShowSearch(!showSearch)}
                />
              </div>
            </div>
          ) : (
            <div className="search-field">
              <img
                src="/images/search.svg"
                className="pointer"
                onClick={() => setShowSearch(!showSearch)}
              />
              <input onKeyDown={onSearch} />
            </div>
          )}

          <div className="d-flex align-item-center">
            <div className="logo">
              <Link
                to={`/${currentCinema?.alias}/${ROUTES.MOVIES}`}
                className="d-flex"
              >
                <img src="/images/reading_logo_us.svg" />
                <div className="site-name pointer">READING CINEMAS</div>
              </Link>
            </div>
          </div>
          <div>
            <ul className="social-media">
              <li>
                <a href={currentCinema?.facebook_url} target="_blank">
                  <img
                    onMouseOver={(e) =>
                      (e.currentTarget.src = "/images/Facebook_red.svg")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.src = "/images/facebook_icon.svg")
                    }
                    src="/images/facebook_icon.svg"
                  />
                </a>
              </li>
              <li>
                <a href={currentCinema?.instagramUrl} target="_blank">
                  <img
                    onMouseOver={(e) =>
                      (e.currentTarget.src = "/images/Instagram_red.svg")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.src = "/images/instagram_icon.svg")
                    }
                    src="/images/instagram_icon.svg"
                  />
                </a>
              </li>
              <li>
                <a href={currentCinema?.twitter_url} target="_blank">
                  <img
                    onMouseOver={(e) =>
                      (e.currentTarget.src = "/images/Twitter_red.svg")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.src = "/images/twitter_icon.svg")
                    }
                    src="/images/twitter_icon.svg"
                  />
                </a>
              </li>
              <li>
                <a href={currentCinema?.tiktok} target="_blank">
                  <img
                    onMouseOver={(e) =>
                      (e.currentTarget.src = "/images/TikTok_red.svg")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.src = "/images/tiktok_icon.svg")
                    }
                    src="/images/tiktok_icon.svg"
                  />
                </a>
              </li>
              <li>
                <a href={currentCinema?.youtubeUrl} target="_blank">
                  <img
                    className="youtube"
                    onMouseOver={(e) =>
                      (e.currentTarget.src = "/images/Youtube_red-1.svg")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.src = "/images/youtube_icon.svg")
                    }
                    src="/images/youtube_icon.svg"
                  />
                </a>
              </li>
              <li>
                <a href={`mailto:${currentCinema?.email}`} target="_blank">
                  <img
                    className="email"
                    onMouseOver={(e) =>
                      (e.currentTarget.src = "/images/Mail_red.svg")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.src = "/images/mail_icon.svg")
                    }
                    src="/images/mail_icon.svg"
                  />
                </a>
              </li>
            </ul>
            <div>
              <div className="d-flex justify-content-end">
                <div className="sign-in">
                  {isLoggedIn ? (
                    <div>
                      <ul>
                        <li className="user-name">{userDetails?.firstName}</li>
                        <li>REWARDS ({points})</li>
                        <li onClick={onClickEdit}>VIEW PROFILE</li>
                        <li onClick={logout}>LOG OUT</li>
                      </ul>
                    </div>
                  ) : (
                    <div
                      onClick={onSignIn}
                      className="d-flex align-items-center sign-in-logo"
                    >
                      <span className="mx-2">Sign in</span>
                      <img
                        className="signin-icon"
                        src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}signin_icon.svg`}
                      />
                      <img
                        className="signin-icon-hover"
                        src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}signin_red.svg`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-wrapper">
          <div>
            <ul>
              <li>
                {currentCinema ? (
                  <div className="d-flex">
                    <img className="mx-1" src="/images/location.svg" />
                    <LocationDropDown
                      value={{
                        value: currentCinema ? currentCinema.slug : "",
                        label: currentCinema
                          ? currentCinema.name.toUpperCase()
                          : "",
                      }}
                      options={cinemaList}
                      placeholder="SELECT  YOUR CITY"
                      onChange={onChangeCinema}
                      className="location-dropdown-us"
                      classNamePrefix="location-us"
                      brandId={brandId}
                    />
                  </div>
                ) : null}
              </li>
              {menu.header.map((v: IMainNav) => (
                <li
                  className={`menu ${
                    (pathname.includes(v.Link_Url) &&
                      v.Sub_items.length === 0) ||
                    (v.Sub_items.length > 0 &&
                      v.Sub_items.filter((v) => pathname.includes(v.Link_Url))
                        .length > 0)
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    v.Sub_items.length === 0
                      ? navigate(`/${currentCinema?.alias}${v.Link_Url}`)
                      : ""
                  }
                >
                  {v.Sub_items.length > 0 ? (
                    <div className="dropdown">
                      <a className="dropbtn">
                        <span className="mx-2 label">
                          {v.Main_item_name.toUpperCase()}
                        </span>
                        <img src="/images/arrow_down.svg" />
                        <div className="dropdown-content">
                          {v.Sub_items.map((sub) => (
                            <a
                              className={`${
                                pathname.includes(sub.Link_Url) ? "active" : ""
                              }`}
                              href="javascript:void(0)"
                              onClick={() =>
                                navigate(
                                  `/${currentCinema?.alias}${sub.Link_Url}`
                                )
                              }
                            >
                              <span>{sub.Subitem_Name.toUpperCase()}</span>
                            </a>
                          ))}
                        </div>
                      </a>
                    </div>
                  ) : (
                    v.Main_item_name.toUpperCase()
                  )}
                </li>
              ))}
              <li
                className={`quick-tickets-btn ${
                  showQuickTickets ? "active" : ""
                }`}
                onClick={() => onShowQuickTickets()}
              >
                Quick Tickets
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showQuickTickets ? (
        <div className={filterClass ? "show-filter" : "hide-filter"}>
          <QuickTickets cinemas={cinemaList} />
          <div
            onClick={() => setShowQuickTickets(false)}
            className={`quick-filter-backDrop ${
              showQuickTickets ? "opened" : "closed"
            }`}
          ></div>
        </div>
      ) : null}

      <UpdateProfile />
    </>
  );
};

export default Header;
