import React,{ FC, useEffect, useState } from "react";
import MovieDescription from "../../../../../components/TicketBooking/MovieDescription";
import "./auth.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import Guest from "../../../../../components/Auth/Guest";
import Member from "../../../../../components/Auth/Member";

interface IAuthProps {}

const Auth: FC<IAuthProps> = () => {
  const [tab, setTab] = useState("");

  const { modalData, isLoggedIn, ticketLessFb } = useSelector((state: RootState) => ({
    modalData: state.ticketBookingReducer.modal.data,
    isLoggedIn: state.authReducer.isLoggedIn,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb
  }));

  useEffect(() => {
    setTab(modalData);
  }, [modalData]);

  const changeTab = (type: string) => {
    setTab(type);
  };

  return (
    <div className="row">
     {!ticketLessFb?<MovieDescription />: null}
      <div className={`col-12 ${ticketLessFb?'col-md-12':'col-md-8'} auth-wrapper`}>
        {!isLoggedIn?<div className="d-flex">
          <div
            onClick={() => changeTab("guest")}
            className={`login-tab ${tab === "guest" ? "active" : ""}`}
          >
            GUEST
          </div>
          <div
            onClick={() => changeTab("login")}
            className={`login-tab ${tab === "login" ? "active" : ""}`}
          >
            MEMBER
          </div>
        </div>: null}
        <div>
          <div className={isLoggedIn?'':'my-4'}>{tab === "guest" ? <Guest /> : null}</div>
          <div className="my-4">{tab === "login" ? <Member fromPage={'ticket'}/> : null}</div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
