import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import Slick from "react-slick";
import { SLICK_SETTING } from "../../../constants/app";
import {
  IMovieData,
  ISessionDetails,
  ISignatureProgramming,
} from "../../../models/cinema";
import Slider from "../../../components/UI/Slider";
import { API_REQUEST_TYPE } from "../../../constants/url";
import { getSignatureProgramsApi } from "../../../services/films";
import { useNavigate, useParams } from "react-router";

import {
  dateToFromNowDaily,
  formatFlags,
  formatSignatureSlides,
  seatFillStatus,
} from "../../../utils/formatter";
import { getSignatureProgramWatcher } from "../../../store/films/action";
import { IDropDown } from "../../../models/common";
import "./signature.scss";
import moment from "moment";
import { toast } from "react-toastify";
import { enableShowTime } from "../../../utils/helper";

interface ISignatureProps {}

const SignaturePrograming: FC<ISignatureProps> = () => {
  const { countryId, currentCinema, signaturePrograming } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      signaturePrograming: state.filmsReducer.signatureProgram,
    })
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slideSessionRef = useRef<any>();
  const { uniqueKey } = useParams();
  const [date, setDate] = useState(null);
  const [session, setSession] = useState<IDropDown[]>([]);
  const [slides, setSlides] = useState<any[]>([]);
  const [movieIds, setMovieIds] = useState("");
  const [programs, setPrograms] = useState<ISignatureProgramming[]>([]);

  const getMovieDetails = (ids: string, selectedDate: string | null) => {
    const payload: any = {
      countryId: countryId,
      cinemaId: currentCinema?.slug,
      status: API_REQUEST_TYPE.NOW_SHOWING,
      flag: "",
      movieGroupIds: ids,
    };
    if (selectedDate) {
      payload.selectedDate = selectedDate;
    }
    dispatch(getSignatureProgramWatcher(payload));
  };

  const getData = async (uniqueKey?: string) => {
    const {
      data: { data, statusCode },
    } = await getSignatureProgramsApi({
      cinemaId: currentCinema.slug,
      status: API_REQUEST_TYPE.GET_SIGNATURE_PROGRAMMING,
      countryId: countryId,
      flag: uniqueKey,
    });
    if (statusCode === 200 && Array.isArray(data)) {
      setPrograms(data);
      const ids: Array<number> = [];
      data.forEach((v) => {
        v.Films.split(",").forEach((f: any) => {
          ids.push(f);
        });
      });
      const slug = [...new Set(ids)].join(",");
      setMovieIds(slug);
      setSession([]);
      setDate(null);
      getMovieDetails(slug, null);
      setSlides(formatSignatureSlides(data));
    }
  };

  useEffect(() => {
    if (currentCinema) {
      if (uniqueKey) {
        getData(uniqueKey);
      } else {
        getData();
      }
    }
  }, [uniqueKey, currentCinema]);

  useEffect(() => {
    if (signaturePrograming && session.length == 0) {
      let sessionDate: string[] = [];
      signaturePrograming.forEach((v: any) => {
        v.showdates.forEach((show: any) => {
          sessionDate.push(show.date);
        });
      });
      sessionDate = sessionDate.sort(
        (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
      );
      setSession(
        [...new Set(sessionDate)].map((v) => ({
          label: dateToFromNowDaily(v),
          value: v,
        }))
      );
    }
  }, [signaturePrograming]);

  const onChange = (value: any) => {
    setDate(value);
    getMovieDetails(movieIds, value);
  };

  const openTicketModal = (data: ISessionDetails) => {
    if (enableShowTime(data)) {
      navigate(
        `/${currentCinema.alias}/sessions/${data.id}/${data.ScheduledFilmId}`
      );
    } else {
      toast.error("Sale not started");
    }
  };


  const Tag: FC<{ slug: string }> = ({ slug }) => {
    const name = programs.find((v: ISignatureProgramming) =>
      v.Films.split(",").includes(slug)
    );
    return name ? (
      <div className="tag">
        <div>{name.Website_menu_title}</div>
      </div>
    ) : null;
  };

  return (
    <div className="signature-page">
      <div className="slider-filter">
        <Slider className="banner-slider-us" data={slides} />
      </div>
      <div>
        <div className="content-container">
          <h2 className="heading">SHOWTIMES & TICKETS</h2>

          <div className="showtime-content-table-flex">
            <div className="table-parent">
              <table>
                <tbody>
                  <tr className="d-md-none">
                    <td colSpan={2} className="label">
                      <div>
                        <div className="filter">
                          <img src="/images/calendar-img-icon.svg" />
                          <span>WHEN</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="label mobile-hide">
                      <div>
                        <div className="filter">
                          <img src="/images/calendar-img-icon.svg" />
                          <span>WHEN</span>
                        </div>
                      </div>
                    </td>
                    <td className="action">
                      <div className="wrapper">
                        <div
                          onClick={() => onChange(null)}
                          className={
                            !date ? `common-filter active` : "common-filter"
                          }
                        >
                          ANYTIME
                          <div className={!date ? `active` : "non-active"}>
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="slider">
                      <div className="d-flex align-items-center">
                        <Slick {...SLICK_SETTING} ref={slideSessionRef}>
                          {session?.map((s: any, index: number) => (
                            <a
                              key={`date${index}`}
                              style={{ whiteSpace: "nowrap" }}
                              href="javascript:void(0)"
                              onClick={() => onChange(s.value)}
                              className={date === s.value ? "active" : ""}
                            >
                              {s.label}
                              <div
                                className={
                                  date === s.value ? "active-slider" : ""
                                }
                              >
                                &nbsp;
                              </div>
                            </a>
                          ))}
                        </Slick>
                      </div>
                    </td>
                    <td className="sessionType mobile-hide"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="single-show-time-container">
            {signaturePrograming && signaturePrograming.length > 0 ? (
              signaturePrograming.map((v: IMovieData) => {
                return (
                  v.showdates &&
                  v.showdates.map((show: any) => {
                    return (
                      <div className="movie-details-section-new">
                        <div className="movie-details-content-new">
                          <div className="movie-details-left-new">
                            <div className="movie-details-img">
                              <img
                                className="w-100"
                                src={v.film_image_large_size}
                              />
                            </div>
                            <div className="d-flex justify-content-start justify-content-md-center mt-2">
                              <div className="movie-details-action">
                                <div className="movie-details-action-list">
                                  <div className="movie-details-action-list-img">
                                    <img src="/images/img-icon.svg" />
                                  </div>
                                  <div className="movie-details-action-list-cont">
                                    <h3 className="active-cls">92%</h3>
                                    <p className="rotten-tomatoes">
                                      Rotten Tomatoes Score
                                    </p>
                                  </div>
                                </div>
                                <div className="movie-details-action-list">
                                  <div className="movie-details-action-list-img">
                                    <img src="/images/wishlist-icon.svg" />
                                  </div>
                                  <div className="movie-details-action-list-cont">
                                    <h3>
                                      <a
                                        href="javascript:void(0)"
                                        className="active-hover"
                                      >
                                        Add to Watch List
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                                <div className="movie-details-action-list">
                                  <div className="movie-details-action-list-img">
                                    <img src="/images/share-icon.svg" />
                                  </div>
                                  <div className="movie-details-action-list-cont">
                                    <h3>
                                      <a
                                        href="javascript:void(0)"
                                        className="active-hover"
                                      >
                                        Share
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="movie-details-right">
                            <Tag slug={v.slug} />
                            <h3>{v.name}</h3>
                            <p>
                              <span className="vt-line">{v.ratingName} </span>
                              {v.length
                                ? moment
                                    .duration(v.length, "minutes")
                                    .humanize()
                                : ""}
                            </p>
                            <p>{v.gentre}</p>
                            <div>
                              <div className={`divider`}>
                                <div className="btn-section d-flex">
                                  <p style={{ color: "#fff" }}>
                                    {dateToFromNowDaily(show.date)}
                                  </p>
                                </div>

                                {show.showtypes.map((session: any) => (
                                  <>
                                    <div className="movie-show-time-parent-section">
                                      <div className="movie-show-time-parent">
                                        <h4> {session.type}</h4>
                                        <div className="movie-show-time-flex">
                                          {session.showtimes.map(
                                            (time: any) => (
                                              <div
                                                className={`movie-show-time ${
                                                  !enableShowTime(v)
                                                    ? "show-disable"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  openTicketModal(time)
                                                }
                                              >
                                                {moment
                                                  .parseZone(time.date_time)
                                                  .format("hh:mm A")}
                                                {time.statusCode === "1" ? (
                                                  <span className="sold_out_txt">
                                                    SOLD OUT
                                                  </span>
                                                ) : null}
                                                {time.statusCode === "0" ? (
                                                  <div className="seat_wrap">
                                                    <div
                                                      className={`fill_status ${
                                                        seatFillStatus(
                                                          time.available_seats,
                                                          time.totalNumberOfSeats
                                                        ) === 0
                                                          ? "no_fill"
                                                          : seatFillStatus(
                                                              time.available_seats,
                                                              time.totalNumberOfSeats
                                                            ) === 1
                                                          ? "slow_fill"
                                                          : seatFillStatus(
                                                              time.available_seats,
                                                              time.totalNumberOfSeats
                                                            ) === 2
                                                          ? "fast_fill"
                                                          : ""
                                                      }`}
                                                    ></div>
                                                    <div
                                                      className={`recliner`}
                                                    ></div>

                                                    {time.statusCode === "0" &&
                                                      formatFlags(time).map(
                                                        (flagObj: any) => (
                                                          <div className="flag_wrap">
                                                            <span className="flag_text">
                                                              {flagObj.short}
                                                            </span>
                                                          </div>
                                                        )
                                                      )}
                                                  </div>
                                                ) : null}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                );
              })
            ) : (
              <div className="w-100 d-flex justify-content-center">
                <div className="no-session">No Sessions Available</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignaturePrograming;
