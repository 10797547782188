import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { FC, useEffect, useState } from "react";
import React from "react";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY, COUNTRY } from "../../../../constants/app";
import { employmentApi } from "../../../../services/sideMenus";
import TextInput from "../../../../components/UI/TextInput";
import SelectDropdown from "../../../../components/UI/DropDown/SelectDropDown";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { getSlidesApi } from "../../../../services/application";
import "./employment.scss";
import { IDropDown } from "../../../../models/common";
import { toast } from "react-toastify";
import HelmetTitle from "../../../../components/Helmet";

interface IEmploymentProps {
  onCloseModal: any;
}

const Employment: React.FC<IEmploymentProps> = ({ onCloseModal }) => {
  const { countryId, currentCinema, cinemas, brandId } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      cinemas: state.applicationReducer.cinemas,
      brandId: state.applicationReducer.brandId,
    })
  );

  const [fileUploadError, setfileUploadError] = useState("");
  const [location, setLocation] = useState<IDropDown[]>([]);
  const [image, setImage] = useState("");

  const getSlides = async () => {
    const {
      status,
      response: { data },
    } = (await getSlidesApi({
      countryId,
      pageName: API_REQUEST_TYPE.EMPLOYMENT,
      location: "",
    })) as any;
    if (status && data && data.length > 0) {
      setImage(data[0]["imageUrl"]);
    }
  };

  useEffect(() => {
    if (countryId) {
      getSlides();
    }
  }, [countryId]);

  useEffect(() => {
    if (cinemas) {
      const allCinemaLocation: IDropDown[] = [];
      Object.keys(cinemas).forEach(function (key) {
        const arrList = cinemas[key];
        arrList.forEach((obj: any) => {
          allCinemaLocation.push({
            value: obj.slug,
            label: obj.cinemaName + ", " + key,
          });
        });
      });
      setLocation(allCinemaLocation);
    }
  }, [cinemas]);

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  const getBase64 = (file: Blob): any => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileSelect = async (event: any) => {
    setfileUploadError("");
    setFieldValue("cv", "");
    setFieldValue("fileName", "");
    if (event.target.files && event.target.files[0]) {
      const fileSize = event.target.files[0].size;
      let fileName = event.target.files[0].name;
      const fileExtn = fileName.substring(fileName.lastIndexOf(".") + 1);
      const allowedTypes = ["doc", "docx", "pdf"];

      if (allowedTypes.includes(fileExtn) != true) {
        setfileUploadError("Uploaded file type is not allowed");
        return;
      }
      const sizeInMB = parseFloat((fileSize / 1024).toFixed(2));
      if (sizeInMB > 1024) {
        setfileUploadError("File size is exceeded the limit");
        return;
      }
      setFieldValue("fileName", fileName);
      const file = await getBase64(event.target.files[0]);
      setFieldValue("cv", file);
    }
  };

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("employment"),
      initialValues: {
        name: "",
        email: "",
        contactNumber: "",
        location: "",
        comments: "",
        cv: "",
        fileName: "",
        captcha: "",
      },
      onSubmit: async (values) => {
        const payload = { ...values, countryId };
        const { data } = await employmentApi(payload);
        if (data) {
          toast.success("Employment details submitted successfully.");
          onCloseModal();
        } else {
          toast.error(
            "Could not connect to services at the moment. Please try again. Thanks."
          );
        }
      },
    });

  return (
    <div className="employment-wrapper">
      <HelmetTitle title="Employment" description="Employment"/>
      <div className="modal-header d-block p-0">
        {image ? (
          <div className="full_banner_wrap">
            <img src={image} alt="Contact Us" title="Contact Us" />
          </div>
        ) : null}
        <div className="row p-3">
          <div className="col-md-12 mt-4">
            <h2 className="title">Employment</h2>
            {countryId === COUNTRY.STA ? (
              <p>
                We are always on the look out for new people to join the State
                Cinema team.
              </p>
            ) : countryId === COUNTRY.ANG ? (
              <p>
                We are always on the lookout for new people to join the Angelika team.
              </p>
            ) : (
              <p>
                We are always on the look out for new people to join the Reading
                Cinemas team.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="form_page">
          <div className="row">
            <div className="col-6 col-md-6 ">
              <label className="form-label">Name*</label>
              <div className="form-group pass_show">
                <TextInput
                  field={"name"}
                  handleChange={handleChange}
                  touched={touched.name!}
                  error={errors.name}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Email*</label>
              <div className="form-group pass_show">
                <TextInput
                  field={"email"}
                  handleChange={handleChange}
                  touched={touched.email!}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Contact Number</label>
              <div className="form-group pass_show">
                <TextInput
                  field={"contactNumber"}
                  handleChange={handleChange}
                  touched={touched.contactNumber!}
                  error={errors.contactNumber}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label empty">Location*</label>
              <SelectDropdown
                // placeholder="Cinema"
                field={"location"}
                options={location}
                setFieldValue={setFieldValue}
                touched={touched.location!}
                error={errors.location}
                onChange={(value: any) => {
                  setFieldValue("location", value);
                }}
              />
            </div>
            <div className="col-6 col-md-6">
              <label className="form-label">Upload CV*</label>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="form-control custom-file-input"
                    id="inputGroupFile01"
                    aria-describedby="inputGroupFileAddon01"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileSelect}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile01"
                  >
                    {values.fileName ? values.fileName : "Choose file"}
                  </label>
                </div>
                <p className="font_italic">
                  * Upload only .pdf .doc or .docx. File size should be less
                  than 1MB
                </p>
                {touched && !fileUploadError && errors.cv ? (
                  <div className="error_message">
                    <span>{errors.cv}</span>
                  </div>
                ) : null}
                {fileUploadError ? (
                  <div className="error_message">
                    <span>File size is exceeded the limit</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6 col-md-6">
              <label className="form-label">Comments: </label>
              <div className="form-group">
                <textarea
                  title="Enter your Comments"
                  name="comments"
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  aria-label="Comments"
                />
              </div>
            </div>
            <div className="col-12  col-md-12">
              <label className="form-label">Verification Code* </label>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${CAPTCHA_SITE_KEY}`}
                  onChange={onChangeCaptcha}
                />
                {touched.captcha && errors.captcha ? (
                  <div className="error_message">{`${errors?.captcha}`}</div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              <div className="button_wrap text-center div">
                <button
                  type="button"
                  className="btn gray_btn back-btn mx-1"
                  onClick={onCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn black_btn mx-2"
                  onClick={() => handleSubmit()}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employment;
