import { FC, useEffect, useState } from "react";
import { ISearch } from "../../../models/cinema";
import HtmlRender from "../../../components/UI/HtmlRender";
import { API_REQUEST_TYPE, URLS } from "../../../constants/url";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { searchApi } from "../../../services/films";
import { useNavigate } from "react-router";
import { getSlidesWatcher } from "../../../store/application/action";
import { ROUTES } from "../../../constants/path";
import { formatSearchList } from "../../../utils/formatter";
import Deal from "../../../components/Menu/SideMenu/Deal";
import CompetionQuestion from "../../../components/Menu/SideMenu/Competition/competitionQuestion";
import "./search.scss";
import { searchMovie } from "../../../store/films/action";
import { Tooltip } from "react-tooltip";

interface ISearchProps {}

const Search: FC<ISearchProps> = () => {
  const { countryId, currentCinema, searchFilter } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      searchFilter: state.filmsReducer.searchFilter,
    })
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [movies, setMovie] = useState([]);
  const [deals, setDeals] = useState([]);
  const [competition, setCompetition] = useState([]);

  const getSearch = async () => {
    const {
      data: { data },
    } = await searchApi({
      countryId,
      searchkey: searchFilter,
    });
    if (data) {
      const res = formatSearchList(data);

      setMovie(res.filter((v: any) => v.type === "movies"));
      setDeals(res.filter((v: any) => v.type === "deal"));
      setCompetition(res.filter((v: any) => v.type === "competition"));
      if (res.length <= 1) {
        document.body.classList.add("fixedbottom");
      } else {
        document.body.classList.remove("fixedbottom");
      }
    }
  };



  useEffect(() => {
    if (searchFilter) {
      getSearch();
    } else {
      setMovie([]);
      setDeals([]);
      setCompetition([]);
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    }
  }, [searchFilter]);

  const onClickMovieDetails = (data: any) => {
    dispatch(searchMovie(""));
    navigate(
      `/${ROUTES.MOVIE_DETAIL_SEARCH.replace(
        ":cinemaId",
        currentCinema?.alias
      ).replace(":movieGroupId", data.id)}`
    );
  };

  const errorHandler = (event: any) => {
    event.target.src = URLS.ERROR_IMAGE;
  };

  return (
    <div className="search_result_wrap">
      {movies.length > 0 ? (
        <section className="movie_result">
          <div className="split_section_header">
            <div className="label">Movies</div>
          </div>
          {movies.map((contentObj: any) => (
            <div className="list-content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <label className="title mobile_title">
                      {contentObj.movie}
                    </label>
                  </div>
                  <div className="col-md-12 col-12 mobile_view">
                    <div className="media">
                      <div
                        className="list_poster"
                        onClick={() => onClickMovieDetails(contentObj)}
                      >
                        <img
                          className="align-self-start"
                          src={contentObj.images[0].large}
                          alt={contentObj.movie}
                          title={contentObj.movie}
                          onError={errorHandler}
                        />
                      </div>
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8 ">
                            <div className="">
                              <h5
                                className="mt-0 title mobile_hide"
                                onClick={() => onClickMovieDetails(contentObj)}
                              >
                                {contentObj.movie}
                              </h5>
                              {contentObj.outline ? (
                                <HtmlRender
                                  {...{ className: "description" }}
                                  content={contentObj.outline}
                                  sliceLength={300}
                                />
                              ) : (
                                <p className="description">No Description</p>
                              )}

                              <div className="rating_wrap">
                                <div className="icon">
                                  <span className="certification_icon">
                                    <img
                                      className="align-self-start"
                                      src={contentObj.ratingImage}
                                      alt={contentObj.ratingDescription}
                                      onError={errorHandler}
                                      data-tooltip-id="rating-explanation" data-tooltip-html={contentObj.ratingExplanation}
                                    />
                                     <Tooltip id="rating-explanation" className="tool-tip" />
                                  </span>
                                </div>
                                <div>
                                  <HtmlRender
                                    {...{ className: "rating_title" }}
                                    content={contentObj.ratingDescription}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      ) : null}

      {deals.length > 0 ? (
        <section className="deals_result">
          <div className="split_section_header">
            <div className="label">Offers & Events</div>
          </div>
          <div className="list-content">
            {deals.map((v) => (
              <Deal data={v} />
            ))}
          </div>
        </section>
      ) : null}

      {competition.length > 0 ? (
        <section className="competition_result">
          <div className="split_section_header">
            <div className="label">Competitions</div>
           
          </div>
          <div className="list-content">
              {competition.map((v) => (
                <CompetionQuestion data={v} page="search" />
              ))}
            </div>
        </section>
      ) : null}

      {movies.length === 0 && deals.length === 0 && competition.length === 0 ? (
        <div className="err-noMovie searchlist">No data available</div>
      ) : null}
    </div>
  );
};

export default Search;
