import React,{ FC, useState } from "react";
import { FoodBeverageItems } from "../../../store/foodAndBeverage/type";
import { onImgError } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateFoodItemsCart } from "../../../store/foodAndBeverage/action";
import { TICKET_FLOW } from "../../../constants/ticket";
import {
  MODAL_TYPE,
  TICKET_FLOW_TYPES,
} from "../../../models/tickets";
import { setModal } from "../../../store/ticketBooking/action";

interface IFoodItemSummaryProps {
  foodItems: FoodBeverageItems;
  index: number;
}

const FoodItemSummary: FC<IFoodItemSummaryProps> = ({ foodItems, index }) => {
  const { orderCartData, modalType, prevModal, isConcessionDiscount } = useSelector(
    (state: RootState) => ({
      orderCartData: state.foodBeverageReducer.orderCartData,
      modalType: state.ticketBookingReducer.modal.type,
      prevModal: state.ticketBookingReducer.modal.prevModal,
      nextModal: state.ticketBookingReducer.modal.nextModal,
      isConcessionDiscount: state.foodBeverageReducer.isConcessionDiscount,
    })
  );
  const dispatch = useDispatch();
  const [item, setItem] = useState(foodItems);

  const listUpdateItems = (items: any) => {
    const item = JSON.parse(JSON.stringify(items));
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const prev: MODAL_TYPE = prevModal;
    const modal = TICKET_FLOW[ticketType][prev];

    dispatch(
      setModal({
        ...modal,
        type: modalType,
        data: {
          ...item,
          index: index,
          page: MODAL_TYPE.FOOD_AND_BEVERAGE
        }
      })
    );
  };

  const onClickminus = (item: any) => {
   
    const order = JSON.parse(JSON.stringify(orderCartData));
    const concession = JSON.parse(JSON.stringify(item));
    if((concession.quantity - 1) < 1){
      return;
    }
    concession.quantity = concession.quantity - 1;
    concession.totalPrice = (
      Number(concession.price) * Number(concession.quantity)
    ).toFixed(2);
    order[index] = concession;
    // if (concession.quantity <= 0) {
    //   order.splice(index, 1);
    // }
    setItem(concession);
    dispatch(updateFoodItemsCart(order));
  };

  const onClickplus = (item: any) => {
    if (item.quantity < 10) {
      const order = JSON.parse(JSON.stringify(orderCartData));
      const concession = JSON.parse(JSON.stringify(item));
      concession.quantity = concession.quantity + 1;
      concession.totalPrice = (
        Number(concession.price) * Number(concession.quantity)
      ).toFixed(2);
      order[index] = concession;
      setItem(concession);
      dispatch(updateFoodItemsCart(order));
    }
  };

  return (
    <li>
      <div className="media_wrap">
        <div className="media_content">
          <div className="thumb_img">
            <img src={item.image} alt="Food Image" onError={onImgError} />
          </div>
          <div className="list_details">
            <p className="title">{item.description}</p>
            <p className="item_list">Quantity: {item.quantity}</p>
            {
              isConcessionDiscount ? <p className="item_list">Angelika Gold Rewards 10% Off</p> : null
            }            
            {item.options.map((optionItems: any) => {
              return (
                <>
                  {optionItems._comment == "AlternateItems" ? (
                    <p className="item_list">
                      {optionItems.selected.description}
                    </p>
                  ) : null}

                  {optionItems._comment == "ModifierGroups" ? (
                    <>
                      {" "}
                      {optionItems.selected.map((options: any) => (
                        <p className="item_list">{options.description}</p>
                      ))}
                    </>
                  ) : null}

                  {optionItems._comment == "smartModifierGroups" ? (
                    <>
                      {optionItems.selected.map((options: any) => (
                        <p className="item_list">{options.description}</p>
                      ))}
                    </>
                  ) : null}
                </>
              );
            })}
            <p className="item_list mobile_show">${item.totalPrice}</p>
          </div>
        </div>
        {item.options && item.options.length > 0?<div className="edit_btn" onClick={() => listUpdateItems(item)}>
          &nbsp;
        </div>:null}
      </div>
      <div className="action_wrap mobile_hide">
        <div className="action_btns">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="btn_minus" onClick={() => onClickminus(item)}>
                &nbsp;
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              aria-label="qantity"
              value={item.quantity}
              disabled
            />
            <div className="input-group-append">
              <span className="btn_plus" onClick={() => onClickplus(item)}>
                &nbsp;
              </span>
            </div>
          </div>
        </div>
        <div className="price_wrap">
          <p className="item_list">${item.totalPrice}</p>
        </div>
      </div>
    </li>
  );
};

export default FoodItemSummary;
