export interface ICards {
  cardName: string;
  cardNumber: string;
  defaultCard: number;
  expiryDate: string;
  token: string;
}


export interface PaymentData {
  msgId: string;
  amount: string;
  description?: string;
  transactionReference?: string;
  saleId: string;
  email: string;
  saveCreditCard?: boolean;
  memberRefId?: string;
  ticketlessFB?: boolean;
  movieId?: string;
  googleApplePay?: boolean;
  id?: string;
}

export interface PaymentResponseStripe {
  status: boolean;
  message?: string;
}

export interface PaymentResponse {
  status: boolean;
  responseDesc?: string;
  responseCode?: string;
  message?: string;
  amount?: string;
  txnID?: string;
  authID?: string;
  cardNumber?: string;
  transactionReference?: string;
}

export interface MovieTransaction {
  countryId: string;
  saleId: string;
  requestType?: string;
}

export interface EncryptionKeyData {
  key: string;
}

export interface EncryptionKeyResponse {
  statusCode: number;
  data: EncryptionKeyData;
}

export interface ICardDetails {
  expirymonth?: string;
  expiryyear?: string;
  cardNumber?: string;
  cardHolderName?: string;
  cvv?: string;
  expiryDate?: string;
}
export interface IStripePaymentStatus {
  status: boolean;
  error?: string;
  cardDetails?: ICardDetails;
  paymentIntentId?: string;
}

export interface ICheckRefund {
  bookingFee: string,
  concessionAmount: number,
  message: string,
  status: boolean,
  ticketAmount: string,
  totalAmount: string,
  transactionRefNo: string,
  txnId: string,
  email: string,
  ticketConfirmationNumber: string
}

export enum PAYMENT_TYPE {
  CARD = "card",
  APPLE_PAY = "applePay",
  GIFT_CARD = "giftcard",
  GOOGLE_PAY = "googlePay",
  NSW_VOUCHER = "nswVoucher",
  CLICK_TO_PAY = "click2pay"
}

export interface ISocialShare {
  Id: string,
  Plat_Form: string,
  Session_Seat_Sharing_Description: string,
  Session_Seat_Sharing_title: string,
  Session_Seat_Sharing_url_link: string,
  Session_Sharing_Description: string,
  Session_Sharing_title: string,
  Session_Sharing_url_link: string,
  brandId: string,
  countryId: string,
  image1: string,
  image2: string,
  image3: string,
  image4: string,
  online: number,
  uniqueKey: string
}