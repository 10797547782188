import React, { FC, useEffect, useState } from "react";
import "./quickTickets.scss";
import LocationDropDown from "../../DropDown/Location";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setQuickFilter } from "../../../../store/films/action";
import { quickFilterApi } from "../../../../services/films";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { IDropDown } from "../../../../models/common";
import moment from "moment";
import { setReinitializeSlider } from "../../../../store/application/action";
import { sleep } from "../../../../utils/helper";
import { delay } from "lodash";

interface IQuickTicketProps {
  cinemas: { label: string; value: string }[];
}

const QuickTickets: FC<IQuickTicketProps> = ({ cinemas }) => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));

  const dispatch = useDispatch();

  const [movies, setMovies] = useState<IDropDown[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [format, setFormat] = useState<IDropDown[]>([]);
  const [sessions, setSessions] = useState<IDropDown[]>([]);
  const [filter, setFilter] = useState<any>({
    location: null,
    movie: null,
    format: null,
    session: null,
    date: null,
  });

  const delay = async () => {
    await sleep(100);
    setShowFilter(true);
  };

  useEffect(() => {
    delay();
  }, []);

  const onChangeLocation = async (location: string) => {
    setFilter({
      ...filter,
      location: location,
      movie: null,
      format: null,
      session: null,
      date: null,
    });
    setMovies([]);
    setFormat([]);
    setSessions([]);
    const {
      data: { statusCode, data },
    } = await quickFilterApi({
      cinemaId: location,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      countryId,
    });

    if (statusCode === 200 && Array.isArray(data)) {
      setMovies(data.map((v: any) => ({ label: v.name, value: v.id })));
    }
  };

  const onChangeMovies = async (movie: string) => {
    setFilter({
      ...filter,
      movie: movie,
      format: null,
      session: null,
      date: null,
    });
    setFormat([]);
    setSessions([]);
    const {
      data: { statusCode, data },
    } = await quickFilterApi({
      cinemaId: filter.location,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      selectedMovie: movie,
      countryId,
    });

    if (statusCode === 200 && Array.isArray(data)) {
      setFormat(data.map((v: any) => ({ label: v.name, value: v.name })));
    }
  };

  const onChangeFormat = async (format: string) => {
    setFilter({
      ...filter,
      format: format,
      session: null,
      date: null,
    });
    setSessions([]);
    const {
      data: { statusCode, data },
    } = await quickFilterApi({
      cinemaId: filter.location,
      selectedMovie: filter.movie,
      selectedAttribute: format,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      countryId,
    });

    if (statusCode === 200 && Array.isArray(data)) {
      setSessions(
        data
          .filter((v, i) => {
            return (
              data.findIndex(
                (s) =>
                  moment(s.name).format("YYYY-MM-DD") ===
                  moment(v.name).format("YYYY-MM-DD")
              ) === i &&
              moment(moment(v.name).format("YYYY-MM-DD")) >=
                moment(moment().format("YYYY-MM-DD"))
            );
          })
          .map((v: any) => ({
            label: moment(v.name).format("DD/MM ddd"),
            value: `${moment(v.name).format("YYYY-MM-DD")}`,
          }))
      );
    }
  };

  const onChangeSession = async (session: string) => {
    setFilter({
      ...filter,
      session: null,
      date: session,
    });
  };

  const onClickSearch = () => {
    dispatch(setQuickFilter(filter));
    dispatch(setReinitializeSlider(true));
  };

  return (
    <div className={`quick-tickets-filter ${showFilter ? "show" : ""}`}>
      <div className="container-wrapper">
        <img className="filter-img" src="/images/quick-tickets-img.svg" />
        <div className="filter-wrapper">
          <div className="filter-options">
            <div className="filter-label">
              <img
                className="filter-option-icons"
                src="/images/location-img-icon.svg"
              ></img>
              <div className="filter-label-heading">WHERE</div>
            </div>
            <LocationDropDown
              onChange={onChangeLocation}
              options={cinemas}
              placeholder={"Select your city"}
              noOptionsMessage={() => "No City found"}
              value={cinemas.find((v) => v.value === filter.location) || null}
            />
          </div>
          <div className="headerDivider"></div>
          <div className="filter-options">
            <div className="filter-label">
              <img
                className="filter-option-icons"
                src="/images/movie-img-icon.svg"
              ></img>
              <div className="filter-label-heading">WHAT</div>
            </div>
            <LocationDropDown
              onChange={onChangeMovies}
              options={movies}
              placeholder={"Select your movie"}
              noOptionsMessage={() => "No Movies available"}
              value={movies.find((v) => v.value === filter.movie) || null}
            />
          </div>
          <div className="headerDivider"></div>
          <div className="filter-options">
            <div className="filter-label">
              <img
                className="filter-option-icons"
                src="/images/format-img.svg"
              ></img>
              <div className="filter-label-heading">FORMAT</div>
            </div>
            <LocationDropDown
              onChange={onChangeFormat}
              options={format}
              placeholder={"Select your format"}
              noOptionsMessage={() => "No Format found"}
              value={format.find((v) => v.value === filter.format) || null}
            />
          </div>
          <div className="headerDivider"></div>
          <div className="filter-options">
            <div className="filter-label">
              <img
                className="filter-option-icons"
                style={{ top: "2px" }}
                src="/images/calendar-img-icon.svg"
              ></img>
              <div className="filter-label-heading">WHEN</div>
            </div>
            <LocationDropDown
              onChange={onChangeSession}
              options={sessions}
              placeholder={"Select your date and time"}
              noOptionsMessage={() => "No Session found"}
              value={sessions.find((v) => v.value === filter.date) || null}
            />
          </div>
        </div>
        <img
          className="filter-img pointer"
          onClick={onClickSearch}
          src="/images/search-icon-img.svg"
        />
      </div>
    </div>
  );
};

export default QuickTickets;
